<template>
  <div class="zh-compliance-checkbox">
    <Checkbox
      id="zhCompliance"
      name="zhCompliance"
      :error="error"
      :has-error="!!error"
      @change="$emit('change', $event)"
    >
      <i18n-t keypath="transaction-flow.zero-hash-compliance.checkbox">
        <template #userAgreementLink>
          <a
            href="https://www.zerohash.com/paybis"
            target="_blank"
          >{{
            $t("transaction-flow.zero-hash-user-agreement")
          }}</a>
        </template>
        <template #privacyPolicyLink>
          <a
            href="https://zerohash.zendesk.com/hc/en-us/articles/360009900494-Privacy-Policy"
            target="_blank"
          >{{ $t("transaction-flow.zero-hash-privacy-policy") }}</a>
        </template>
        <template #regulatoryDisclosureLink>
          <a
            href="https://zerohash.zendesk.com/hc/en-us/articles/360008819314-State-Money-Transmitter-Disclosures"
            target="_blank"
          >{{ $t("transaction-flow.zero-hash-regulatory-disclosure") }}</a>
        </template>
      </i18n-t>
    </Checkbox>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ZeroHashComplianceCheckbox',
  components: {
    Checkbox,
  },
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    error() {
      return this.hasError
        ? this.t('transaction-flow.zero-hash-compliance.checkbox.error')
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.zh-compliance-checkbox {
  padding-top: rem(16);

  a {
    color: inherit;
    text-decoration: none;
  }
}
</style>
