import { isInsideIframe } from '@/utils/isInsideIframe';

const checkForValue = value => value !== null && value !== '' && value !== undefined;

// XXX: set default false
const settings = {
  canClose: false,
  standalone: {},
};

export const setCanClose = canClose => { settings.canClose = canClose === 'true'; };

export const canClose = () => isInsideIframe() || settings.canClose;

export const setStandaloneParams = params => {
  settings.standalone = {
    ...params,
  };
};

export const hasParams = () => {
  const { currencyCodeTo, currencyCodeFrom } = settings.standalone;

  return [currencyCodeTo, currencyCodeFrom].every(checkForValue);
};

export const hasWalletAddress = () => checkForValue(settings.standalone.cryptoAddress);

export const getWalletAddress = () => {
  if (!hasWalletAddress()) {
    throw new Error('Could not find valid wallet address');
  }
  const { cryptoAddress } = settings.standalone;

  return cryptoAddress;
};

export const getStandaloneParams = () => {
  const {
    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom,
    amountTo,
    transactionFlow,
    paymentMethod,
  } = settings.standalone;

  let cryptoCurrency = currencyCodeTo;
  let fiatCurrency = currencyCodeFrom;
  let cryptoAmount = amountTo;
  let fiatAmount = amountFrom;

  const sellCrypto = transactionFlow === 'sellCrypto';
  if (sellCrypto) {
    cryptoCurrency = currencyCodeFrom;
    fiatCurrency = currencyCodeTo;
    cryptoAmount = amountFrom;
    fiatAmount = amountTo;
  }

  return {
    cryptoAddress,
    currencyCodeTo: cryptoCurrency,
    currencyCodeFrom: fiatCurrency,
    amountFrom: Number(fiatAmount) > 0 ? fiatAmount : null,
    amountTo: Number(cryptoAmount) > 0 ? cryptoAmount : null,
    transactionFlow,
    paymentMethod,
  };
};
