<template>
  <div class="address-qr-scanner">
    <header
      :class="{
        'page-layout__header': true,
        'is-clickable': true,
      }"
      data-test="header"
      @click="onBackButtonClick"
    >
      <i
        class="page-layout__back-icon svg-icon icon-prev"
        data-test="back"
      />
      <div class="page-layout__title">
        {{ t('widget.qr-scanner.popup.title') }}
      </div>
    </header>
    <div class="address-qr-scanner__scan-region">
      <div class="loader-container">
        <div
          class="loading__icon"
        />
      </div>
      <video
        ref="scannerElement"
        class="address-qr-scanner__video"
      />
      <div
        ref="scannerOverlay"
        class="address-qr-scanner__scan-region-overlay"
      >
        <svg
          class="address-qr-scanner__code-outline-highlight"
          preserveAspectRatio="none"
        >
          <polygon />
        </svg>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, nextTick, ref } from 'vue';
import QrScanner from 'qr-scanner';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['setWalletAddress', 'closeScanner']);
const qrScanner = ref(null);
const scannerElement = ref(null);
const scannerOverlay = ref(null);
const scannedAddress = ref(null);
const isScannerAvailable = ref(false);

const onBackButtonClick = () => {
  emit('closeScanner');
};
onMounted(async () => {
  isScannerAvailable.value = await QrScanner.hasCamera();
  if (!isScannerAvailable.value) {
    return;
  }

  await nextTick();
  qrScanner.value = new QrScanner(
    scannerElement.value,
    ({ data }) => {
      if (!data) {
        return;
      }
      scannedAddress.value = data;
      emit('setWalletAddress', scannedAddress.value);
      qrScanner.value.stop();
    },
    {
      highlightScanRegion: false,
      highlightCodeOutline: false,
      maxScansPerSecond: 5,
    },
  );
  await qrScanner.value.start();
});
onUnmounted(() => {
  if (qrScanner.value === null) {
    return;
  }
  qrScanner.value.destroy();
  qrScanner.value = null;
});
</script>
<style scoped lang="scss">
.page-layout {
  padding: 0 rem(16) rem(40) rem(16);
  height: calc(100% - 20px * 2);
  display: flex;
  flex-direction: column;
  @media (max-width: $tablet-max) {
    padding: 0;
    height: 100%;
  }
  @media (min-width: $laptop-min) {
    height: 100%;
    padding-bottom: 0;
  }
  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: rem(28);
    font-weight: 700;
    margin-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    @media (max-width: $tablet-max) {
      font-size: 24px;
      line-height: 26px;
      margin-top: 8px;
      padding-right: 30px;
      padding-left: 30px;
    }
    @media (max-width: $screen-sm-min) {
      font-size: 20px;
      line-height: 26px;
    }
    &.is-clickable:hover:not(.disabled) {
      opacity: 0.6;
      cursor: pointer;
    }
  }
  &__title {
    min-height: 32px;
    text-align: center;
  }
  &__back-icon {
    width: rem(32);
    height: rem(32);
    position: absolute;
    left: -8px;
    top: 0;
  }
  @media (max-width: $screen-sm-min) and (max-height: $widget-height-xxlg) {
    &__title {
      padding-top: 5px;
    }
  }
  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &__header {
      font-size: 3vh;
      line-height: 4vh;
    }
  }
  @media (min-width: $laptop-middle) and (max-height: $widget-height-sm) {
    padding-bottom: 3.125vh;
    &__header {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
      padding-left: calcVh(32, $widget-h-sm);
      padding-right: calcVh(32, $widget-h-sm);
    }
    &__back-icon {
      width: calcVh(32, $widget-h-sm);
      height: calcVh(32, $widget-h-sm);
      left: calcVh(-8, $widget-h-sm);
    }
  }
  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    padding-bottom: 3.13vh;
    &__header {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
      padding-left: calcVh(32, $widget-h-xlg);
      padding-right: calcVh(32, $widget-h-xlg);
    }
    &__back-icon {
      width: calcVh(32, $widget-h-xlg);
      height: calcVh(32, $widget-h-xlg);
      left: calcVh(-8, $widget-h-xlg);
    }
  }
  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    padding-bottom: 3.18vh;
    &__header {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
      padding-left: calcVh(32, $widget-h-xxlg);
      padding-right: calcVh(32, $widget-h-xxlg);
    }
    &__back-icon {
      width: calcVh(32, $widget-h-xxlg);
      height: calcVh(32, $widget-h-xxlg);
      left: calcVh(-8, $widget-h-xxlg);
    }
  }
}
.loader-container {
  position: absolute;
  display: flex;
  align-items: center;
  min-width: 50px;
  min-height: 50px;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__icon {
    @include spinerIcon;
    height: 100%;
    padding: 6px;
    &:after {
      width: 40px;
      height: 40px;
      @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
        width: calcVh(40, $widget-h-sm);
        height: calcVh(40, $widget-h-sm);
      }
      @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
        width: calcVh(40, $widget-h-xlg);
        height: calcVh(40, $widget-h-xlg);
      }
      @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
        width: calcVh(40, $widget-h-xxlg);
        height: calcVh(40, $widget-h-xxlg);
      }
    }
  }
}
.address-qr-scanner {
  width: 100%;
  &__scan-button-section {
    text-align: center;
    margin-top: rem(16);
    & .btn {
      margin-top: rem(8);
    }
  }
  &__scan-region {
    position: relative;
    overflow: hidden;
    aspect-ratio : 1 / 1;
    border-radius: 6px;
  }
  &__video {
    width: 100%;
    aspect-ratio : 1 / 1;
    object-fit: cover;
    @media screen and (min-width: $screen-lg-min) {
      margin: 0 auto;
      display: block;
    }
  }
  &__scan-region-overlay {
    position: absolute;
    pointer-events: none;
    border-radius: 30px;
    outline: rgba(0, 0, 0, .5) solid 100vmax;
    width: 75%;
    height: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__code-outline-highlight {
    display: none;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: rgba(255, 255, 255, .5);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 15;
    stroke-dasharray: none;
  }
}
</style>
