<template>
  <div class="wrap-apm-payment-waiting">
    <Title
      v-if="isApmPaymentReady"
      data-testid="payment-title"
    >
      {{ $t('transaction-flow.steps.payment-details.title') }}
    </Title>
    <div class="bp-container">
      <div class="apm-payment-waiting">
        <div v-if="!isLoading">
          <ApmPaymentAlreadyInitiated v-if="isShowAlreadyInitiated" />

          <div v-else-if="waitingForPayment">
            <Title>
              {{
                $t('transaction-flow.steps.payment-waiting.apm.title')
              }}
            </Title>
            <Paragraph>{{ waitingTimeLabel }}</Paragraph>
            <FunnelLoader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';

import ApmPaymentAlreadyInitiated from './apm-payment-already-initiated';
import FunnelLoader from './funnel-loader';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
    ApmPaymentAlreadyInitiated,
  },

  data() {
    const { t } = useI18n();

    return {
      waitingForPayment: false,
      t,
    };
  },

  computed: {
    ...mapGetters('transaction', ['payment', 'invoice']),
    ...mapGetters(['requestId']),
    ...mapGetters('settings', ['isWidget']),
    ...mapGetters('apmPayment', ['isApmPaymentAlreadyInitiated', 'isLoading', 'isApmPaymentReady']),

    waitingTimeLabel() {
      const { slug } = this.payment;
      let key = `transaction-flow.money-service.${slug}.payment-waiting.apm.waiting-time`;

      if (slug.includes('token_io') || slug.includes('tokenio')) {
        key = 'transaction-flow.steps.payment-waiting.apm.waiting-time-days';
      } else if (!this.t(key)) {
        key = 'transaction-flow.steps.payment-waiting.apm.waiting-time';
      }

      return this.t(key);
    },
    isShowAlreadyInitiated() {
      return this.payment.isTrustly() || this.isApmPaymentAlreadyInitiated;
    },
  },

  methods: {
    ...mapActions('apmPayment', ['initBridgerPay']),
    ...mapMutations('apmPayment', ['setIsLoading']),

    getReturnUrl() {
      const parsedUrl = new URL(window.location.href);
      let returnUrl = parsedUrl.origin + parsedUrl.pathname;
      if (this.isWidget) {
        const searchParams = new URLSearchParams();
        searchParams.set('requestId', this.requestId);

        returnUrl = `${parsedUrl.origin}/?${searchParams.toString()}`;
      }

      return returnUrl;
    },
  },

  mounted() {
    if (this.payment.isTrustly()) {
      this.setIsLoading(false);
      return;
    }

    const bridgerPayOptions = {
      invoice: this.invoice,
      returnUrl: this.getReturnUrl(),
    };

    this.initBridgerPay(bridgerPayOptions).then(() => {
      this.waitingForPayment = true;
      const BridgerPayButton = document.querySelector('.bp-cashier-button');

      if (BridgerPayButton) {
        BridgerPayButton.click();
      }
    });
  },
  beforeDestroy() {
    const bpModal = document.querySelector('.bp-modal');
    if (bpModal) {
      bpModal.remove();
    }
  },
};
</script>

<style lang="scss">
.bp-cashier-button {
  display: none;
}

.wrap-apm-payment-waiting {
  .bp-container {
    overflow: hidden;
  }
}
</style>
