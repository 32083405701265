import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { isV2 } from '@/services/apiVersion';
import { useIsAppInitialized } from '@/composables/useIsAppInitialized';
import { usePaymentMethodsInit } from '@/composables/usePaymentMethodsInit';
// eslint-disable-next-line import/no-cycle
import { EXCHANGE_FORM, TRANSACTION } from '@/router/routes';
import { AuthValidationService } from '@paybis/frontend-authentication-app/src/services/auth-validation';
import TokenService from '@paybis/frontend-authentication-app/src/services/token';
import { useIndexPage } from '@/composables/useIndexPage';

export default {
  name: 'ExchangeFormPreloader',

  setup() {
    const store = useStore();
    const router = useRouter();
    const { isAppInitialized } = useIsAppInitialized();
    const { waitPaymentMethods } = usePaymentMethodsInit();
    const { indexPage } = useIndexPage();

    const funnelInvoiceId = computed(() => store.getters['external/funnelInvoiceId']);
    const isOneClickCheckoutFlow = computed(() => store.getters.isOneClickFlow);
    const userEmail = computed(() => store.getters['request/email']);
    const sourceInfo = computed(() => store.getters.sourceInfo);

    onMounted(async () => {
      await isAppInitialized;

      const AuthValidator = new AuthValidationService({
        email: userEmail.value,
        sourceInfo: sourceInfo.value,
        onInvalidData: () => {
          TokenService.removeTokens();
        },
        onValidData: tokens => {
          store.dispatch('authenticate', { tokens });
          store.dispatch('getFeatureFlags');
          store.dispatch('loadClientData');
        },
      });

      await AuthValidator.validateUserTokens();

      if (funnelInvoiceId.value || isOneClickCheckoutFlow.value) {
        router.push({ name: TRANSACTION });
        return;
      }

      if (isV2()) {
        await waitPaymentMethods();

        store.dispatch('v2/widgetQuote/updatePaymentMethodByQuote', {}, { root: true });

        router.push({ name: indexPage.value });
        return;
      }

      router.push({ name: EXCHANGE_FORM });
    });

    return {};
  },
};
