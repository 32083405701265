// eslint-disable-next-line import/no-cycle
import client from '@/network/clients/widget';
// eslint-disable-next-line import/no-cycle
import clientProcessing from '@/network/clients/processing';
import {
  X_DEVICE_ID_HEADER,
  X_PARTNER_ID_HEADER,
} from '@paybis/frontend-authentication-app/src/network/interceptors/userPartnerIntercepor';

export default {
  async getRequest(requestId) {
    const options = {
      skipAuthHeader: true,
    };

    const { data } = await client.get(`/v1/request/${requestId}`, options);

    return data;
  },

  async getConfig(requestId) {
    const options = {
      skipAuthHeader: true,
    };

    const { data } = await client.get(`/v1/config/${requestId}`, options);

    return data;
  },

  async refreshQuote(requestId) {
    const body = {};
    const options = {
      skipAuthHeader: true,
    };

    const { data } = await client.post(`/v1/request/${requestId}/refresh-quote`, body, options);

    return data;
  },

  async getQuoteById(quoteId) {
    const options = {
      skipAuthHeader: true,
    };

    const { data } = await client.get(`/v1/quote/${quoteId}`, options);

    return data;
  },

  async generateQuote(requestId, { quote, locale }) {
    const options = {
      skipAuthHeader: true,
      headers: {
        'X-User-Locale': locale,
      },
    };

    const { data } = await client.post(`/v1/request/${requestId}/quote`, quote, options);

    return data;
  },

  async createTransaction(requestId) {
    const body = {
      requestId,
    };

    const { data } = await client.post('/v1/transaction', body);

    return data;
  },

  async getTransactionHistory(params) {
    const body = {
      params,
    };

    const response = await clientProcessing.get('/v1/user-transaction', body);

    const { data, meta } = response.data;

    return {
      data, meta,
    };
  },

  async getRequestIdByInvoice({ invoiceId }) {
    const { data } = await client.get(`/v1/invoice/${invoiceId}/request`);
    return data;
  },

  async getFeatureFlags() {
    const { data } = await client.get('/v1/feature-flag');
    return data;
  },

  async assignQuoteToRequest({ requestId }) {
    const options = {
      skipAuthHeader: true,
    };
    await client.post(`/v1/request/${requestId}/confirm`, {}, options);

    return {};
  },

  async getClientDetails() {
    const { data } = await client.get('/v1/user');
    return data;
  },

  passwordlessLogin({ requestId, oneTimeToken, nSurePartnerId, nSureDeviceId }) {
    const headers = {};
    if (nSurePartnerId) {
      headers[X_PARTNER_ID_HEADER] = nSurePartnerId;
    }
    if (nSureDeviceId) {
      headers[X_DEVICE_ID_HEADER] = nSureDeviceId;
    }
    return client.post(`/v1/request/${requestId}/login`, {
      oneTimeToken,
    }, { headers });
  },
};
