import Cancelled from './views/cancelled';
import CardAuthorization from './views/card-authorization';
import Completed from './views/completed';
import ExtraVerification from './views/extra-verification';
import GiftCard from './views/gift-card';
import TransactionFlow from './views/index';
import PaymentCheck from './views/payment-check';
import PaymentDetails from './views/payment-details';
import PaymentError from './views/payment-error';
import PaymentReview from './views/payment-review';
import PaymentWaiting from './views/payment-waiting';
import PayoutDetails from './views/payout-details';
import PayoutWaiting from './views/payout-waiting';
import Rejected from './views/rejected';
import BasicVerification from '../../../src/views/BasicVerification';

const appConfig = {
  fallback: {
    TransactionFlow,
    CardAuthorization,
    Cancelled,
    Completed,
    ExtraVerification,
    GiftCard,
    PaymentCheck,
    PaymentDetails,
    PaymentError,
    PaymentReview,
    PaymentWaiting,
    PayoutDetails,
    PayoutWaiting,
    Rejected,
    BasicVerification,
  },
  paybis: {},
  widget: {},
};

const defaultAppType = 'fallback';
let currentAppType;
const exportComponentsInstance = {};

const getComponent = cName => {
  const componentFallback = appConfig[defaultAppType][cName];
  let componentBuilder = componentFallback;

  if (appConfig[currentAppType] && appConfig[currentAppType][cName]) {
    componentBuilder = appConfig[currentAppType][cName];
  }

  switch (typeof componentBuilder) {
    case 'function':
      return componentBuilder(componentFallback);
    case 'object':
      return componentBuilder;
    default:
      throw new Error('Unknown component');
  }
};

export const install = ({ appType, config }) => {
  currentAppType = appType || defaultAppType;
  if (appConfig[appType]) {
    Object.assign(appConfig[appType], config);
  }
};

export default (() => {
  const allRegistredComponents = Object.values(appConfig)
    .map(configs => Object.keys(configs))
    .flat();

  const uniqueRegistredComponents = [...new Set(allRegistredComponents)];

  uniqueRegistredComponents.forEach(componentName => {
    Object.defineProperty(exportComponentsInstance, componentName, {
      get() {
        return getComponent(componentName);
      },
    });
  });

  return exportComponentsInstance;
})();
