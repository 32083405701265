import cookieStore from './cookie';
import { Session } from '../models/session';

const SESSION_KEY = '__authorization-session';

export default {
  hasStoredSession() {
    return cookieStore.get(SESSION_KEY) !== undefined;
  },

  saveSession(session) {
    cookieStore.set(SESSION_KEY, JSON.stringify(session), {
      expires: session.getExpirationTime(),
    });
  },

  getSession() {
    const storedSession = cookieStore.get(SESSION_KEY);

    if (storedSession === null) {
      return null;
    }

    const { sessionId, emailOtpSession, phoneOtpSession, locale, flowName, theme, sourceInfo, emailProvided } = JSON.parse(storedSession);
    const { expirationTime, attempts, canBeResentAfter, confirmed, context: userEmail } = emailOtpSession;

    const session = new Session({
      sessionId,
      expirationTime,
      canBeResentAfter,
      attempts,
    }, userEmail, locale, flowName, theme, sourceInfo);

    if (emailProvided) {
      session.setEmailProvided();
    }
    if (confirmed) {
      session.confirmEmailOtpSession();
    }
    if (phoneOtpSession === null) {
      return session;
    }

    const {
      expirationTime: phoneOtpExpirationTime,
      canBeResentAfter: phoneOtpCanBeResentAfter,
      attempts: phoneOtpAttempts,
      context: phoneNumber,
    } = phoneOtpSession;
    session.setPhoneOtpSession(phoneOtpExpirationTime, phoneOtpCanBeResentAfter, phoneOtpAttempts, phoneNumber);

    return session;
  },

  getSessionEmail() {
    const session = this.getSession();
    const emailOtpSession = session.getEmailOtpSession();

    if (emailOtpSession && emailOtpSession.context) {
      return emailOtpSession.context;
    }

    return null;
  },

  clearStoredSession() {
    cookieStore.remove(SESSION_KEY);
  },

  verifySession(email = '', sourceInfo = {}, isEmailProvided = false) {
    const session = this.getSession();
    const { source: sSource } = session.getSourceInfo();

    const { source } = sourceInfo;
    if (source !== sSource || isEmailProvided !== session.isEmailProvided()) {
      return false;
    }

    // XXX: allow to resume session if no email is provided
    return email === this.getSessionEmail();
  },
};
