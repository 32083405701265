import { UAParser } from 'ua-parser-js';

export const ignoredStatusCodes = [401, 403, 422];

export const ignoredErrorMessages = [
  'ReportingObserver [deprecation]',
  'Request aborted',
  'Non-Error promise rejection captured',
  'undefined',
  'Blocked a frame with origin',
  'DataCloneError: Proxy object could not be cloned.',
];

export const ignoredUrls = [
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extensions:\/\//i,
  /^safari-extension:\/\//i,
];

export const filterOldBrowsers = (event, hint) => {
  const parser = new UAParser(navigator.userAgent);
  const browser = parser.getBrowser();

  if (browser.name === 'Chrome' && browser.major < 109) {
    return null;
  }
  if (browser.name === 'Firefox' && browser.major < 54) {
    return null;
  }
  if (browser.name === 'Safari' && browser.major < 10) {
    return null;
  }
  if (browser.name === 'Opera' && browser.major < 38) {
    return null;
  }
  return event;
};

export const filterUnwantedExceptions = (event, hint) => {
  if (event.exception?.values && event.exception.values[0].value.includes('Non-Error exception captured with keys')) {
    try {
      const { statusCode, stack } = event.extra.__serialized__.error;

      const isDeveloperError = statusCode === 'DEVELOPER_ERROR';
      const isGooglePayNonErrorException = stack.indexOf('gp/p/js/pay.js') !== -1;

      if (isGooglePayNonErrorException && !isDeveloperError) {
        return null;
      }
    } catch (e) {
      // Do not break the flow if error occurs
    }
  } else {
    try {
      const { response } = hint.originalException;

      if (ignoredStatusCodes.includes(response?.status)) {
        return null;
      }
    } catch (e) {
      // Do not break the flow if error occurs
    }
  }
  return event;
};
