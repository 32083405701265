import PartnerService from '../../services/partner';

export const X_PARTNER_ID_HEADER = 'X-Partner-ID';
export const X_DEVICE_ID_HEADER = 'X-Device-ID';

export function addPartnerHeader(config) {
  try {
    const partner = PartnerService.getPartner();
    if (partner.hasId()) {
      config.headers[X_PARTNER_ID_HEADER] = partner.getId();
    }
  } catch (e) {
    if (window && window.nSurePartnerId) {
      // HOTFIX: pass nsure partner id
      config.headers[X_PARTNER_ID_HEADER] = window.nSurePartnerId;
    }
  }

  // DO NOT prevent return of config object
  return config;
}
