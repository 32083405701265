// eslint-disable-next-line import/no-cycle
import widgetApiClient from '@/services/widget/widgetService.v2';

const mutations = {
  SET_PAYMENT_METHODS: 'setPaymentMethods',
  SET_PAYMENT_METHOD: 'setPaymentMethod',
  SET_PAYOUT_METHODS: 'setPayoutMethods',
  SET_PAYOUT_METHOD: 'setPayoutMethod',
};

export default {
  namespaced: true,

  state: {
    paymentMethods: [],
    payoutMethods: [],
    paymentMethod: {},
    payoutMethod: {},
  },

  getters: {
    paymentMethods: ({ paymentMethods }, getters, rootState, rootGetters) => {
      const predefinedPaymentMethod = rootGetters['request/paymentMethod'];

      if (!predefinedPaymentMethod) {
        return paymentMethods;
      }

      return paymentMethods.sort((a, b) => {
        if (a.paymentMethod === predefinedPaymentMethod) {
          return -1;
        }

        if (b.paymentMethod === predefinedPaymentMethod) {
          return 1;
        }

        return 0;
      });
    },
    payoutMethods: ({ payoutMethods }, getters, rootState, rootGetters) => {
      const predefinedPayoutMethod = rootGetters['request/payoutMethod'];
      if (!predefinedPayoutMethod) {
        return payoutMethods;
      }

      return payoutMethods.sort((a, b) => {
        if (a.paymentMethod === predefinedPayoutMethod) {
          return -1;
        }

        if (b.paymentMethod === predefinedPayoutMethod) {
          return 1;
        }

        return 0;
      });
    },
    paymentMethod: ({ paymentMethod }) => paymentMethod,
    payoutMethod: ({ payoutMethod }) => payoutMethod,
    fromPaymentMethod: (state, getters, rootState, rootGetters) => {
      const { isSellCryptoFlow } = rootGetters;
      const { paymentMethod, payoutMethod } = getters;

      return !isSellCryptoFlow
        ? { paymentMethod: paymentMethod.id }
        : { payoutMethod: payoutMethod.id };
    },

    toPaymentMethod: (state, getters, rootState, rootGetters) => {
      const { isSellCryptoFlow } = rootGetters;
      const { paymentMethod, payoutMethod } = getters;

      return isSellCryptoFlow
        ? { payoutMethod: payoutMethod.id }
        : { paymentMethod: paymentMethod.id };
    },
  },

  mutations: {
    [mutations.SET_PAYMENT_METHODS](state, payload) {
      state.paymentMethods = payload;
    },
    [mutations.SET_PAYMENT_METHOD](state, payload) {
      state.paymentMethod = payload;
    },
    [mutations.SET_PAYOUT_METHODS](state, payload) {
      state.payoutMethods = payload;
    },
    [mutations.SET_PAYOUT_METHOD](state, payload) {
      state.payoutMethod = payload;
    },
  },

  actions: {
    async getMethods({ dispatch, rootGetters }) {
      const getPaymentMethodsPayload = {
        currencyFrom: rootGetters['exchangeForm/fromCurrency'],
        currencyTo: rootGetters['exchangeForm/toCurrencyCode'],
      };
      if (!getPaymentMethodsPayload.currencyTo || !getPaymentMethodsPayload.currencyFrom) {
        return;
      }
      if (rootGetters.isSellCryptoFlow) {
        await dispatch('getPayoutMethods', getPaymentMethodsPayload);
      } else {
        await dispatch('getPaymentMethods', getPaymentMethodsPayload);
      }
    },

    async getPaymentMethods({ commit, dispatch, getters, rootGetters }, { currencyFrom, currencyTo }) {
      const { requestId } = rootGetters;

      const { data } = await widgetApiClient.getPaymentMethods({
        requestId,
        currencyFrom,
        currencyTo,
      });

      commit(mutations.SET_PAYMENT_METHODS, data);

      const quotePaymentMethods = rootGetters['v2/widgetQuote/paymentMethods'];
      if (quotePaymentMethods.length > 0) {
        dispatch('mapPaymentMethodsWithQuote', { quotePaymentMethods });
      }

      dispatch('setSelectedPaymentMethod');
    },

    async getPayoutMethods({ commit, dispatch, rootGetters }, { currencyFrom, currencyTo }) {
      const { requestId } = rootGetters;

      const { data } = await widgetApiClient.getPayoutMethods({
        requestId,
        currencyFrom,
        currencyTo,
      });

      commit(mutations.SET_PAYOUT_METHODS, data);

      const quotePayoutMethods = rootGetters['v2/widgetQuote/payoutMethods'];
      if (quotePayoutMethods.length > 0) {
        dispatch('mapPayoutMethodsWithQuote', { quotePayoutMethods });
      }

      dispatch('setSelectedPayoutMethod');
    },

    setSelectedPaymentMethod({ dispatch, getters, rootGetters }) {
      const { paymentMethod: selectedPaymentMethod } = getters;
      const backendDefinedPaymentMethod = rootGetters['request/paymentMethod'];
      const predefinedPaymentMethodSlug = selectedPaymentMethod.id
        || backendDefinedPaymentMethod;

      const { paymentMethods } = getters;
      const paymentMethod = paymentMethods.find(
        item => item.id === predefinedPaymentMethodSlug,
      );
      if (
        selectedPaymentMethod.id !== undefined
        && selectedPaymentMethod.id === paymentMethod?.id
      ) {
        return;
      }
      if (paymentMethod) {
        dispatch('setPaymentMethod', paymentMethod);
        dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', { paymentMethod: paymentMethod.id }, { root: true });

        return;
      }
      const selectedPaymentMethodSlug = paymentMethods[0]?.id || paymentMethods[0]?.paymentMethod;
      dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', {
        paymentMethod: selectedPaymentMethodSlug,
      }, { root: true });
      dispatch('setPaymentMethod', paymentMethods[0]);
    },

    setSelectedPayoutMethod({ dispatch, getters }) {
      const { payoutMethod: selectedPayoutMethod, payoutMethods } = getters;

      const predefinedPayoutMethodSlug = selectedPayoutMethod.id;
      const payoutMethod = payoutMethods.find(
        item => item.id === predefinedPayoutMethodSlug,
      );

      if (
        selectedPayoutMethod.id !== undefined
        && selectedPayoutMethod.id === payoutMethod?.id
      ) {
        return;
      }

      if (payoutMethod) {
        dispatch('setPayoutMethod', payoutMethod);
        dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', { paymentMethod: payoutMethod.id }, { root: true });

        return;
      }

      const { id: selectedPayoutMethodSlug } = payoutMethods[0];

      dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', {
        paymentMethod: selectedPayoutMethodSlug,
      }, { root: true });
      dispatch('setPayoutMethod', payoutMethods[0]);
    },

    setPaymentMethod({ commit, dispatch }, selectedPaymentMethod) {
      commit(mutations.SET_PAYMENT_METHOD, selectedPaymentMethod);
      const { paymentMethod: selectedPaymentMethodSlug } = selectedPaymentMethod;

      dispatch('v2/widgetQuote/setWidgetQuote', { paymentMethod: selectedPaymentMethodSlug }, { root: true });
    },

    unsetPaymentMethod({ dispatch }) {
      dispatch('setPaymentMethod', {});
    },

    setPayoutMethod({ commit, dispatch }, selectedPayoutMethod) {
      commit(mutations.SET_PAYOUT_METHOD, selectedPayoutMethod);

      // XXX: not a mistype, backend returns `paymentMethod` in payout method object
      const { paymentMethod: selectedPayoutMethodSlug } = selectedPayoutMethod;

      dispatch('v2/widgetQuote/setWidgetQuote', { payoutMethod: selectedPayoutMethodSlug }, { root: true });
    },

    unsetPayoutMethod({ dispatch }) {
      dispatch('setPayoutMethod', {});
    },

    mapPaymentMethodsWithQuote({ commit, getters }, { quotePaymentMethods }) {
      const { paymentMethods } = getters;
      paymentMethods.forEach(paymentMethod => {
        paymentMethod.quote = quotePaymentMethods.find(
          item => item.paymentMethod === paymentMethod.paymentMethod,
        );
      });

      commit(mutations.SET_PAYMENT_METHODS, paymentMethods);
    },

    mapPayoutMethodsWithQuote({ commit, getters }, { quotePayoutMethods }) {
      const { payoutMethods } = getters;
      payoutMethods.forEach(payoutMethod => {
        payoutMethod.quote = quotePayoutMethods.find(
          item => item.paymentMethod === payoutMethod.paymentMethod,
        );
      });

      commit(mutations.SET_PAYOUT_METHODS, payoutMethods);
    },
    setPaymentOrPayoutMethodFromTransaction({ commit, rootGetters }) {
      const { isSellCryptoFlow } = rootGetters;

      const { name, slug } = isSellCryptoFlow
        ? rootGetters['transaction/payout']
        : rootGetters['transaction/payment'];

      const methodFromTransaction = {
        id: slug,
        name,
        paymentMethod: slug,
      };

      const neededMutation = isSellCryptoFlow ? mutations.SET_PAYOUT_METHOD : mutations.SET_PAYMENT_METHOD;

      commit(neededMutation, methodFromTransaction);
    },
  },
};
