<template>
  <div class="exchange-form">
    <HeaderTitle
      :is-back-button-available="isBackButtonAvailable"
      @back="goToPaymentMethodsScreen"
    >
      <template v-if="isSellCryptoFlow">
        {{ $t('widget.exchange-form.sell.title') }}
      </template>
      <template v-else>
        {{ $t('widget.exchange-form.title') }}
      </template>
    </HeaderTitle>

    <PendingTransactionNotification />

    <div class="exchange-form__wrapper">
      <ExchangeFormBody
        :is-loading="isLoading"
        :quote="quote"
      />

      <ExchangeFormFooter
        :disable-continue="isContinueDisabled"
        :is-loading="isNextStepLoading"
        @continue="onContinue"
      />
    </div>

    <SelectCurrencyModal />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ExchangeFormBody from './ExchangeFormBody';
import ExchangeFormFooter from '@/components/ExchangeForm/ExchangeFormFooter';
import SelectCurrencyModal from '@/components/v2/SelectCurrencyModal';
import { HeaderTitle } from '@/overrides/AuthFlow/exports';
import { refreshQuoteWithPaymentMethodSubscriber, stopQuoteAutorefresh } from '@/store/widgetQuote';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';
import { AUTH, PAYMENT_METHODS_V2, TRANSACTION } from '@/router/routes';
import { IntroService } from '@/services/introInitService';
import PendingTransactionNotification from '@/components/PendingTransactionNotification';
import { usePaymentMethodsInit } from '@/composables/usePaymentMethodsInit';

const store = useStore();
const router = useRouter();

const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);

const selectedPaymentMethod = computed(() => {
  if (isSellCryptoFlow.value) {
    return store.getters['v2/paymentMethods/payoutMethod'].paymentMethod;
  }

  return store.getters['v2/paymentMethods/paymentMethod'].paymentMethod;
});

const paymentOrPayoutMethod = computed(() => store.getters['v2/widgetQuote/quote'](selectedPaymentMethod.value));
const paymentOrPayoutMethods = computed(() => {
  if (isSellCryptoFlow.value) {
    return store.getters['v2/widgetQuote/payoutMethods'];
  }

  return store.getters['v2/widgetQuote/paymentMethods'];
});
const paymentMethodError = computed(() => store.getters['v2/widgetQuote/paymentMethodError'](selectedPaymentMethod.value));
const payoutMethodError = computed(() => store.getters['v2/widgetQuote/payoutMethodError'](selectedPaymentMethod.value));
const isBackButtonAvailable = computed(() => !store.getters['widgetQuote/isQuoteLocked']);

const quote = computed(() => {
  const exchangeFormQuote = store.getters['exchangeForm/quote'];
  if (!paymentOrPayoutMethod.value?.amountFrom) {
    return exchangeFormQuote;
  }

  // eslint-disable-next-line max-len
  const { amountFromAmount, amountToAmount, amountReceived, currencyCodeFrom, currencyCodeTo } = paymentOrPayoutMethod.value;

  return {
    id: exchangeFormQuote.quoteId,
    amountFrom: amountFromAmount,
    amountTo: amountToAmount,
    amountReceived,
    currencyCodeFrom,
    currencyCodeTo,
    currencyCodeToFixed: exchangeFormQuote.currencyCodeToFixed,
    currencySourceFrom: exchangeFormQuote.currencySourceFrom,
  };
});

const isQuoteExist = computed(() => quote.value?.id);
const isLoading = computed(() => store.getters['exchangeForm/isLoading']);
const isNextStepLoading = ref(false);

const onContinue = async () => {
  stopQuoteAutorefresh();
  isNextStepLoading.value = true;
  try {
    if (isNewExchangeFormFlowEnabled()) {
      if (store.getters.jwtTokenExists()) {
        if (paymentOrPayoutMethods.value.length > 1) {
          await store.dispatch('v2/widgetQuote/refreshQuoteWithPaymentMethod', null, { root: true });
        }

        router.push({
          name: TRANSACTION,
        });

        return;
      }

      router.push({
        name: AUTH,
      });

      return;
    }

    const createTransactionPayload = {};

    if (isSellCryptoFlow.value) {
      createTransactionPayload.payoutMethod = selectedPaymentMethod.value;
    } else {
      createTransactionPayload.paymentMethod = selectedPaymentMethod.value;
    }

    await store.dispatch('createTransaction', createTransactionPayload);

    router.push({
      name: TRANSACTION,
    });
  } catch {
    refreshQuoteWithPaymentMethodSubscriber(store);
    isNextStepLoading.value = false;
  }
};

onMounted(() => {
  stopQuoteAutorefresh();
  refreshQuoteWithPaymentMethodSubscriber(store);

  // timeout is used to wait out all the animations of exchangeForm so hints could capture the final position of it
  setTimeout(() => IntroService.startIntro('exchange-form-guide'), 1200);
});

const goToPaymentMethodsScreen = () => {
  router.push({
    name: PAYMENT_METHODS_V2,
  });
};

watch(paymentMethodError, () => {
  if (paymentMethodError.value?.message) {
    stopQuoteAutorefresh();
  }
}, { immediate: true });

watch(payoutMethodError, () => {
  if (payoutMethodError.value?.message) {
    stopQuoteAutorefresh();
  }
}, { immediate: true });

const isContinueDisabled = computed(() => {
  if (paymentMethodError.value || payoutMethodError.value) {
    return true;
  }
  const isQuoteReady = isQuoteExist.value && !isLoading.value;
  // eslint-disable-next-line max-len
  const isFilledInputs = quote.value && quote.value.amountFrom && quote.value.amountTo;

  return !isQuoteReady || !isFilledInputs;
});

const { waitPaymentMethods } = usePaymentMethodsInit();
const currencyFrom = computed(() => store.getters['exchangeForm/fromCurrency']);
const currencyTo = computed(() => store.getters['exchangeForm/toCurrencyCode']);
watch([currencyFrom, currencyTo], () => {
  waitPaymentMethods();
});
</script>

<style lang="scss" scoped>
.exchange-form {
  &__wrapper {
    margin: 40px auto 0;
    width: rem(350);

    @media screen and (max-width: $tablet-max) {
      margin-top: 48px;
      width: auto;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      margin: 6.25vh auto 0;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      margin: 5.57vh auto 0;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      margin: 4.24vh auto 0
    }
  }
}
</style>
