<template>
  <div class="email-otp">
    <i class="icon icon-envelope-auth email-otp__icon" />

    <div class="auth-app-header">
      {{ $t( `authentication-app.funnel.email-otp-heading` ) }}
    </div>

    <p class="sent-notice">
      {{
        $t( 'authentication-app.otp.email.sent-verification-code', {
          email: session.getEmailOtpSession().getContext(),
        } )
      }}
      <br>
      {{
        $t('authentication-app.otp.email.input-code-label', {
          email: session.getEmailOtpSession().getContext(),
        })
      }}
    </p>

    <Otp
      :resend-time="session.getEmailOtpSession().getCanBeResentAfter()"
      :error-message="errorMessage"
      :is-loading="isLoading"
      :is-loading-resend="isLoadingResend"
      :is-back-button-enabled="!session.isEmailProvided()"
      :has-no-attempts-left="session.getEmailOtpSession().hasNoAttemptsLeft()"
      @success-otp="onSuccessOtp"
      @submit="onSuccessOtp"
      @incomplete-otp="onIncompleteOtp"
      @resend-otp="onResendOtp"
      @back="handleBack"
    />
  </div>
</template>

<script>
import Otp from '../../components/Otp';
import AuthService from '../../services/auth';
import { Session } from '../../models/session';
import { OtpError } from '../../models/auth-error';
import { useI18n } from 'vue-i18n';

export default {
  name: 'EmailOtpView',

  components: {
    Otp,
  },
  inject: ['startAuthSession'],

  props: {
    session: {
      type: Session,
      required: true,
    },
    flowName: {
      type: String,
      default: 'funnel',
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      errorMessage: '',
      isLoading: false,
      isLoadingResend: false,
    };
  },

  computed: {
    otpSession() {
      return this.session.getEmailOtpSession();
    },
    userEmail() {
      return this.session.getEmailOtpSession().getContext();
    },
  },

  methods: {
    onIncompleteOtp() {
      this.errorMessage = '';
    },

    onResendOtp() {
      if (this.isLoading) {
        return;
      }

      this.errorMessage = '';

      if (this.otpSession.hasExpired()) {
        this.isLoadingResend = true;
        return this.startAuthSession(this.userEmail).finally(() => {
          this.isLoadingResend = false;
        });
      }

      this.isLoading = true;
      this.isLoadingResend = true;
      this.resendEmailOtpRequest().finally(() => {
        this.isLoading = false;
        this.isLoadingResend = false;
      });
    },

    resendEmailOtpRequest() {
      return AuthService.resendEmailOtp(this.session).catch(error => {
        const { statusCode, isCritical } = error;

        if (typeof statusCode === 'undefined' || isCritical) {
          return this.$emit('critical-error', error);
        }

        this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
      });
    },

    onSuccessOtp(code) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      AuthService.confirmEmailOtp(this.session, code).then(tokens => this.$emit('success-otp', tokens))
        .catch(error => {
          this.isLoading = false;

          const { statusCode, isCritical } = error;

          if (error instanceof OtpError) {
            const { canBeResentAt, expirationTime, attempts } = error;
            this.session.setEmailOtpSession(expirationTime, canBeResentAt, attempts, this.userEmail);
          }

          if (statusCode === 'session-not-found') {
            this.otpSession.markAsExpired();
          }

          if (this.otpSession.hasExpired()) {
            this.errorMessage = this.t('authentication-app.error.session-expired');
            return;
          }

          if (typeof statusCode === 'undefined' || isCritical) {
            return this.$emit('critical-error', error);
          }

          this.errorMessage = this.t(`authentication-app.error.${statusCode}`, {
            sessionTimer: this.otpSession.getExpirationTimer(),
            attempts: this.otpSession.getAttempts(),
          });
        });
    },

    handleBack() {
      if (this.isLoading) {
        return;
      }
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/icon';

.icon {
  height: 6rem;
  align-self: center;
  margin-bottom: 30px;
  background-size: contain;

  @media (max-width: $laptop-min) {
    height: 4.5rem;
    margin: 0 auto 20px;
  }
}

.auth-app-header {
  margin-bottom: 30px;
}

.sent-notice {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
  line-height: 1.375;
}
</style>
