import { SumsubConfig } from '../../models/sumsub';
import ProcessingClient from '../../services/clients/processing-client';

export default {
  namespaced: true,
  state: {
    sumsubConfig: new SumsubConfig(),
  },
  getters: {
    sumsubConfig: state => state.sumsubConfig,
  },
  mutations: {
    setSumsubConfig(state, payload) {
      state.sumsubConfig = payload;
    },
  },
  actions: {
    getConfig({ commit }, verificationFieldTypeSlug) {
      return ProcessingClient.getExtraVerificationConfig(
        verificationFieldTypeSlug,
      ).then(({ data }) => {
        const { apiUrl, levelName, accessToken, email } = data;
        commit(
          'setSumsubConfig',
          new SumsubConfig(true, accessToken, levelName, apiUrl, email),
        );
      });
    },
  },
};
