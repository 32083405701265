<template>
  <span>
    <Title
      v-if="!canShowAddCardForm"
      data-testid="payment-title"
    >
      {{ $t('transaction-flow.steps.wallet.title.non-crypto') }}
    </Title>
    <Content>
      <FormRow v-if="!canShowAddCardForm">
        <Loader
          v-if="isLoading"
          class="mt-32 mb-32 ml-auto mr-auto "
        />
        <CardSelect
          v-else
          :cards="cards"
          :selected="selectedCardId"
          :is-card-payout="payout.isBankCard()"
          @selectCard="handleSelectCard"
          @pay="payOutWithCard(selectedCardId)"
          @deleteCard="handleDeleteCard"
        />
      </FormRow>

      <div
        v-if="canShowAddCardForm"
        class="card-payment-drawer"
      >
        <Icon
          v-if="isAvailablePayment"
          class="card-payment-drawer__icon"
          icon="arrow-left"
          @click.native="setSelectedCard(null)"
        />
        <Title
          data-testid="payment-title"
          class="card-payment-drawer__title"
        >
          {{ $t('transaction-flow.steps.payment-details.new_card_title') }}
        </Title>
        <FormRow double-margin>
          <CardForm
            ref="cardForm"
            :fields="addCard.getFormFields()"
            :billing-address-fields="addCard.billingAddress.getFormFields()"
            :invoice="invoice"
            :is-payout="true"
            @created="createCardHandler"
            @error="createCardErrorHandler"
          />
        </FormRow>
        <Actions :key="canShowAddCardForm">
          <template
            v-if="canShowAddCardForm"
            #submit
          >
            <button
              class="btn btn-lg btn-primary"
              :class="{
                'is-loading': isLocked,
              }"
              :disabled="isLocked"
              data-testid="pay-button"
              @click="createCardAndPayOut"
            >
              {{ $t('shared.navigation.button.continue') }}
            </button>
          </template>
          <template
            v-if="canShowAddCardForm"
            #accessory
          >
            <SecureStamp />
          </template>
        </Actions>
      </div>

      <Actions
        v-if="canShowLowerCancelButton"
        :key="canShowAddCardForm"
        :cancel-button-default-class="true"
      />
      <RefundDisclaimer v-if="isShowRefundDisclaimer" />
    </Content>
  </span>
</template>

<script>
import Loader from '@paybis/frontend-common-lib/src/components/loader';
import Content from '@paybis/frontend-common-lib/src/components/content';
import FormRow from '@paybis/frontend-common-lib/src/components/form/form-row';
import Title from '@paybis/frontend-common-lib/src/components/title';
import Icon from '@paybis/frontend-common-lib/src/components/icon';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Storage from '@paybis/frontend-common-lib/src/services/storage';
import ProcessingClient from '@paybis/frontend-common-lib/src/services/clients/processing-client';
import CardPaymentClient from '@paybis/frontend-common-lib/src/services/clients/card-payment-client';
import RefundDisclaimer from './refund-disclaimer';

import Actions from './actions';
import CardForm from './card-form';
import CardSelect from './card-select';
import SecureStamp from './secure-stamp';

import eventBus from '../helpers/eventBus';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    RefundDisclaimer,
    Title,
    Content,
    FormRow,
    CardSelect,
    CardForm,
    Actions,
    SecureStamp,
    Loader,
    Icon,
  },
  props: {
    invoice: { type: String, required: true },
    transaction: { type: Object, required: true },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
      loadingCards: false,
    };
  },
  computed: {
    ...mapGetters('card', [
      'cards',
      'selectedCardId',
      'addCard',
      'selectedCard',
      'isCardsLoaded',
    ]),
    ...mapGetters('quote', ['paymentAmount']),
    ...mapGetters(['isLocked']),
    ...mapGetters('transaction', ['payout']),
    ...mapGetters('feature', [
      'cardEntryIFrameAppFeature',
    ]),
    isPaymentMethodSelect() {
      return false;
    },
    isAvailablePayment() {
      return (
        !this.isLoading
          && this.payout.isBankCard() && this.cards.length > 0
      );
    },
    canShowAddCardForm() {
      // XXX: cant use selectedCard.isNew() method
      // @see card store selectedCard getter.
      return (
        !this.isPaymentMethodSelect
          && !this.isLoading
          && this.selectedCardId === 'new'
      );
    },
    canShowLowerCancelButton() {
      return this.isPaymentMethodSelect || this.selectedCardId !== 'new';
    },
    isLoading() {
      return this.loadingCards;
    },
    isShowRefundDisclaimer() {
      return !this.isLoading;
    },
  },
  watch: {
    isLoading: {
      handler(newValue, prevValue) {
        // Need to check explicitly, because sometimes we haven't loading
        // and `prevValue` is `undefined`
        if (!newValue && prevValue !== true) {
          eventBus.emit('transaction-flow.payment-details.loaded');
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('card', [
      'getCards',
      'setFormFieldErrors',
      'createCard',
      'deleteCard',
      'chooseFirstAvailableCard',
    ]),
    ...mapMutations(['lock', 'unlock', 'clearLocks']),
    ...mapMutations('card', [
      'setSelectedCardId',
    ]),
    ...mapMutations('transaction', ['setPayoutErrorMessage']),
    setSelectedCard(cardId) {
      try {
        this.setSelectedCardId(cardId);

        // Remove selected card id
        setTimeout(() => {
          Storage.remove(`selectedCardId:${this.invoice}`);
        }, 1000);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    selectDefaultPayment() {
      const selectedCardId = Storage.get(`selectedCardId:${this.invoice}`);
      // Selects the card if it has been selected before
      if (selectedCardId) {
        this.setSelectedCard(selectedCardId);
      } else {
        this.chooseFirstAvailableCard();
      }
    },
    requestCards() {
      // TODO this method not called here anymore, because it was
      // moved into common lib store. Need to get rid of it or refactor
      // @see frontend-common-lib/store/modules/transaction.js:132
      return new Promise((resolve, reject) => {
        this.loadingCards = true;

        this.getCards().then(() => {
          if (!this.selectedCard.isNew() || this.selectedCard.hasError()) {
            return Promise.resolve();
          }
          this.chooseFirstAvailableCard();
        })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            this.loadingCards = false;
            this.clearLocks();

            resolve();
          });
      });
    },
    handleSelectCard(cardId) {
      eventBus.emit('transaction-flow.payment.select-card');
      this.setPayoutErrorMessage(null);
      this.setSelectedCard(cardId);
    },
    createCardHandler(payload) {
      eventBus.emit('transaction-flow.payment.starts');

      this.payOutWithCard(payload.id);
    },
    createCardErrorHandler() {
      this.unlock();
    },
    createCardAndPayOut() {
      this.lock();
      if (this.cardEntryIFrameAppFeature.isEnabled()) {
        this.$refs.cardForm.submit();
        return;
      }

      const { cardNumber } = this.addCard.getFormFields();
      if (cardNumber.getErrorMessage()) {
        this.unlock();
        return false;
      }

      const { invoice } = this;

      eventBus.emit('transaction-flow.payment.starts');

      return this.createCard({
        currency: this.paymentAmount.getCurrency(),
        invoice,
        browserLanguage: this.browserLanguage,
      })
        .then(({ data }) => {
          const { id } = data;
          this.addCard.setId(id);
          this.payOutWithCard(id);
        })
        .catch(({ data, status }) => {
          this.unlock();

          eventBus.emit('transaction-flow.payment.fails');

          if (status === 400 && data.code === 'CARD_IS_VERIFYING') {
            // Show error popup
            this.selectedCard.setAlreadyVerifying();
            return Promise.resolve();
          }

          if (data && data.errors) {
            this.setFormFieldErrors(data.errors);

            return Promise.resolve();
          }

          this.addCard.setError();
        });
    },
    async payOutWithCard(cardId) {
      this.lock();

      try {
        const { data: payoutData } = await CardPaymentClient.isPayoutAllowedByCardId({ cardId, invoice: this.invoice });
        if (!payoutData.support) {
          this.setPayoutErrorMessage(this.t('validators.transaction-flow.payment-details.card-details.payout-receive').toString());
          return;
        }

        // Remember selected card id
        Storage.put(`selectedCardId:${this.invoice}`, cardId);

        window.scrollTo(0, 0);

        await ProcessingClient.submitTransactionPayoutDetailsByInvoice(
          this.invoice,
          {
            cardId,
          },
          'en',
        );

        eventBus.emit('transaction-flow.update-transaction');

        // UI: Reset add card form fields
        if (this.selectedCard.fields) {
          this.selectedCard.resetFormFields();
        }
      } catch (error) {
        this.onCatchError();
        return Promise.reject(error);
      } finally {
        this.clearLocks();
      }
    },
    handleDeleteCard(card) {
      this.deleteCard(card).then(result => {
        if (result) {
          this.chooseFirstAvailableCard();
        }
      });
    },
  },
  async mounted() {
    if (this.cards.length === 0 && !this.isCardsLoaded) {
      await this.requestCards();
    }

    eventBus.emit('transaction-flow.card-payment-details.loaded');

    if (!this.selectedCard.hasError()) {
      this.selectDefaultPayment();
    }
  },
};
</script>

<style scoped lang="scss">
@keyframes open {
  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.card-payment-drawer {
  width: 100%;
  background: $white;
  opacity: 0;
  visibility: hidden;
  transform: translate(100px, 0);
  animation: open 0.4s forwards cubic-bezier(0.4, 0, 0.2, 1) 0.2s;

  &__icon {
    cursor: pointer;
    font-size: rem(28);
    line-height: 1.14;
    position: absolute;
    top: rem(2);
    left: 0;
    z-index: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  &__title {
    margin: 16px 36px 24px !important;

    @media screen and (max-width: $screen-md-max) {
      margin-top: 12px !important;
    }
  }
}
</style>
