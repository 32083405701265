import { h } from 'vue';

import modalManager from './modalManager';

export default {
  name: 'Modal',

  model: {
    prop: 'show',
    event: 'update',
  },

  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      required: true,
    },
    target: {
      type: String,
      default: 'default',
    },
    isWithoutBlur: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    modalId: Symbol('modalId'),
  }),

  created() {
    const holderDismiss = () => {
      this.$emit('reject');
    };

    // this is needed for passing value by link
    const self = this;
    const wrappedComponent = {
      functional: true,
      target: this.target,
      holderDismiss,
      render() {
        return h(self.component, {
          ...self.$attrs,
        });
      },
    };

    modalManager.registerModal({
      key: this.modalId,
      component: wrappedComponent,
    });
  },

  watch: {
    show(value) {
      modalManager.setShownState({
        target: this.target,
        key: this.modalId,
        state: value,
        isWithoutBlur: this.isWithoutBlur,
      });
    },
  },

  render() {
    return null;
  },

  beforeDestroy() {
    modalManager.unregisterModal({
      key: this.modalId,
    });
  },
};
