import WidgetService from '@/services/widget';
import { AppCriticalError } from '@/exceptions/AppCriticalError';
import { REQUEST } from './mutations';
import { emitToParent } from '@/services/widgetApiEvents';

export default {
  namespaced: true,

  state: {
    email: null,
    invoiceId: null,
    quoteId: null,
    requestId: null,
    status: null,
    paymentMethod: '',
    payoutMethod: '',
    isPromoCodesEnabled: false,

    isLoading: false,
  },

  getters: {
    email: ({ email }) => email,
    requestId: ({ requestId }) => requestId,
    invoiceId: (state, getters, globalState, globalGetters) => globalGetters['external/funnelInvoiceId'],
    hasInvoice: (state, getters) => typeof getters.invoiceId !== 'undefined' && getters.invoiceId !== null,
    quoteId: state => state.quoteId,
    wallet: state => state.cryptoWalletAddress || [],
    // eslint-disable-next-line max-len
    hasWallet: (state, getters) => getters.wallet && Array.isArray(getters.wallet) && getters.wallet.length > 0,
    paymentMethod: ({ paymentMethod }) => paymentMethod,
    paymentMethodSlug: ({ paymentMethod: paymentMethodSlug }) => paymentMethodSlug,
    payoutMethod: ({ payoutMethod }) => payoutMethod,
    payoutMethodSlug: ({ payoutMethod: payoutMethodSlug }) => payoutMethodSlug,
    requestStatus: ({ status }) => status,
    isPromoCodesEnabled: ({ isPromoCodesEnabled }) => isPromoCodesEnabled,

    isLoading: state => state.isLoading,
  },

  mutations: {
    [REQUEST.SET_REQUEST]: (state, data) => {
      state.email = data.email;
      state.invoiceId = data.invoiceId;
      state.quoteId = data.quoteId;
      state.requestId = data.requestId;
      state.status = data.status;
      state.isPromoCodesEnabled = data.isPromoCodesEnabled;
      const wallets = data.cryptoWalletAddress || [];
      state.cryptoWalletAddress = Array.isArray(wallets)
        ? data.cryptoWalletAddress : [data.cryptoWalletAddress];
      state.paymentMethod = data.paymentMethod;
      state.payoutMethod = data.payoutMethod;
    },

    [REQUEST.SET_QUOTE_ID]: (state, quoteId) => {
      state.quoteId = quoteId;
    },

    [REQUEST.SET_IS_LOADING]: (state, loadingState) => {
      state.isLoading = loadingState;
    },
  },

  actions: {
    async setRequest({ commit, dispatch }, { requestId }) {
      try {
        const data = await WidgetService.getRequest(requestId);

        data.requestId = requestId;

        commit(REQUEST.SET_REQUEST, data);

        if (data.invoiceId) {
          dispatch('external/setFunnelInvoiceId', {
            invoiceId: data.invoiceId,
          }, {
            root: true,
          });
        }

        return data;
      } catch ({ message }) {
        throw new AppCriticalError(message);
      }
    },

    setRequestWithLoader({ commit, dispatch }, { requestId }) {
      commit(REQUEST.SET_IS_LOADING, true);
      return dispatch('setRequest', {
        requestId,
      }).finally(() => {
        commit(REQUEST.SET_IS_LOADING, false);
      });
    },

    async redirectToInvoice({ commit, dispatch }, { invoiceId }) {
      commit(REQUEST.SET_IS_LOADING, true);

      emitToParent('state', { state: 'showLoader' });

      try {
        const { requestId } = await WidgetService.getRequestIdByInvoice({
          invoiceId,
        });

        const toUrl = new URL(window.location.toString());

        toUrl.searchParams.set('requestId', requestId);

        // XXX: to prevent unlimited loader and unnecessary refresh when we decide to switch on current transaction
        if (toUrl.toString() === window.location.toString()) {
          commit('menu.set.state', false, { root: true });

          emitToParent('state', {
            state: 'loaded',
          });

          return;
        }

        toUrl.hash = undefined;

        window.location.assign(toUrl.toString());
      } catch {
        emitToParent('state', {
          state: 'loaded',
        });
      } finally {
        commit(REQUEST.SET_IS_LOADING, false);
      }
    },

    assignQuoteId({ commit }, quoteId) {
      commit(REQUEST.SET_QUOTE_ID, quoteId);
    },
  },
};
