// eslint-disable-next-line import/no-cycle
import widgetApiClient from '@/services/widget/widgetService.v2';

const mutations = {
  SET_QUOTE: 'setQuote',
  SET_QUOTE_LOADING: 'setQuoteLoading',
  SET_QUOTE_READY: 'setQuoteReady',
  SET_QUOTE_ERROR: 'setQuoteError',
  SET_UNEXPECTED_QUOTE_ERROR: 'setUnexpectedQuoteError',
  RESET_ERRORS: 'resetErrors',
};

export default {
  namespaced: true,

  state: {
    quote: {},
    isQuoteLoading: false,
    unexpectedQuoteError: null,
    hasError: false,
  },

  getters: {
    isQuoteLoading: state => state.isQuoteLoading,
    rawQuote: ({ quote }) => quote,
    hasError: ({ hasError }) => hasError,
    // eslint-disable-next-line max-len
    quote: ({ quote }, getters, rootState, rootGetters) => paymentMethod => {
      const {
        id,
        paymentMethods = [],
        payoutMethods = [],
        direction,
        currencyCodeFrom,
        currencyCodeTo,
      } = quote;
      const { isSellCryptoFlow } = rootGetters;

      if ((!paymentMethods || paymentMethods.length === 0) && !isSellCryptoFlow) {
        return {};
      }

      if ((!payoutMethods || payoutMethods.length === 0) && isSellCryptoFlow) {
        return {};
      }

      const paymentMethodsForCurrentFlow = isSellCryptoFlow ? payoutMethods : paymentMethods;

      const quotePaymentMethod = paymentMethodsForCurrentFlow.find(
        item => item.paymentMethod === paymentMethod,
      );

      if (!quotePaymentMethod) {
        return {};
      }

      // eslint-disable-next-line max-len
      const { amountTo = {}, amountFrom = {}, amountToEquivalent = {}, amountFromEquivalent = {} } = quotePaymentMethod;
      let amountFromAmount = amountFrom.amount;
      if (Math.sign(amountFromAmount) === -1) {
        amountFromAmount = '0';
      }
      let amountToAmount = amountTo.amount;
      if (Math.sign(amountToAmount) === -1) {
        amountToAmount = '0';
      }
      let amountReceived = amountToEquivalent?.amount || amountFromEquivalent?.amount;
      if (Math.sign(amountReceived) === -1) {
        amountReceived = '0';
      }

      return {
        ...quotePaymentMethod,
        id,
        amountFromAmount,
        amountToAmount,
        amountReceived,
        direction,
        currencyCodeFrom,
        currencyCodeTo,
      };
    },
    quoteId: ({ quote: { id = null } }, getters, rootState, rootGetters) => id || rootGetters['request/quoteId'],
    // eslint-disable-next-line max-len
    paymentMethods: ({ quote: { paymentMethods = [] } }, getters) => paymentMethods.map(({ paymentMethod }) => getters.quote(paymentMethod)),
    // eslint-disable-next-line max-len
    payoutMethods: ({ quote: { payoutMethods = [] } }, getters) => payoutMethods.map(({ paymentMethod }) => getters.quote(paymentMethod)),
    // eslint-disable-next-line max-len
    paymentMethodError: ({ quote: { paymentMethodErrors = [] } = {} }) => paymentMethod => (paymentMethodErrors || []).find(item => item.paymentMethod === paymentMethod)?.error,
    // eslint-disable-next-line max-len
    payoutMethodError: ({ quote: { payoutMethodErrors = [] } = {} }) => payoutMethod => (payoutMethodErrors || []).find(item => item.payoutMethod === payoutMethod)?.error,
    selectedPaymentMethod: (state, getters, rootState, rootGetters) => rootGetters['v2/paymentMethods/paymentMethod'].paymentMethod,
    selectedPayoutMethod: (state, getters, rootState, rootGetters) => rootGetters['v2/paymentMethods/payoutMethod'].paymentMethod,
    selectedPaymentMethodSlug: (state, getters, rootState, rootGetters) => rootGetters['v2/paymentMethods/paymentMethod'].paymentMethod,
    selectedPayoutMethodSlug: (state, getters, rootState, rootGetters) => rootGetters['v2/paymentMethods/payoutMethod'].paymentMethod,
    requestId: (state, getters, rootState, rootGetters) => rootGetters['request/requestId'],
    unexpectedQuoteError: state => state.unexpectedQuoteError,
  },

  mutations: {
    [mutations.SET_QUOTE](state, payload) {
      state.quote = payload;
    },
    [mutations.SET_QUOTE_LOADING](state) {
      state.isQuoteLoading = true;
    },
    [mutations.SET_QUOTE_READY](state) {
      state.isQuoteLoading = false;
    },
    [mutations.SET_UNEXPECTED_QUOTE_ERROR](state, message) {
      state.unexpectedQuoteError = message;
    },
    [mutations.RESET_ERRORS](state) {
      state.unexpectedQuoteError = null;
    },
    [mutations.SET_QUOTE_ERROR](state, payload) {
      state.hasError = payload;
    },
  },

  actions: {
    updatePaymentMethodByQuote({ getters, dispatch, rootGetters }, { paymentMethod }) {
      const { isSellCryptoFlow } = rootGetters;
      const allPaymentMethods = rootGetters['v2/paymentMethods/paymentMethods'];
      const allPayoutMethods = rootGetters['v2/paymentMethods/payoutMethods'];

      const {
        paymentMethods = [],
        payoutMethods = [],
      } = getters.rawQuote;

      const selectedPaymentMethod = paymentMethod || getters.selectedPaymentMethod || getters.selectedPayoutMethod;
      if (paymentMethods && paymentMethods.length === 1) {
        const { id: quotePaymentMethod, name: quotePaymentMethodName } = paymentMethods[0];

        dispatch('setWidgetQuote', { paymentMethod: quotePaymentMethod });
        dispatch('v2/paymentMethods/setPaymentMethod', {
          id: quotePaymentMethod,
          paymentMethod: quotePaymentMethod,
          name: quotePaymentMethodName,
        }, { root: true });
        dispatch('exchangeForm/setFromDisplayName', quotePaymentMethodName, { root: true });
      } else if (payoutMethods && payoutMethods.length === 1) {
        const { id: quotePayoutMethod, name: quotePayoutMethodName } = payoutMethods[0];

        dispatch('setWidgetQuote', { payoutMethod: quotePayoutMethod });
        dispatch('v2/paymentMethods/setPayoutMethod', {
          id: quotePayoutMethod,
          paymentMethod: quotePayoutMethod,
          name: quotePayoutMethodName,
        }, { root: true });
        dispatch('exchangeForm/setFromDisplayName', quotePayoutMethodName, { root: true });
      } else if (selectedPaymentMethod) {
        if (isSellCryptoFlow) {
          const currentPayoutMethods = allPayoutMethods.length ? allPayoutMethods : payoutMethods;
          const payoutMethodDetails = currentPayoutMethods.find(({ id }) => id === selectedPaymentMethod);
          dispatch('setWidgetQuote', { payoutMethod: selectedPaymentMethod });
          dispatch('v2/paymentMethods/setPayoutMethod', payoutMethodDetails || {
            id: selectedPaymentMethod,
            paymentMethod: selectedPaymentMethod,
          }, { root: true });

          if (payoutMethodDetails) {
            dispatch('exchangeForm/setFromDisplayName', payoutMethodDetails.name, { root: true });
          }
        } else {
          const currentPaymentMethods = allPaymentMethods.length ? allPaymentMethods : paymentMethods;
          const paymentMethodDetails = currentPaymentMethods.find(({ id }) => id === selectedPaymentMethod);
          dispatch('setWidgetQuote', { paymentMethod: selectedPaymentMethod });
          dispatch('v2/paymentMethods/setPaymentMethod', paymentMethodDetails || {
            id: selectedPaymentMethod,
            paymentMethod: selectedPaymentMethod,
          }, { root: true });

          if (paymentMethodDetails) {
            dispatch('exchangeForm/setFromDisplayName', paymentMethodDetails.name, { root: true });
          }
        }
      }
    },

    async getQuote({ commit, dispatch, getters }, { quoteId, paymentMethod }) {
      commit(mutations.SET_QUOTE_LOADING);

      let data = {};

      try {
        data = await widgetApiClient.getQuote({
          quoteId,
        });
      } catch ({ response }) {
        if (response?.status === 404) {
          commit(mutations.SET_QUOTE_ERROR, true);

          data = {
            id: '',
            currencyCodeTo: '',
            currencyCodeFrom: '',
            requestedAmount: null,
            requestedAmountType: '',
            productId: null,
            paymentMethods: [],
            payoutMethods: null,
            paymentMethodErrors: null,
            payoutMethodErrors: null,
            direction: '',
          };
        }
      }

      commit(mutations.SET_QUOTE, data);
      commit(mutations.SET_QUOTE_READY);

      const {
        requestedAmount,
        requestedAmountType,
        direction,
        currencyCodeFrom,
        currencyCodeTo,
      } = data;

      // XXX: set exchange form attributes
      const hasIncompatibleDirection = direction !== requestedAmountType;

      // XXX: if  direction and rerequestedAmountType not the same then we need to set data to inputs
      // of equivalent amount to prevent re-calculation after quote update
      if (hasIncompatibleDirection) {
        commit('exchangeForm/exchangeForm.setFromFiatAmount', requestedAmount.amount, { root: true });
      }

      const isReceivedAmount = requestedAmountType === 'to';

      commit('exchangeForm/exchangeForm.isReceivedAmount', isReceivedAmount, { root: true });
      commit('exchangeForm/exchangeForm.setFromCurrency', currencyCodeFrom, { root: true });
      commit('exchangeForm/exchangeForm.setToCurrencyCode', currencyCodeTo, { root: true });
      commit('exchangeForm/exchangeForm.setDirection', direction, { root: true });

      return data;
    },

    async createQuote({ commit, rootGetters }, {
      currencyCodeFrom,
      currencyCodeTo,
      amount,
      isReceivedAmount = false,
      directionChange = 'from',
      paymentMethod = null,
      promoCode,
    }) {
      const requestBody = {
        currencyCodeFrom,
        currencyCodeTo,
        amount,
        directionChange,
        isReceivedAmount,
        promoCode,
      };
      if (paymentMethod) {
        requestBody.paymentMethod = paymentMethod;
      }

      commit(mutations.SET_QUOTE_LOADING);

      try {
        const quote = await widgetApiClient.createQuote({
          requestId: rootGetters['request/requestId'], // XXX: should we pass it as an argument
          requestBody,
          locale: rootGetters.locale,
        });

        commit(mutations.SET_QUOTE, quote);

        return quote;
      } catch (error) {
        if (error.response?.data) {
          commit(mutations.SET_QUOTE, error.response.data);
        }

        throw error;
      } finally {
        commit(mutations.SET_QUOTE_READY);
      }
    },

    setWidgetQuote({ commit, getters, state, rootGetters }, { paymentMethod, payoutMethod }) {
      const payMethodQuote = getters.quote(paymentMethod || payoutMethod);

      if (!payMethodQuote.amountFrom) {
        return;
      }

      const {
        fees: { networkFee, serviceFee, totalFee, feeDiscount },
        expiration: expiresAt,
        amountTo,
        amountFrom,
        amountFromAmount,
        amountToAmount,
        amountReceived,
      } = payMethodQuote;

      commit('widgetQuote/setQuote', {
        quoteId: payMethodQuote.id,
        currencyCodeFrom: state.quote.currencyCodeFrom,
        currencyCodeTo: state.quote.currencyCodeTo,
        amountTo: {
          amount: amountToAmount,
          currency: amountTo.currencyCode,
        },
        amountFrom: {
          amount: amountFromAmount,
          currency: amountFrom.currencyCode,
        },
        amountReceived,
        fees: {
          currency: networkFee.currencyCode,
          network_fee: networkFee.amount,
          service_fee: serviceFee.amount,
          total_fee: totalFee.amount,
          fee_discount: feeDiscount?.amount,
        },
        expiresAt,
      }, { root: true });
      commit('widgetQuote/boostReactivity', null, { root: true });
    },

    async fetchQuote({ commit, dispatch, getters, rootGetters }, requestBody) {
      const { requestId } = getters;
      const { isSellCryptoFlow, locale } = rootGetters;

      commit(mutations.RESET_ERRORS);
      commit(mutations.SET_QUOTE_LOADING);

      try {
        const quote = await widgetApiClient.createQuote({
          requestId,
          requestBody,
          locale,
        });

        commit(mutations.SET_QUOTE, quote);

        const selectedPaymentOrPayoutMethod = isSellCryptoFlow
          ? getters.selectedPayoutMethod
          : getters.selectedPaymentMethod;

        const {
          amountFrom = {},
          amountTo = {},
          currencyCodeFrom,
          currencyCodeTo,
        } = getters.quote(selectedPaymentOrPayoutMethod);

        const { selectedPaymentMethodSlug, selectedPayoutMethodSlug } = getters;

        if (!isSellCryptoFlow) {
          dispatch('v2/paymentMethods/mapPaymentMethodsWithQuote', { quotePaymentMethods: getters.paymentMethods }, { root: true });

          dispatch('setWidgetQuote', { paymentMethod: selectedPaymentMethodSlug });
        } else {
          dispatch('v2/paymentMethods/mapPayoutMethodsWithQuote', { quotePayoutMethods: getters.payoutMethods }, { root: true });

          dispatch('setWidgetQuote', { payoutMethod: selectedPayoutMethodSlug });
        }

        let amountToAmount = amountTo.amount;
        if (Math.sign(amountToAmount) === -1) {
          amountToAmount = '0';
        }
        let amountFromAmount = amountFrom.amount;
        if (Math.sign(amountFromAmount) === -1) {
          amountFromAmount = '0';
        }

        return {
          amountFromAmount,
          amountToAmount,
          currencyCodeFrom,
          currencyCodeTo,
        };
      } catch (error) {
        if (error.response?.data) {
          commit(mutations.SET_QUOTE, error.response.data);
        }

        if (error.response?.status === 500) {
          dispatch('deleteQuote');
          commit(mutations.SET_UNEXPECTED_QUOTE_ERROR, 'widget.exchange-form.error.calculating-a-quote');

          return false;
        }

        throw error;
      } finally {
        commit(mutations.SET_QUOTE_READY);
      }
    },

    async refreshQuoteWithPaymentMethod({ dispatch, rootGetters }) {
      const { isSellCryptoFlow } = rootGetters;

      const paymentMethod = isSellCryptoFlow
        ? rootGetters['v2/paymentMethods/fromPaymentMethod']
        : rootGetters['v2/paymentMethods/toPaymentMethod'];

      await dispatch('refreshQuote', paymentMethod);
    },

    async refreshQuote({ dispatch, rootGetters }, paymentMethod = {}) {
      const { amountFrom, amountTo, amountReceived } = rootGetters['widgetQuote/quote'];

      const isFromFiatAmount = !!rootGetters['exchangeForm/fromFiatAmount'];
      let amount;
      if (isFromFiatAmount) {
        amount = amountReceived;
      } else {
        amount = rootGetters['exchangeForm/direction'] === 'from' ? amountFrom : amountTo;
      }

      await dispatch('fetchQuote', {
        amount,
        currencyCodeFrom: rootGetters['exchangeForm/fromCurrency'],
        currencyCodeTo: rootGetters['exchangeForm/toCurrencyCode'],
        directionChange: rootGetters['exchangeForm/direction'],
        isReceivedAmount: rootGetters['exchangeForm/isReceivedAmount'],
        promoCode: rootGetters['promoCode/promoCode'],
        ...paymentMethod,
      });
    },
    deleteQuote({ commit }) {
      commit(mutations.SET_QUOTE, {});
    },
  },
};
