import axios from 'axios';
import cookieStore from '@paybis/frontend-authentication-app/src/services/cookie';
import { captureException } from '../plugins/sentry';
import { parseJwt } from './jwt';
import eventBus from '../helpers/eventBus';
import EventNames from '../models/eventNames';
import UserLocaleStorage from '@paybis/frontend-authentication-app/src/services/user-locale-storage';

const KEYS = {
  JWT: '__jwt',
  JWT_EXPIRATION: '__jwt-exp',
  REFRESH_TOKEN: '__refresh_token',
  REFRESH_TOKEN_EXPIRATION: '__refresh_token-exp',
};

let refreshPromise = null;

class TokenService {
  constructor() {
    if (this.getToken() === 'undefined') {
      this.removeTokens();
    }
  }

  getToken() {
    return cookieStore.get(KEYS.JWT);
  }

  hasToken() {
    return cookieStore.get(KEYS.JWT) !== undefined;
  }

  getRefreshToken() {
    return cookieStore.get(KEYS.REFRESH_TOKEN);
  }

  hasRefreshToken() {
    return cookieStore.get(KEYS.REFRESH_TOKEN) !== undefined;
  }

  getUserId() {
    const token = this.getToken();
    const { sub } = parseJwt(token);

    return sub;
  }

  getUser() {
    const hasToken = this.hasToken();
    if (hasToken) {
      const token = this.getToken();
      const { sub, email } = parseJwt(token);

      return {
        id: sub,
        email,
      };
    }

    return null;
  }

  setTokens({ jwt, jwtExpiresAt, refreshToken, refreshTokenExpiresAt }) {
    cookieStore.set(KEYS.JWT, jwt, {
      expires: new Date(refreshTokenExpiresAt),
    });
    cookieStore.set(KEYS.REFRESH_TOKEN, refreshToken, {
      expires: new Date(refreshTokenExpiresAt),
    });
    cookieStore.set(KEYS.JWT_EXPIRATION, jwtExpiresAt, {
      expires: new Date(refreshTokenExpiresAt),
    });
    cookieStore.set(KEYS.REFRESH_TOKEN_EXPIRATION, refreshTokenExpiresAt, {
      expires: new Date(refreshTokenExpiresAt),
    });
  }

  removeTokens() {
    cookieStore.remove(KEYS.JWT);
    cookieStore.remove(KEYS.JWT_EXPIRATION);
    cookieStore.remove(KEYS.REFRESH_TOKEN);
    cookieStore.remove(KEYS.REFRESH_TOKEN_EXPIRATION);
  }

  requestNewToken() {
    if (refreshPromise !== null) {
      return refreshPromise;
    }
    if (!this.hasRefreshToken()) {
      eventBus.emit(EventNames.SESSION_TIMEOUT);
      return Promise.reject(new Error('Could not find refreshToken'));
    }

    const apiUrl = process.env.VUE_APP_AUTH_SERVICE_PUBLIC_URL;

    const instance = axios.create({
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;

          return data;
        },
        ...axios.defaults.transformRequest,
      ],
    });

    instance.interceptors.request.use(
      config => {
        const deviceId = window.nSureSDK && window.nSureSDK.getDeviceId();

        if (!deviceId) {
          captureException({
            error: new Error('No deviceID found on token refresh'),
          });
        }

        config.headers['X-Device-ID'] = deviceId || '';
        if (window && window.nSurePartnerId) {
          config.headers['X-Partner-ID'] = window.nSurePartnerId;
        }

        return config;
      },
      error => Promise.reject(error),
    );

    refreshPromise = instance.post(
      `${apiUrl}/public/authentication-service/v1/jwt/refresh`,
      {
        refreshToken: this.getRefreshToken(),
      },
      {
        headers: {
          'X-User-Language': UserLocaleStorage.getLocale() || 'en',
        },
      },
    );

    return refreshPromise
      .then(({ data }) => {
        this.setTokens(data);

        return data;
      })
      .catch(error => {
        const extra = {
          token: this.getToken(),
          refreshToken: this.getRefreshToken(),
          errorExtraMessage: 'Error in refresh method for token',
        };

        captureException({ error, extra });
        this.removeTokens();
        eventBus.emit(EventNames.SESSION_TIMEOUT);
      })
      .finally(() => {
        refreshPromise = null;
      });
  }
}

export default new TokenService();
