import { h, defineAsyncComponent } from 'vue';

import { isFrictionlessEnabled, isPaymentInitiated } from '@/services/frictionlessFlow';

import PayoutDetails from './components/PayoutDetails';
import Completed from './components/Completed';
import Rejected from './components/Rejected';
import Cancelled from './components/Cancelled';
import ChallengeWaiting from './components/ChallengeWaiting';
import PaymentStatusWaiting from './components/PaymentStatusWaiting';
import PaymentError from './components/PaymentError';
import ExtraVerification from './components/ExtraVerification';
import ApmPaymentError from './components/ApmPaymentError';
import InvoiceDetails from './components/InvoiceDetails';
import FrictionlessPaymentWaiting from './components/FrictionlessPaymentWaiting';

const NoopComponent = () => h('div');

export default {
  TransactionFlow: DefaultComponent => ({
    ...DefaultComponent,

    name: 'CustomTransactionFlow',

    components: {
      ...DefaultComponent.components,

      HeaderSteps: NoopComponent,
      CurrentTransaction: NoopComponent,
      FooterText: NoopComponent,
      PaymentFallbackPopup: NoopComponent,
      CancellationPopup: NoopComponent,
    },
  }),

  PayoutDetails: () => PayoutDetails,
  Completed: () => Completed,
  Rejected: () => Rejected,
  Cancelled: () => Cancelled,
  ExtraVerification: () => ExtraVerification,
  PaymentDetails: DefaultComponent => {
    const CardPaymentDetailsComponent = DefaultComponent.components.CardPaymentDetails;
    const CryptoPaymentDetailsComponent = DefaultComponent.components.CryptoPaymentDetails;
    const ApmPaymentWaitingComponent = DefaultComponent.components.ApmPaymentWaiting;

    Object.assign(CardPaymentDetailsComponent.components, {
      ChallengeWaiting,
      PaymentStatusWaiting,
    });

    Object.assign(ApmPaymentWaitingComponent.components, {
      ApmPaymentError,
    });

    return {
      ...DefaultComponent,

      name: 'CustomPaymentDetails',

      components: {
        ...DefaultComponent.components,

        CardPaymentDetails: CardPaymentDetailsComponent,
        CryptoPaymentDetails: defineAsyncComponent(() => new Promise(resolve => {
          if (isFrictionlessEnabled.value) {
            resolve(InvoiceDetails);
            return;
          }

          resolve(CryptoPaymentDetailsComponent);
        })),
        ApmPaymentWaiting: ApmPaymentWaitingComponent,
      },
    };
  },
  PaymentError: () => PaymentError,
  PaymentWaiting: DefaultComponent => defineAsyncComponent(() => new Promise(resolve => {
    if (isFrictionlessEnabled.value && isPaymentInitiated.value) {
      resolve(FrictionlessPaymentWaiting);
      return;
    }

    resolve(DefaultComponent);
  })),
};
