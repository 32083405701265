<template>
  <div class="payout-details">
    <div v-if="!payout.isBankCard() && !isScannerVisible">
      <header
        class="wallet-header"
      >
        <i
          class="wallet-header__icon svg-icon"
          :class="`icon-${icon}`"
        />
        {{ payoutTitle }}
      </header>

      <p class="wallet-notice">
        {{ payoutSubtitle }}
      </p>

      <div class="wallet-input">
        <div class="wallet-input__label">
          {{ walletInputLabel }}
        </div>
        <CustomFields
          class="wallet-input__with-qr-button"
          :fields="payoutDetailsFields"
          @setField="setField"
          @onClick="requestLedgerAccount"
          @onBlur="unlockRequestLedgerAccount"
        />
        <template v-if="payoutDetailsWithQRScannerFeature.isEnabled() && !isSellCryptoFlow">
          <div
            class="qr-scanner-button-section"
          >
            <span class="qr-scanner-button-section__divider">
              {{ $t('widget.qr-scanner.divider-text') }}
            </span>
            <br>
            <button
              class="btn btn-secondary btn-sm"
              @click="showScanner"
            >
              <Icon icon="qrcode" />
              <span class="qr-scanner-button-section__btn-text">
                {{ $t('widget.qr-scanner.scan-btn') }}
              </span>
            </button>
          </div>
          <button
            class="btn btn-secondary btn-sm qr-scanner-mobile-button"
            @click="showScanner"
          >
            <Icon icon="qrcode" />
          </button>
        </template>
        <ZeroHashComplianceCheckbox
          v-if="showComplianceCheckbox"
          :has-error="showComplianceError"
          @change="handleZhComplianceCheckbox"
        />
      </div>
      <div class="guard">
        <i class="guard__icon svg-icon icon-shield" />

        <div class="guard__text">
          {{ $t("widget.wallet.guard.title") }}

          <div>{{ $t("widget.wallet.guard.description") }}</div>
        </div>
      </div>

      <div class="wallet-footer">
        <button
          :disabled="isLocked"
          class="btn btn-widget btn-primary"
          :class="{ 'is-loading': isLocked }"
          @click="submit()"
        >
          {{ $t("widget.wallet.continue") }}
        </button>
      </div>
    </div>
    <WalletAddressQRScanner
      v-if="!payout.isBankCard() && isScannerVisible"
      @setWalletAddress="handleSetWalletAddress"
      @closeScanner="closeScanner"
    />
    <CardPayoutDetails
      v-if="payout.isBankCard()"
      :transaction="transaction"
      :invoice="invoice"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CustomFields from '@paybis/frontend-common-lib/src/components/custom-fields';
import CardPayoutDetails from '@paybis/frontend-transaction-funnnel-app/src/components/card-payout-details';
import ZeroHashComplianceCheckbox from '@/overrides/TransactionFunnel/components/ZeroHashComplianceCheckbox';
import { requestAccount } from '@/services/ledger';
import { getWalletAddress } from '@/services/widgetSettings';
import Icon from '@paybis/frontend-common-lib/src/components/icon';
import WalletAddressQRScanner
  from '@/overrides/TransactionFunnel/components/WalletAddressQRScanner/WalletAddressQRScanner';
import { useI18n } from 'vue-i18n';

const ACCOUNT = 'account';

export default defineComponent({
  name: 'PayoutDetails',

  components: {
    WalletAddressQRScanner,
    Icon,
    CardPayoutDetails,
    ZeroHashComplianceCheckbox,
    CustomFields,
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      requestAccountInProgress: false,
      isAgreedWithZh: false,
      showComplianceError: false,
      scannerVisible: false,
    };
  },

  computed: {
    ...mapGetters(['isLocked']),
    ...mapGetters(['isSellCryptoFlow']),
    ...mapGetters('external', ['payoutDetailsFields', 'transactionPayoutAccount', 'payoutCardMaskedByAccount']),
    ...mapGetters('card', ['cards']),
    ...mapGetters('feature', ['payoutDetailsWithQRScannerFeature']),
    ...mapGetters('widgetQuote', ['currencyCodeTo']),
    ...mapGetters('transaction', ['flowDetails', 'payout', 'payment', 'transaction', 'invoice']),
    showComplianceCheckbox() {
      return this.flowDetails.isPayoutTermsAcceptanceRequired();
    },
    walletInputLabel() {
      return this.t('widget.wallet.input-label', {
        crypto: this.currencyCodeTo,
      });
    },
    isScannerVisible() {
      return this.scannerVisible;
    },
    icon() {
      return this.currencyCodeTo.toLowerCase();
    },
    payoutTitle() {
      return this.payment.isCrypto()
        ? this.t('transaction-flow.steps.wallet.title.non-crypto')
        : this.t('widget.wallet.title');
    },
    payoutSubtitle() {
      if (this.payment.isCrypto()) {
        if (this.payout.isNeteller()) return this.t('transaction-flow.steps.wallet.neteller-subtitle');
        if (this.payout.isSkrill()) return this.t('transaction-flow.steps.wallet.skrill-subtitle');
        return this.t('transaction-flow.steps.wallet.subtitle');
      }
      return this.t('widget.wallet.address-notice');
    },
  },

  beforeUnmount() {
    this.resetVerification();
  },

  mounted() {
    try {
      const field = this.payoutDetailsFields.find(({ id }) => id === ACCOUNT);
      field.setValue(getWalletAddress());

      this.updatePayoutDetailsField(field);

      return;
      // eslint-disable-next-line no-empty
    } catch { }

    this.requestLedgerAccount();
  },

  methods: {
    ...mapActions('external', ['submitTransactionPayoutDetails', 'setPayoutDetailsFieldErrors', 'updatePayoutDetailsField', 'resetVerification']),
    ...mapMutations(['lock', 'clearLocks', 'unlock']),

    showScanner() {
      this.scannerVisible = true;
    },

    closeScanner() {
      this.scannerVisible = false;
    },

    handleSetWalletAddress(walletAddress) {
      const field = this.payoutDetailsFields.find(({ id }) => id === ACCOUNT);
      field.setValue(walletAddress);
      field.setErrorMessage(null);
      this.updatePayoutDetailsField(field);
      this.closeScanner();
    },

    handleZhComplianceCheckbox(isAgreedWithZh) {
      this.isAgreedWithZh = isAgreedWithZh;
      this.showComplianceError = false;
    },

    unlockRequestLedgerAccount() {
      this.requestAccountInProgress = false;
    },

    submit() {
      this.lock();
      if (
        !this.isAgreedWithZh
        && this.showComplianceCheckbox
      ) {
        this.showComplianceError = true;
        this.unlock();

        return;
      }

      this.submitTransactionPayoutDetails({
        isAgreedWithZhCompliance: this.isAgreedWithZh,
      })
        .catch(({ data }) => {
          if (data && 'errors' in data) {
            this.setPayoutDetailsFieldErrors(data.errors);
          }

          return null;
        })
        .finally(() => {
          this.clearLocks();
        });
    },

    setField(field) {
      const fieldValue = field.getValue().trim();

      field.setValue(fieldValue);
      field.setErrorMessage(null);

      this.updatePayoutDetailsField(field);
    },

    requestLedgerAccount() {
      if ((this.payment.isCrypto() && !this.payout.isCrypto()) || this.requestAccountInProgress) {
        return;
      }

      const currencies = [];
      const blockchainName = this.$store.getters['exchangeForm/getBlockchainNameByCurrencyCode'](this.currencyCodeTo);
      if (blockchainName !== null) {
        currencies.push(blockchainName);
      }
      requestAccount({ currencies })
        .then(({ address: account }) => {
          const accountField = this.payoutDetailsFields.find(({ id }) => id === ACCOUNT);
          accountField.setValue(account);

          this.updatePayoutDetailsField(accountField);
        }).catch(() => {
          this.requestAccountInProgress = true;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.wallet-header {
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;

  &__icon {
    display: block;
    width: 80px;
    height: 80px;
    margin: 40px auto 16px;
    background-size: cover;

    @media (max-width: $tablet-max) {
      width: 64px;
      height: 64px;
      margin-top: 20px;
    }
  }
}
.qr-scanner-button-section {
  text-align: center;
  margin-top: rem(16);

  &__btn-text {
    margin-left: 5px;
  }

  & .btn {
    margin-top: rem(8);
  }
  @media (max-width: $mobile-min) {
   display: none;
  }
}
.qr-scanner-mobile-button {
  display: none;
  @media (max-width: $mobile-min) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 25px;
    max-height: 25px;
    position: absolute;
    top: 16px;
    right: 10px;
    background-color: transparent;
    border: none;
    transition: color 0.2s linear;
    &:hover {
      color: $violet;
    }
  }
}

.wallet-notice {
  font-size: 1rem;
  margin-bottom: 16px;
  margin-top: 8px;
  text-align: left;
  line-height: 1.375rem;
}

.wallet-input {
  position: relative;

  &__label {
    margin-bottom: 5px;
  }

  // TODO hack to allow CustomFields component have icon inside
  &__icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: 14px;
    // One text line + half of icon height + half of input height
    top: calc(1em + 20px);
    pointer-events: none;
  }
}
@media (max-width: $mobile-min) {
  :deep(.form-input__input) {
    padding-right: 35px;
  }
}

.wallet-footer {
  text-align: center;
  margin-top: 86px;
  @media (max-width: $tablet-max) {
    margin-top: 50px;
  }
}

.guard {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
  padding: 1rem 0.875rem;
  border-radius: 6px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  background: rgba(0, 0, 0, 0.04);

  &__icon {
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    background-size: cover;
    margin-right: 0.75rem;
    position: relative;
    top: 1px;
  }

  &__text {
    div {
      margin-top: 2px;
      color: $grey;
    }
  }
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .wallet-header {
    font-size: 3.6vh;
    line-height: 4.16vh;
    margin-bottom: 4vh;

    &__icon {
      width: calcVh(80, $widget-h-sm);
      height: calcVh(80, $widget-h-sm);
      margin: calcVh(40, $widget-h-sm) auto calcVh(16, $widget-h-sm);
    }
  }

  .wallet-notice {
    font-size: calcVh(16, $widget-h-sm);
    margin-bottom: calcVh(22, $widget-h-sm);
    margin-top: calcVh(8, $widget-h-sm);
    line-height: calcVh(20, $widget-h-sm);
  }

  .wallet-input {
    ::v-deep(.form-input__input) {
      font-size: calcVh(16, $widget-h-sm);
      line-height: calcVh(20, $widget-h-sm);
      padding: calcVh(14, $widget-h-sm);
    }
  }

  .wallet-footer {
    margin-top: calcVh(86, $widget-h-sm);
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .wallet-header {
    font-size: 3.7vh;
    line-height: 4.18vh;
    margin-bottom: 7.31vh;

    &__icon {
      width: calcVh(80, $widget-h-xlg);
      height: calcVh(80, $widget-h-xlg);
      margin: calcVh(40, $widget-h-xlg) auto calcVh(16, $widget-h-xlg);
    }
  }

  .wallet-notice {
    font-size: calcVh(16, $widget-h-xlg);
    margin-bottom: calcVh(22, $widget-h-xlg);
    margin-top: calcVh(8, $widget-h-xlg);
    line-height: calcVh(20, $widget-h-xlg);
  }

  .wallet-input {
    ::v-deep(.form-input__input) {
      font-size: calcVh(16, $widget-h-xlg);
      line-height: calcVh(20, $widget-h-xlg);
      padding: calcVh(14, $widget-h-xlg);
    }
  }

  .wallet-footer {
    margin-top: calcVh(86, $widget-h-xlg);
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .wallet-header {
    font-size: 3.71vh;
    line-height: 4.24vh;
    margin-bottom: 7.42vh;

    &__icon {
      width: calcVh(80, $widget-h-xxlg);
      height: calcVh(80, $widget-h-xxlg);
      margin: calcVh(40, $widget-h-xxlg) auto calcVh(16, $widget-h-xxlg);
    }
  }

  .wallet-notice {
    font-size: calcVh(16, $widget-h-xxlg);
    margin-bottom: calcVh(22, $widget-h-xxlg);
    margin-top: calcVh(8, $widget-h-xxlg);
    line-height: calcVh(20, $widget-h-xxlg);
  }

  .wallet-input {
    ::v-deep(.form-input__input) {
      font-size: calcVh(16, $widget-h-xxlg);
      line-height: calcVh(20, $widget-h-xxlg);
      padding: calcVh(14, $widget-h-xxlg);
    }
  }

  .wallet-footer {
    margin-top: calcVh(86, $widget-h-xxlg);
  }
}
</style>
