<template>
  <div
    class="form-checkbox__wrapper"
    :class="{
      'has-error': error,
      'is-disabled': isDisabled,
      'is-compact': hasLabelSlot && hasDescriptionSlot
    }"
  >
    <label class="form-checkbox__fauxbox">
      <input
        :id="id"
        v-model="inputVal"
        :name="name"
        :disabled="isDisabled"
        type="checkbox"
      >

      <span onclick="return false;" />
    </label>

    <div
      v-if="hasLabelSlot"
      class="form-checkbox__label"
    >
      <slot name="label" />
      <div
        v-if="hasDescriptionSlot"
        class="form-checkbox__description"
      >
        <slot name="description" />
      </div>
      <span
        v-if="error"
        class="form-checkbox__error"
      >{{ error }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCheckbox',

  props: {
    id: { type: String, required: false, default: '' },
    name: { type: String, required: false, default: '' },
    error: { type: String, required: false, default: '' },
    value: { type: Boolean, required: false, default: false },
    isDisabled: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      inputVal: this.value,
    };
  },

  computed: {
    hasLabelSlot() {
      return !!this.$slots.label;
    },
    hasDescriptionSlot() {
      return !!this.$slots.description;
    },
  },

  watch: {
    inputVal(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<style scoped lang="scss">
.form-checkbox {
  &__wrapper {
    position: relative;
    display: inline-flex;
  }

  &__fauxbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: rem(16);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: rem(16);
    min-width: rem(16);
    height: rem(18);
  }

  &__fauxbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__fauxbox span {
    position: absolute;
    top: rem(2);
    left: 0;
    height: rem(16);
    width: rem(16);
    border: 1px solid #dbdbdb;
    background-color: $grey-04;
    border-radius: rem(2);
    transition: border-color 0.2s linear;
  }

  [dir="rtl"] &__fauxbox span {
    right: 0;
    left: unset;
  }

  &__fauxbox input:checked ~ span:after {
    content: "\e912";
    @include inline-icon;
    position: absolute;
    top: -1px;
    left: -1px;
  }

  [dir="rtl"] &__fauxbox input:checked ~ span:after {
    right: -1px;
    left: unset;
  }

  &__fauxbox:hover span {
    border-color: $black-20;
  }

  &__wrapper.has-error &__fauxbox span {
    border-color: $red;
  }

  &__wrapper.has-error &__fauxbox:hover span {
    border-color: rgba(231, 60, 64, 0.25);
  }

  &__label {
    margin-left: rem(12);
    font-size: rem(16);
    line-height: 1.25;
  }

  &__description {
    color: #b2b2b2;
    margin-top: rem(2);
  }

  [dir="rtl"] &__label {
    margin-right: rem(12);
    margin-left: unset;
  }

  &__label a {
    border-bottom: 1px solid $black-15;
    transition: border-color 0.2s linear;
  }

  &__label a:hover {
    border-color: $black-60;
  }

  &__wrapper.is-disabled &__label {
    color: $grey;
  }

  &__wrapper.is-disabled &__fauxbox {
    pointer-events: none;
  }

  &__wrapper.is-disabled &__fauxbox span {
    background-color: $grey-04;
    border-color: $grey-04;
  }

  &__wrapper.is-compact &__label {
    font-size: rem(14);
  }

  &__error {
    display: block;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }
}
</style>
