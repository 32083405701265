<template>
  <span>
    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/verification-block.svg')
      "
    />

    <Subtitle>
      {{
        $t('transaction-flow.steps.payment-error.subtitle')
      }}
    </Subtitle>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <Paragraph v-html="$t('transaction-flow.steps.payment-error.message')" />
  </span>
  <div class="local-footer">
    <div class="buttons-group">
      <BackToMerchantButton
        v-if="failureReturnURL"
        :url="failureReturnURL"
      />
    </div>
  </div>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';
import Subtitle from '@paybis/frontend-common-lib/src/components/subtitle';
import BackToMerchantButton from '@/components/BackToMerchantButton/BackToMerchantButton.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BackToMerchantButton,
    Subtitle,
    Paragraph,
    Pictogram,
  },
  computed: {
    ...mapGetters(['failureReturnURL']),
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/blocks/buttons-group";
.local-footer{
  text-align: center;
}
</style>
