// TODO: refactor. DIP violation. It forces to use some folder structure in the project where it is used.
import store from '@/store/index';

import CardPaymentClient from '../../services/clients/card-payment-client';
import AbstractPaymentProcessor from '../AbstractPaymentProcessor';

export default class BridgerpayPaymentProcessor extends AbstractPaymentProcessor {
  constructor(cardId, invoice, cvv) {
    super('checkout', cardId, invoice);

    this.cvv = cvv;
  }

  getCvv() {
    return this.cvv;
  }

  getReturnUrl() {
    if (store.getters['settings/isWidget']) {
      const parsedUrl = new URL(window.location.href);

      const searchParams = new URLSearchParams();
      // TODO: DIP violation. Should be fixed
      searchParams.set('requestId', store.getters.requestId);

      return `${parsedUrl.origin}/?${searchParams.toString()}`;
    }

    return window.location.href;
  }

  pay() {
    return CardPaymentClient.getBridgerpayDdc({
      cardId: this.getCardId(),
      invoice: this.getInvoice(),
    }).then(({ data }) => ({
      isBridgerPayIframe: true,
      data,
    }));
  }
}
