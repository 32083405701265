<template>
  <div class="error-page">
    <div class="container">
      <div class="error-page-block">
        <i class="error-page-icon svg-icon icon-critical-error-screen" />
        <div class="error-page-header">
          {{ $t(content.title) }}
        </div>
        <div class="error-page-message" v-html="$t(content.description)">
        </div>
        <div class="error-page-correlation">
          {{ $t(content.correlationMessage) }} {{ correlationId }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import mappedContent, { COOKIE_DISABLED } from './contentMapper';
import { stopQuoteAutorefresh } from '@/store/widgetQuote';
import { emitToParent } from '@/services/widgetApiEvents';
import { getCorrelationId } from '../../../packages/frontend-common-lib/src/services/correlation';

export default {
  name: 'CriticalErrorScreen',

  setup() {
    const route = useRoute();
    const { errorType } = route.query;
    stopQuoteAutorefresh();

    if (errorType === COOKIE_DISABLED) {
      emitToParent('error', {
        message: 'Blocked third-party cookies',
      });
    }

    return {
      correlationId: getCorrelationId(),
      content: mappedContent(errorType),
    };
  },
};
</script>

<style lang="scss" scoped>
  .error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    &-icon {
      height: 76px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-header {
      font-size: 22px;
      line-height: 28px;
      padding-bottom: 1rem;
      text-align: center;
      font-weight: 700;

      @media screen and (min-width: 1000px) {
        font-size: 1.75rem;
        line-height: 1;
        padding: 3.875rem 1.5rem 2rem;
      }
    }

    &-message {
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
    }

    &-correlation {
      text-align: center;
      font-weight: 700;
    }
  }
</style>
