import IntroJs from 'intro.js';
import 'intro.js/introjs.css';

import cookieStore from '@paybis/frontend-authentication-app/src/services/cookie';

const cookieExpiration = new Date().setFullYear(new Date().getFullYear() + 10); // expires in 10 years
const intro = IntroJs();
intro.setOptions({
  exitOnEsc: false,
  exitOnOverlayClick: false,
  disableInteraction: true,
  showBullets: false,
  showProgress: false,
  showStepNumbers: false,
  overlayOpacity: 0.6,
  nextLabel: 'OK',
  doneLabel: 'OK',
});

const ResizeObserverSuper = window.ResizeObserver || ResizeObserverPolyfill;
const resizeObserver = new ResizeObserverSuper(() => {
  window.dispatchEvent(new Event('resize'));
});
resizeObserver.observe(document.documentElement);

export const IntroService = {
  getCookieKeyForHints(sessionName) {
    return `userSeenHints_${sessionName}`;
  },
  getHintsUserData(sessionName) {
    return JSON.parse(cookieStore.get(this.getCookieKeyForHints(sessionName)));
  },
  isNewUserForHints(sessionName) {
    return !cookieStore.get(this.getCookieKeyForHints(sessionName));
  },
  onHintChange(sessionName) {
    intro.onchange(element => {
      const hintsUserData = {
        currentStep: element.getAttribute('data-step'),
        hintsSeen: false,
      };
      cookieStore.set(this.getCookieKeyForHints(sessionName), JSON.stringify(hintsUserData), {
        expires: new Date(cookieExpiration),
      });
    });
  },
  onCompleteIntro(sessionName) {
    intro.oncomplete(() => {
      const currentIntroUserData = this.getHintsUserData(sessionName);
      if (intro._introItems.length - 1 === intro._currentStep) {
        currentIntroUserData.hintsSeen = true;
      }
      cookieStore.set(this.getCookieKeyForHints(sessionName), JSON.stringify(currentIntroUserData), {
        expires: new Date(cookieExpiration),
      });
    });
  },
  exitIntroSession() {
    intro.exit([true]);
  },
  initHintsIntro(sessionName) {
    this.onHintChange(sessionName);
    this.onCompleteIntro(sessionName);
    intro.start();
  },
  startIntro(sessionName) {
    if (this.isNewUserForHints(sessionName)) {
      this.initHintsIntro(sessionName);
      return;
    }
    if (!this.getHintsUserData(sessionName).hintsSeen) {
      const currentHintsUserData = this.getHintsUserData(sessionName);
      this.initHintsIntro(sessionName);
      intro.goToStep(Number(currentHintsUserData.currentStep));
    }
  },
};
