import { ref, computed } from 'vue';

const frictionlessMode = ref('manual');

export const isPaymentInitiated = ref(false);

export const isFrictionlessEnabled = computed(() => {
  return frictionlessMode.value && frictionlessMode.value !== 'manual';
});

export const setFrictionlessMode = value => {
  frictionlessMode.value = value;
};

export const setIsPaymentInitiated = status => {
  if (status !== 'payment_initiated') return;
  isPaymentInitiated.value = true;
};
