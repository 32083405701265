<template>
  <div class="widget-menu menu">
    <div
      ref="headerBlock"
      class="widget-menu__header"
    >
      <h5
        :style="{
          fontSize: `${fontSizeHeader}px`,
        }"
      >
        {{ menuHeader || $t('widget.menu.title') }}
      </h5>
      <div
        v-if="menuSubheader"
        class="widget-menu__header-email"
        :style="{
          fontSize: `${fontSizeSubheader}px`,
        }"
      >
        {{ menuSubheader }}
      </div>
    </div>

    <MenuItem
      v-for="item in menuItemsList"
      :key="item.alias"
      :alias="item.alias"
      :title="$t(item.translationCode)"
      @click="openMenuItem"
    />

    <button
      v-if="canLogout"
      class="widget-menu__logout"
      @click="toggleLogoutConfirm"
    >
      {{ $t('widget.menu.items.logout') }}
    </button>

    <Modal
      :show.sync="isLogoutConfirmVisible"
      :component="$options.modals.LogoutModal"
      @confirm="logoutWidget"
      @reject="toggleLogoutConfirm"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

import inIframe from '@paybis/frontend-transaction-funnnel-app/src/utils/iframe';
import { useRouter } from 'vue-router';
import { Modal, LogoutModal } from '@/components/Modal';
import { MENU_ITEMS_DICTIONARY } from '@/constants/menu';

import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';
import { emitToParent } from '@/services/widgetApiEvents';

import MenuItem from './MenuItem';
import { HOME } from '@/router/routes';

const MENU_ITEMS_LIST = Object.values(MENU_ITEMS_DICTIONARY)
  .sort((left, rigth) => left.order - rigth.order);

export default defineComponent({
  name: 'WidgetMenuLayout',

  modals: {
    LogoutModal,
  },

  components: {
    Modal,
    MenuItem,
  },

  emits: ['open-item'],

  setup() {
    const store = useStore();
    const router = useRouter();
    const isLogoutConfirmVisible = ref(false);
    const headerBlock = ref(null);
    const fontSizeHeader = ref(0);
    const fontSizeSubheader = ref(0);
    const openMenuItem = item => store.dispatch('selectMenuItem', {
      item,
    });

    const canLogout = computed(() => store.getters.jwtTokenExists());
    const client = computed(() => store.getters.client);
    const menuHeader = computed(() => client.value.fullName || client.value.email);
    const menuSubheader = computed(() => (client.value.fullName ? client.value.email : ''));

    const menuItemsList = computed(() => {
      const isTransactionHistoryAvailable = store.getters['transactionHistory/isAvailable'];

      if (!isNewExchangeFormFlowEnabled() && !isTransactionHistoryAvailable) {
        return MENU_ITEMS_LIST.filter(item => item.alias !== 'transaction-history');
      }

      return MENU_ITEMS_LIST;
    });
    const toggleLogoutConfirm = () => {
      isLogoutConfirmVisible.value = !isLogoutConfirmVisible.value;
    };

    const logoutWidget = async () => {
      toggleLogoutConfirm();
      await store.dispatch('logout');

      if (inIframe()) {
        // XXX: send event only when in iframe
        emitToParent('state', {
          state: 'closed',
          action: 'logout',
        });

        return;
      }

      // XXX: navigate to index page
      await router.push({ name: HOME });
      // XXX: close opened menu
      await store.dispatch('toggleMenu');
    };

    const calculateFontSize = () => {
      const coefficient = 1.5;
      const maxHeaderFontSize = 20;
      const maxSubheaderFontSize = 14;

      if (!menuHeader.value) {
        fontSizeHeader.value = maxHeaderFontSize; // when we display menu header
        return;
      }

      if (!headerBlock.value) {
        return;
      }

      const styles = getComputedStyle(headerBlock.value);
      const leftPadding = parseFloat(styles.paddingLeft);
      const rightPadding = parseFloat(styles.paddingRight);
      const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
      const contentWidth = headerBlock.value.clientWidth - leftPadding - rightPadding + scrollWidth;
      const dynamicHeaderSize = (contentWidth / menuHeader.value.length) * coefficient;
      if (dynamicHeaderSize > maxHeaderFontSize) {
        fontSizeHeader.value = maxHeaderFontSize;
      } else {
        fontSizeHeader.value = dynamicHeaderSize;
      }

      const dynamicSubheaderSize = (contentWidth / menuSubheader.value.length) * coefficient;
      if (dynamicSubheaderSize > maxSubheaderFontSize) {
        fontSizeSubheader.value = maxSubheaderFontSize;
      } else {
        fontSizeSubheader.value = dynamicSubheaderSize;
      }
    };

    onMounted(() => {
      calculateFontSize();
      window.addEventListener('resize', calculateFontSize);
    });

    return {
      headerBlock,
      fontSizeHeader,
      fontSizeSubheader,

      menuItemsList,
      isLogoutConfirmVisible,
      canLogout,

      toggleLogoutConfirm,
      logoutWidget,
      openMenuItem,

      menuHeader,
      menuSubheader,
    };
  },
});
</script>

<style lang="scss" scoped>
.widget-menu {
  padding: 0;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    line-height: rem(40);
    font-weight: 700;
    margin-bottom: rem(32);
    padding-right: 20px;

    @media (max-width: $tablet-max) {
      font-size: 24px;
      align-items: start;
      line-height: 26px;
      justify-content: flex-start;
      margin-top: 8px;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: calcVh(28, $widget-h-sm);
      line-height: calcVh(40, $widget-h-sm);
      margin-bottom: calcVh(32, $widget-h-sm);
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: calcVh(28, $widget-h-xlg);
      line-height: calcVh(40, $widget-h-xlg);
      margin-bottom: calcVh(32, $widget-h-xlg);
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: calcVh(28, $widget-h-xxlg);
      line-height: calcVh(40, $widget-h-xxlg);
      margin-bottom: calcVh(32, $widget-h-xxlg);
    }

    h5 {
      margin: 0;
      font-weight: 400;
    }

    &-email {
      font-size: 14px;
      color: $grey;
      line-height: 16px;
      font-family: $font-family;
    }
  }

  &__logout {
    color: red;
    width: 100%;
    font-size: 1rem;
    padding: 0.75rem 0;
    line-height: 1.25rem;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    cursor: pointer;
    text-align: left;
    font-weight: 700;

    &:hover {
      opacity: 0.6;
    }
  }
}

.menu {
  margin-bottom: rem(32);

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    margin-bottom: calcVh(24, $widget-h-sm);
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    margin-bottom: calcVh(24, $widget-h-xlg);
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    margin-bottom: calcVh(24, $widget-h-xxlg);
  }
}
</style>
