<template>
  <TextButton
    icon="file"
    @click="openMenuItem"
  >
    {{ $t('widget.menu.items.transaction-history') }}
  </TextButton>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import TextButton from '@/components/TextButton';

export default {
  name: 'TransactionHistoryButton',

  components: { TextButton },

  setup() {
    const store = useStore();
    const isMenuOpened = computed(() => store.getters.isMenuOpened);

    const openMenuItem = () => {
      if (!isMenuOpened.value) {
        store.dispatch('toggleMenu');
      }
      store.dispatch('selectMenuItem', {
        item: 'transaction-history',
      });
    };

    return {
      openMenuItem,
    };
  },
};
</script>
