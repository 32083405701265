<template>
  <div>
    <div class="zerohash-disclosure__header">
      {{ $t('widget.zerohash-compliance.header') }}
    </div>

    <div class="zerohash-disclosure__message">
      {{ $t('widget.zerohash-compliance.disclaimer') }}
    </div>

    <ZeroHashComplianceCheckbox
      class="zerohash-disclosure__terms"
      :has-error="showAgreementError"
      @change="handleTosCheckboxChange"
    />

    <div class="auth-app-footer auth-app-footer--centered">
      <button
        class="btn btn-primary btn-widget"
        :class="{ 'is-loading': isLoading }"
        :disabled="showAgreementError"
        @click="submit()"
      >
        {{ $t('widget.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

import ZeroHashComplianceCheckbox from '@/overrides/TransactionFunnel/components/ZeroHashComplianceCheckbox';

export default {
  name: 'ZerohashDisclosure',

  components: {
    ZeroHashComplianceCheckbox,
  },

  setup() {
    const store = useStore();

    const agreeToTos = ref(false);
    const showAgreementError = ref(false);
    const isLoading = ref(false);

    const handleTosCheckboxChange = value => {
      agreeToTos.value = value;
      showAgreementError.value = !value;
    };

    const submit = async () => {
      if (!agreeToTos.value) {
        showAgreementError.value = true;
        return;
      }

      isLoading.value = true;

      await store.dispatch('setZerohashAcceptance');
    };

    return {
      agreeToTos,
      showAgreementError,
      isLoading,
      handleTosCheckboxChange,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.zerohash-disclosure {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: rem(28);
    line-height: rem(40);
    font-weight: 700;
    margin-top: -16px;
    margin-bottom: 32px;

    @media (max-width: $tablet-max) {
      font-size: 24px;
      line-height: 26px;
      margin-top: 8px;
    }

    @media (max-width: $screen-sm-min) {
      font-size: 20px;
      line-height: 26px;
      padding-left: 20px;
    }
  }

  &__terms {
    margin-top: 24px;
    margin-bottom: 24vh;
  }
}
</style>
