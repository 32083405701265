import { useStore } from 'vuex';
import { nSureDeviceId } from '@/utils/loadNSure';

const MAX_TICKS = 60;

let nSureTimeoutId = null;
let siftTimeoutId = null;
let nSureTicks = 0;
let siftTicks = 0;

const waitForNSureDeviceId = resolve => {
  if (nSureTimeoutId !== null) {
    clearTimeout(nSureTimeoutId);
  }

  if (nSureTicks > MAX_TICKS) {
    resolve();
    return;
  }

  if (nSureDeviceId.value === null) {
    nSureTicks += 1;
    nSureTimeoutId = setTimeout(() => waitForNSureDeviceId(resolve), 250);
    return;
  }

  resolve();
};

const waitForSift = resolve => {
  if (siftTimeoutId !== null) {
    clearTimeout(siftTimeoutId);
  }

  if (siftTicks > MAX_TICKS) {
    resolve();
    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (!window._sift) {
    siftTicks += 1;
    siftTimeoutId = setTimeout(() => waitForSift(resolve), 250);
    return;
  }

  resolve();
};

export const useIsDeviceIdInitialized = () => {
  const isDeviceIdInitialized = new Promise(resolve => {
    waitForNSureDeviceId(resolve);
  });

  return {
    isDeviceIdInitialized,
  };
};

export const useIsSiftInitialized = () => {
  const isSiftInitialized = new Promise(resolve => {
    waitForSift(resolve);
  });

  return {
    isSiftInitialized,
  };
};

export const useIsAppInitialized = () => {
  const store = useStore();

  const isAppInitialized = new Promise(resolve => {
    store.watch(() => store.getters.isInitCompleted, isInitialized => {
      if (isInitialized) {
        waitForNSureDeviceId(resolve);
      }
    }, { immediate: true });
  });

  return {
    isAppInitialized,
  };
};
