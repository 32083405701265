export const STEP_ERROR = 'critical-error';

export const TransactionFlowStep = {
  STEP_TRANSACTION_FLOW: 'transaction-flow',
  STEP_CARD_AUTHORIZATION: 'card-authorization',
  STEP_GIFT_CARD: 'gift-card',
  STEP_PAYOUT_DETAILS: 'payout-details',
  STEP_EXTRA_VERIFICATION: 'extra-verification',
  STEP_PAYMENT_CHECK: 'payment-checks',
  STEP_PAYMENT_DETAILS: 'payment-details',
  STEP_PAYMENT_REVIEW: 'payment-review',
  STEP_PAYMENT_WAITING: 'payment-waiting',
  STEP_PAYMENT_CHECKS: 'payment-checks',
  STEP_PAYOUT_WAITING: 'payout-waiting',
  STEP_COMPLETED: 'completed',
  STEP_PAYMENT_ERROR: 'payment-error',
  STEP_REJECTED: 'rejected',
  STEP_CANCELLED: 'cancelled',
  STEP_BASE_VERIFICATION: 'base-verification',
  STEP_ZEROHASH_ACCEPTANCE: 'zerohash-acceptance',
};
