<template>
  <div
    class="widget-additional-fees"
    :class="{ hidden: isQuoteHasError }"
  >
    <button
      class="widget-additional-fees__btn"
      :class="{ 'is-open': isExpanded }"
      @click="toggleFeesDropdown"
    >
      <div class="widget-additional-fees__btn-part">
        {{ $t('widget.fees.total_fee') }}
      </div>

      <div class="widget-additional-fees__btn-part">
        <span>{{ totalFees }}</span>
        <i class="svg-icon widget-additional-fees__btn-icon" />
      </div>
    </button>

    <ul
      v-if="isExpanded"
      class="widget-additional-fees__list"
    >
      <li class="widget-additional-fees__list-item">
        <div class="widget-additional-fees__list-item-part">
          {{ $t('widget.fees.service_fee') }}
        </div>

        <div class="widget-additional-fees__list-item-part">
          <span>{{ serviceFees }}</span>
        </div>
      </li>

      <li class="widget-additional-fees__list-item">
        <div class="widget-additional-fees__list-item-part">
          {{ $t('widget.fees.network_fee') }}

          <Tooltip
            class="widget-additional-fees__list-item-icon"
            icon="question"
          >
            {{ $t('widget.fees.network_fee-details') }}
          </Tooltip>
        </div>

        <div class="widget-additional-fees__list-item-part">
          <span>{{ networkFees }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import Tooltip from '@/components/Tooltip';

export default {
  name: 'FeesDetails',

  components: {
    Tooltip,
  },

  props: {
    fees: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const store = useStore();
    const isExpanded = ref(false);

    const toggleFeesDropdown = () => {
      isExpanded.value = !isExpanded.value;
    };

    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const isQuoteHasError = computed(() => store.getters['v2/widgetQuote/hasError']);
    const currentCurrencyTo = computed(() => {
      let currency = store.getters['widgetQuote/currencyTo'];
      if (!store.getters['widgetQuote/isQuoteReady']) {
        currency = store.getters['exchangeForm/toCurrencyCode'];
      }

      return store.getters['exchangeForm/getCurrencyBy'](currency);
    });

    const feesCurrency = computed(() => {
      if (props.fees.currency) {
        return props.fees.currency;
      }

      if (isSellCryptoFlow.value) {
        return currentCurrencyTo.value;
      }

      return store.getters['exchangeForm/fromCurrency'];
    });

    const totalFees = computed(() => `${props.fees.total_fee} ${feesCurrency.value}`);
    const serviceFees = computed(() => `${props.fees.service_fee} ${feesCurrency.value}`);
    const networkFees = computed(() => `${props.fees.network_fee} ${feesCurrency.value}`);

    return {
      isExpanded,
      isQuoteHasError,

      totalFees,
      serviceFees,
      networkFees,

      toggleFeesDropdown,
    };
  },
};
</script>

<style lang="scss" scoped>
$white-50-opacity: 0.5;
$white-50: rgba($white, $white-50-opacity);

.widget-additional-fees {
  border: 1px solid $black-10;
  border-radius: rem(6);
  color: $black;
  flex-direction: column;

  &.desktop {
    width: 100%;
    min-width: rem(276);
    max-width: 21rem;
    border-color: $white-50;
    color: $white-50;
    margin: rem(12) auto 0;

    .widget-additional-fees__btn-part {
      color: $white-50;
    }
  }

  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(8) rem(16);
  }

  .desktop &__btn {
    padding: rem(8) rem(12);
  }

  &__btn-icon {
    display: inline-block;
    margin-left: rem(2);
    transition: transform 0.1s linear;
    width: 18px;
    height: 18px;
    margin-bottom: -4px;
    opacity: $white-50-opacity;
    background-color: $white;
    mask-image: url(~@/assets/images/widget/arrow-bottom-white.svg);
    mask-repeat: no-repeat;

    @media screen and (max-width: $screen-md-max) {
      opacity: 1;
      background-color: $black;
    }
  }

  &__btn.is-open &__btn-icon {
    transform: scaleY(-1);
  }

  &__btn-part:first-child {
    text-align: left;
    margin-right: rem(20);
  }

  &__btn-part:last-child {
    white-space: nowrap;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(16);
    margin-bottom: rem(8);
  }

  .desktop &__list-item {
    padding: 0 rem(12);
  }

  &__list-item-part {
    display: flex;
    align-items: center;
    &:first-child {
      margin-right: rem(16);
    }
    &:last-child {
      white-space: nowrap;
    }
  }

  &__list-item-icon {
    color: $grey-30;
    margin-left: rem(4);

    ::v-deep(.svg-icon) {
      opacity: .5;
      background-color: $white;
      mask-image: url(~@/assets/images/widget/question.svg);
      mask-repeat: no-repeat;
      mask-size: contain;
      background-image: none;
    }

    @media (max-width: $laptop-min) {
      ::v-deep(.svg-icon) {
        opacity: 1;
        background-color: $black;
      }
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &.desktop {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &.mobile {
      display: none;
    }
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &.desktop {
      margin-top: 1.6vh;
    }

    &.desktop &__btn {
      padding: 1vh rem(12);
    }

    &.desktop &__btn,
    &.desktop &__list-item {
      font-size: 2.1vh;
    }
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    &.desktop {
      margin-top: 1.57vh;
    }

    &.desktop &__btn,
    &.desktop &__list-item {
      font-size: 2.09vh;
    }
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    &.desktop {
      margin-top: 1.6vh;
    }

    &.desktop &__btn,
    &.desktop &__list-item {
      font-size: 2.09vh;
    }
  }
}

.widget-additional-fees.hidden {
  display: none;
};
</style>
