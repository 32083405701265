import Api from '../api';

const apiUrl = `${process.env.VUE_APP_CARD_ENTRY_URL}/public/card-payment-service/v1`; // eslint-disable-line no-undef

export default {
  getPreferablePaymentProcessor({ cardId, currency, invoice }) {
    const searchParams = new URLSearchParams();
    searchParams.set('cardId', cardId);
    searchParams.set('currency', currency);
    searchParams.set('invoice', invoice);
    const url = `${apiUrl}/payment/payment-processor?${searchParams.toString()}`;
    return Api().get(url);
  },
  checkoutPaymentCreate({ cardId, invoice, cvv, returnUrl }) {
    const url = `${apiUrl}/payment/checkout/create`;

    return Api().post(url, {
      card_id: cardId,
      invoice,
      cvv,
      challenge_return_url: returnUrl,
    });
  },
  bridgerpayPaymentCreate({ cardId, invoice, cvv, returnUrl, ddc }) {
    const url = `${apiUrl}/payment/bridgerpay/create`;

    return Api().post(url, {
      card_id: cardId,
      invoice,
      challenge_return_url: returnUrl,
      cvv,
      ddc,
    });
  },
  getDdc({ cardId }) {
    const url = `${apiUrl}/payment/world-pay/ddc/${cardId}`;
    return Api().get(url);
  },
  getBridgerpayDdc({ cardId, invoice }) {
    const url = `${apiUrl}/payment/bridgerpay/ddc`;
    return Api().post(url, {
      card_id: cardId,
      invoice,
    });
  },
  initialPayment({
    cardId,
    invoice,
    dfReferenceId,
    cvv,
    browserLanguage,
    returnUrl,
  }) {
    const url = `${apiUrl}/payment/world-pay/initial-payment`;
    return Api().post(url, {
      card_id: cardId,
      df_reference_id: dfReferenceId,
      cvv,
      invoice,
      browser_language: browserLanguage,
      return_url: returnUrl,
    });
  },
  getPaymentStatus({ paymentId }) {
    const url = `${apiUrl}/payment/${paymentId}/status`;
    return Api().get(url);
  },
  getActivePaymentId({ invoice }) {
    const url = `${apiUrl}/payment/invoice/${invoice}`;
    return Api().get(url);
  },
  validatePaymentSession({ validationUrl, invoice }) {
    const url = `${apiUrl}/payment/apple-pay/payment-session`;
    return Api().post(url, {
      validationUrl,
      invoice,
    });
  },
  initialWorldpayApplePayPayment({
    paymentToken,
    cardId,
    invoice,
    dfReferenceId,
    browserLanguage,
    returnUrl,
  }) {
    const url = `${apiUrl}/payment/world-pay/apple-pay/initial-payment`;
    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      df_reference_id: dfReferenceId,
      invoice,
      browser_language: browserLanguage,
      return_url: returnUrl,
    });
  },
  initialWorldpayGooglePayPayment({
    paymentToken,
    cardId,
    invoice,
    dfReferenceId,
    browserLanguage,
    returnUrl,
  }) {
    const url = `${apiUrl}/payment/world-pay/google-pay/initial-payment`;
    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      df_reference_id: dfReferenceId,
      invoice,
      browser_language: browserLanguage,
      return_url: returnUrl,
    });
  },
  createCheckoutApplePayPayment({ paymentToken, cardId, invoice, returnUrl }) {
    const url = `${apiUrl}/payment/checkout/apple-pay/create`;
    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      invoice,
      challenge_return_url: returnUrl,
    });
  },
  createCheckoutGooglePayPayment({ paymentToken, cardId, invoice, returnUrl }) {
    const url = `${apiUrl}/payment/checkout/google-pay/create`;
    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      invoice,
      challenge_return_url: returnUrl,
    });
  },
  isPayoutAllowedByCardId({ cardId, invoice }) {
    const url = `${apiUrl}/payout/check/${cardId}`;
    return Api().get(url, { params: { invoice } });
  },
};
