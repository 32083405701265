// Safari now suddenly blocks iframe cookie access, so we need to call this during some user interaction
export const isSafari = () => navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;

export const requestStorageAccess = callback => new Promise((resolve, reject) => {
  if (!isSafari()) {
    return resolve(callback());
  }

  document.requestStorageAccess().then(() => {
    resolve(callback());
  }, () => {
    reject();
  });
});
