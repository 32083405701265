export default {
  namespaced: true,
  state: {
    isPaymentPopupAllowed: true,
  },
  getters: {
    isPaymentPopupAllowed: state => state.isPaymentPopupAllowed,
  },
  mutations: {
    setIsPaymentPopupAllowed(state, value) {
      state.isPaymentPopupAllowed = value;
    },
  },
  actions: {
    disallowPaymentPopup({ commit }) {
      commit('setIsPaymentPopupAllowed', false);
    },
  },
};
