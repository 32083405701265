import { attachGooglePayScript } from '../factories/GooglePayFactory';

const performTryToAttachGooglePayScript = async onReject => {
  try {
    await attachGooglePayScript();

    if (typeof google === 'undefined') {
      onReject();
    }
  } catch (err) {
    onReject();
  }
};

let isInitiationStarted = false;

export const initGooglePay = async ({
  googlePaySettings,
  paymentAmount,
  onSuccess,
  onReject,
  onPaymentRequestDone,
}) => {
  if (isInitiationStarted) {
    // XXX: prevent GooglePay to start initiation, if it's already started
    return;
  }
  isInitiationStarted = true;
  let isGooglePayCanBeUsed = true;

  try {
    await performTryToAttachGooglePayScript(() => {
      throw new Error('Google SDK was not initiated');
    });
  } catch (err) {
    await performTryToAttachGooglePayScript(() => {
      isGooglePayCanBeUsed = false;
      isInitiationStarted = false;
      onReject();
    });
  }

  if (!isGooglePayCanBeUsed) {
    isInitiationStarted = false;
    return;
  }

  const { baseRequest, baseCardPaymentMethod, tokenizationSpecification } = googlePaySettings;
  const payment = paymentAmount;

  const isReadyToPayRequest = { ...baseRequest };
  isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
  const PaymentsClient = new google.payments.api.PaymentsClient({
    environment: process.env.VUE_APP_GOOGLE_PAY_ENV,
  });

  const handleIsReadyToPay = () => {
    // Google Pay | Payment Data Prefetch
    const cardPaymentMethod = { ...baseCardPaymentMethod, tokenizationSpecification };
    const PaymentDataRequest = { ...baseRequest };
    PaymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    PaymentDataRequest.transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: payment.getAmount(),
      currencyCode: payment.getCurrency(),
      countryCode: 'GB',
    };
    PaymentDataRequest.merchantInfo = {
      merchantName: process.env.VUE_APP_GOOGLE_PAY_MERCHANT_NAME,
      merchantId: process.env.VUE_APP_GOOGLE_PAY_MERCHANT_ID,
    };

    PaymentsClient.prefetchPaymentData(PaymentDataRequest);

    onPaymentRequestDone(PaymentDataRequest);
    onSuccess();
  };

  await PaymentsClient.isReadyToPay(isReadyToPayRequest)
    .then(({ result }) => {
      if (!result) return onReject();
      handleIsReadyToPay();
    })
    .catch(e => {
      if (e.name === 'DataCloneError') {
        // XXX: Ignore DataCloneError for android chrome
        handleIsReadyToPay();

        return;
      }

      // Unlock
      onSuccess();
    });
};
