<template>
  <div class="otp">
    <VerificationCodeInput
      :value="code"
      :is-invalid="hasError"
      :is-disabled="isDisabledCodeInput"
      @filled="onCodeFilled"
      @incomplete="onCodeIncomplete"
      @submit="handleSubmitOtp"
    />
    <div
      v-if="hasError"
      class="otp__error"
    >
      {{ errorMessage }}
    </div>

    <Resend
      :is-resend-timeout="isResendTimeout"
      :is-loading-resend="isLoadingResend"
      :resend-time-string="resendTimeString"
      @resend-otp="handleResendOtp"
    />

    <div
      :class="{ 'has-back-button': isBackButtonEnabled }"
      class="auth-app-footer"
    >
      <div
        class="btn-back-link"
        @click="handleBack"
      >
        <i class="icon icon-back btn-back-link__icon" />
        {{ $t('authentication-app.buttons.back') }}
      </div>

      <button
        :class="{ 'is-loading': isLoading }"
        :disabled="hasError || !isCodeFilled"
        class="btn btn-primary btn-lg"
        @click="handleSubmitOtp(code)"
      >
        {{ $t('authentication-app.buttons.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import VerificationCodeInput from '../VerificationCodeInput';
import Resend from '../Resend';
import { executeEverySecond, removeExecutionById } from '../../utils/secondsTicker';
import { getTimerFromTimestamp } from '../../utils/getTimerFromTimestamp';

export default {
  name: 'Otp',

  components: {
    VerificationCodeInput,
    Resend,
  },

  props: {
    resendTime: {
      type: Date,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingResend: {
      type: Boolean,
      default: false,
    },
    hasNoAttemptsLeft: {
      type: Boolean,
      default: false,
    },
    isBackButtonEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    resendMilliseconds: 0,
    isCodeFilled: false,
    code: '',
  }),

  computed: {
    isResendTimeout() {
      return (this.resendMilliseconds / 1000) < 1;
    },
    isDisabledCodeInput() {
      return this.hasNoAttemptsLeft;
    },
    hasError() {
      return this.errorMessage !== '';
    },
    resendTimeString() {
      return getTimerFromTimestamp(this.resendMilliseconds);
    },
  },

  watch: {
    resendTime(newTime, oldTime) {
      if (oldTime >= newTime) {
        return;
      }
      this.startTimer(newTime);
    },
    hasNoAttemptsLeft(val) {
      if (val) {
        this.code = '';
      }
    },
    code(val) {
      this.$emit('change', val);
    },
  },

  mounted() {
    this.startTimer(this.resendTime);
  },

  methods: {
    onCodeIncomplete(code) {
      this.isCodeFilled = false;
      this.$emit('incomplete-otp', code);
    },

    onCodeFilled(code) {
      this.isCodeFilled = true;
      this.code = code;
      this.$emit('success-otp', code);
    },

    handleSubmitOtp(code) {
      if (!this.isCodeFilled) {
        return;
      }

      this.$emit('success-otp', code);
    },

    handleResendOtp() {
      this.code = '';
      this.isCodeFilled = false;
      this.$emit('resend-otp');
    },

    handleBack() {
      this.$emit('back');
    },

    startTimer(resendTime) {
      let timeoutId = null;
      const dateNow = new Date();
      this.resendMilliseconds = resendTime - dateNow.getTime();
      timeoutId = executeEverySecond(tickerTime => {
        this.resendMilliseconds = resendTime - tickerTime;
        if (tickerTime > resendTime) {
          return removeExecutionById(timeoutId);
        }
      }, true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/icon';
@import '../../assets/scss/btn';

.btn-back-link {
  display: none;
}
.auth-app-footer {
  justify-content: center;

  &.has-back-button {
    justify-content: space-between;

    & .btn-back-link {
      display: flex;
      font-family: $font-family;
      align-items: center;

      &__icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.otp {
  &__error {
    display: block;
    margin-top: -8px;
    margin-bottom: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 1.33;
    color: $red;
  }
}
</style>
