<template>
  <div class="widget-footer">
    <FeaturesList />
    <PoweredBy v-if="showPoweredBy" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import FeaturesList from './FeaturesList';
import PoweredBy from './PoweredBy';

export default {
  name: 'WidgetFooter',

  components: {
    FeaturesList,
    PoweredBy,
  },

  setup() {
    const store = useStore();
    const showPoweredBy = computed(() => !!store.getters.themeConfig?.showPoweredBy);

    return { showPoweredBy };
  },

};
</script>

<style lang="scss" scoped>
.widget-footer {
  padding: rem(24) rem(48);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tablet-max) {
    padding: rem(10) rem(24) rem(16);
  }

  @media (max-width: $mobile-max) {
    margin: 0 12px;
    padding: 24px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    flex-direction: column;
    justify-content: space-around;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    padding: 3.12vh rem(48);
  }
}
</style>
