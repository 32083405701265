const themeNames = {
  BINANCE: 'binance',
  COINPAYMENTS: 'coinpayments',
  PAYBIS: 'paybis',
  YOURBRAND: 'yourbrand',
  LBANK: 'lbank',
  ZENGO: 'zengo',
  WHITEBIT: 'whitebit',
  NEXO: 'nexo',
  OOBIT: 'oobit',
  BITPAY: 'bitpay',
  ABRA: 'abra',
  BITCOINCOM: 'bitcoincom',
  WALTZER: 'waltzer',
  THORWALLET: 'thorwallet',
  DIGITALLABS: 'digitallabs',
  AZONDO: 'azondo',
  TRUSTDICE: 'trustdice',
  COINSBEE: 'coinsbee',
  GLOBIANCE: 'globiance',
  DOSHI: 'doshi',
  BEAXY: 'beaxy',
  FIRSTBYT: 'firstbyt',
  FAIRDESK: 'fairdesk',
  KUCOIN: 'kucoin',
  TRADEBULLS: 'tradebulls',
  XPAID: 'xpaid',
  LIVITPAY: 'livitpay',
  COINMAMA: 'coinmama',
  ARKFI: 'arkfi',
  MILLIONERO: 'millionero',
};

const themes = {
  '0d49fa87-0d62-451b-bf53-412f73c6e71e': themeNames.BINANCE,
  'c5037b9f-8f59-41c5-b408-191041c59800': themeNames.COINPAYMENTS,
  '8f41ef2c-6990-4752-94ab-7464d4e91eb5': themeNames.PAYBIS,
  '824e2ce7-e2d1-42ea-8a0c-032448b946e0': themeNames.YOURBRAND,
  '59490b14-c50a-4885-9a34-26ef9f007ce6': themeNames.LBANK,
  '25be8002-860c-4978-947b-b87dde72a308': themeNames.ZENGO,
  '1219bbf1-df0d-4d61-8315-7697623dd2bd': themeNames.WHITEBIT,
  '0c257c8f-a097-41a9-9166-22b275709474': themeNames.NEXO,
  '5fb26e53-8f51-4207-bfb9-57b553c968e5': themeNames.OOBIT,
  '3b3f9463-7cae-4702-acf9-5b1a1544addc': themeNames.BITPAY,
  '1565789f-c1e9-49bb-89ad-2876fbc08b50': themeNames.ABRA,
  'f03f136b-a485-41bb-af43-43e9d99c4b40': themeNames.BITCOINCOM,
  'd9fc4d75-9419-4f88-8dcf-a0e1177ff934': themeNames.WALTZER,
  '1f11c855-3ff4-4284-8d51-28123c15c05b': themeNames.THORWALLET,
  '0ed456cb-da28-4c72-bcd7-c357cbf82a73': themeNames.DIGITALLABS,
  'f0ca99c5-0404-42dc-a793-77ca5743f722': themeNames.AZONDO,
  '0f4a121e-273f-48b5-a5fb-f387f469ac3d': themeNames.TRUSTDICE,
  '77328a0c-b7b8-4344-867e-4242105666ce': themeNames.COINSBEE,
  '90ba762c-e0f5-4ad1-95db-97f6fdb90f6e': themeNames.GLOBIANCE,
  '3a087006-e076-4ead-9817-c28eecfbc363': themeNames.DOSHI,
  '4023e47b-8026-49f7-847c-19f222fa7364': themeNames.BEAXY,
  '181e283f-bc99-4b04-be4d-7061682043a3': themeNames.FIRSTBYT,
  '3f1ba308-ab89-4a6c-bba6-d04454323959': themeNames.FAIRDESK,
  '5101107b-d0e5-4640-8b09-ed81baa206d3': themeNames.KUCOIN,
  '379a3593-0de0-4fb7-b347-84d57b02302b': themeNames.TRADEBULLS,
  '15061cf4-0bee-4133-b588-21834b5f83ef': themeNames.XPAID,
  '939bacfe-e22b-452b-8d73-8770a2934bb4': themeNames.LIVITPAY,
  '381e8b04-33af-40e5-8b15-24b6ecd11455': themeNames.COINMAMA,
  'dfc07aa8-f058-4db5-a5f8-c0ebbb49ac25': themeNames.MILLIONERO,
  'e1349537-6ca6-46d0-a1a5-d5b7c9599065': themeNames.ARKFI,
  // BC: remove after a while
  binance: themeNames.BINANCE,
  coinpayments: themeNames.COINPAYMENTS,
  paybis: themeNames.PAYBIS,
  yourbrand: themeNames.YOURBRAND,
  lbank: themeNames.LBANK,
  zengo: themeNames.ZENGO,
  whitebit: themeNames.WHITEBIT,
  nexo: themeNames.NEXO,
  oobit: themeNames.OOBIT,
  bitpay: themeNames.BITPAY,
  abra: themeNames.ABRA,
  bitcoincom: themeNames.BITCOINCOM,
  waltzer: themeNames.WALTZER,
  thorwallet: themeNames.THORWALLET,
  digitallabs: themeNames.DIGITALLABS,
  azondo: themeNames.AZONDO,
  trustdice: themeNames.TRUSTDICE,
  coinsbee: themeNames.COINSBEE,
  globiance: themeNames.GLOBIANCE,
  doshi: themeNames.DOSHI,
  beaxy: themeNames.BEAXY,
  firstbyt: themeNames.FIRSTBYT,
  kucoin: themeNames.KUCOIN,
  fairdesk: themeNames.FAIRDESK,
  tradebulls: themeNames.TRADEBULLS,
  xpaid: themeNames.XPAID,
  livitpay: themeNames.LIVITPAY,
  coinmama: themeNames.COINMAMA,
  millionero: themeNames.MILLIONERO,
  arkfi: themeNames.ARKFI,
};

export default themes;
