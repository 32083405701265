<template>
  <div class="payment-select">
    <PaymentSelectItem
      v-for="(payment, i) in payments"
      :selected="selected"
      :isLoading="isLoading"
      :key="i"
      :payment="payment"
      @selectPayment="onSelectPayment"
      @pay="$emit('pay', $event)"
    />
    <PaymentSelectNew
      :placeholder="props.newPlaceholder || $t('transaction-flow.payment-details.card-details.add-card').toString()"
      :selected="selected"
      @selectPayment="onSelectPayment"
    />
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import PaymentSelectItem from './PaymentSelectItem';
import PaymentSelectNew from './PaymentSelectNew';

const props = defineProps({
  payments: {
    type: Array,
    required: true,
    default: () => [],
  },
  selected: {
    type: String,
    required: true,
    default: 'new',
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false,
  },
  newPlaceholder: {
    type: String,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(['selectPayment']);

const onSelectPayment = id => {
  emit('selectPayment', id);
};
</script>

<style lang="scss" scoped>
.payment-select {
  width: 100%;

  @media screen and (min-width: $screen-lg-min) {
    border: 1px solid $black-10;
    border-radius: rem(6);
    padding: rem(8) rem(24) rem(8) rem(16);

    [dir='rtl'] & {
      padding: rem(8) rem(16) rem(8) rem(24);
    }
  }
}
</style>
