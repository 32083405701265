<template>
  <div class="waiting-confirmations">
    <FunnelLoader />
    <Title>{{ $t('widget.transaction-flow.steps.waiting-confirmations.title') }}</Title>
    <AutoProgressBar max-width="280px" />
    <Paragraph
      class=""
      v-html="$t('widget.transaction-flow.steps.waiting-confirmations.paragraph')"
    />
    <Paragraph
      small
      v-html="ourWalletLink"
    />
  </div>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import FunnelLoader from '@paybis/frontend-transaction-funnnel-app/src/components/funnel-loader';
import AutoProgressBar from '@paybis/frontend-common-lib/src/components/auto-progress-bar';
import { mapGetters } from 'vuex';
import CryptoViewModel from '@paybis/frontend-transaction-funnnel-app/src/view-models/crypto';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    AutoProgressBar,
    FunnelLoader,
    Title,
    Paragraph,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payment']),
    crypto() {
      return new CryptoViewModel(this.payment.getDetails());
    },
    ourWalletLink() {
      return this.t(
        'transaction-flow.steps.payment-waiting.crypto.explorer.text',
        {
          link: `<a href="${this.crypto.getOurWalletLink()}" target="_blank" class="btn-link">${this.t(
            'transaction-flow.steps.payment-waiting.crypto.explorer.link',
          )}</a>`,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.waiting-confirmations {
  display: flex;
  flex-direction: column;
  // order for mobile
    @media  screen and (max-width: $mobile-min) {
      &__title {
        order: -1;
        text-align: left;
      }
    }
  }
</style>
