// TODO: refactor. DIP violation. It forces to use some folder structure in the project where it is used.
import store from '@/store/index';

import CardPaymentClient from '../../services/clients/card-payment-client';
import AbstractPaymentProcessor from '../AbstractPaymentProcessor';

export default class CheckoutPaymentProcessor extends AbstractPaymentProcessor {
  constructor(cardId, invoice, cvv) {
    super('checkout', cardId, invoice);

    this.cvv = cvv;
  }

  getCvv() {
    return this.cvv;
  }

  getReturnUrl() {
    if (store.getters['settings/isWidget']) {
      const parsedUrl = new URL(window.location.href);

      const searchParams = new URLSearchParams();
      // TODO: DIP violation. Should be fixed
      searchParams.set('requestId', store.getters.requestId);

      return `${parsedUrl.origin}/?${searchParams.toString()}`;
    }

    return window.location.href;
  }

  pay() {
    return CardPaymentClient.checkoutPaymentCreate({
      cardId: this.getCardId(),
      invoice: this.getInvoice(),
      cvv: this.getCvv(),
      returnUrl: this.getReturnUrl(),
    }).then(({ data }) => {
      const {
        payment_id: paymentId,
        is_challenge_required: isRedirect,
        challenge_info: challengeInfo,
      } = data;

      const { url: challengeLink } = challengeInfo || {};

      return { isRedirect, challengeLink, paymentId };
    });
  }

  initApplePayPayment(paymentRequest) {
    paymentRequest.invoice = this.getInvoice();
    paymentRequest.cardId = this.getCardId();

    return CardPaymentClient.createCheckoutApplePayPayment(paymentRequest).then(
      ({ data }) => {
        const {
          is_challenge_required: isChallengeRequired,
          challenge_info: challengeInfo,
          payment_id: paymentId,
        } = data;

        return { isChallengeRequired, challengeInfo, paymentId };
      },
    );
  }

  initGooglePayPayment(paymentRequest) {
    paymentRequest.invoice = this.getInvoice();
    paymentRequest.cardId = this.getCardId();

    return CardPaymentClient.createCheckoutGooglePayPayment(
      paymentRequest,
    ).then(({ data }) => {
      const {
        is_challenge_required: isRedirect,
        challenge_info: challengeInfo,
        payment_id: paymentId,
      } = data;

      const { url: challengeLink } = challengeInfo || {};

      return { isRedirect, challengeLink, paymentId };
    });
  }
}
