<template>
  <div class="widget-content-area">
    <WidgetContentToast />

    <div class="widget-content-area__wrapper">
      <button
        v-if="!hideTransactionDetails"
        class="burger-button svg-icon icon-burger-menu-mask"
        :class="{ 'burger-button--opened': isMenuOpened }"
        role="menu"
        @click="onToggleMenu"
      />

      <div class="widget-content-area__inner">
        <WidgetMenu
          v-if="isMenuOpened"
          :is-opened="isMenuOpened"
          :current-item="currentMenuItem"
          @close="onToggleMenu"
        />

        <PreloadRouterView />
      </div>

      <ExchangeRateInfo
        v-if="!hideTransactionDetails"
        skin="mobile"
      />
      <div
        v-if="!hideTransactionDetails"
        class="widget-content-area__fees-details"
        :class="{ hidden: isQuoteHasError }"
      >
        <FeesDetails
          :fees="quoteFees"
        />

        <ZhFeeApproximation
          v-if="flowDetails.isZerohashPayoutStrategy()"
          skin="mobile"
        />
      </div>

      <div class="widget-content-area__footer">
        <TransactionHistoryButton
          v-if="isTransactionHistoryAvailable"
          class="widget-content-area__footer-item"
        />
        <SupportChat
          class="widget-content-area__chat-button"
          :class="{ 'widget-content-area__footer-item': isTransactionHistoryAvailable }"
          :chat-key="liveChatKey"
          :lang="currentLang"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import PreloadRouterView from '@/router/PreloadRouterView';

import ExchangeRateInfo from '@/components/ExchangeRateInfo';
import SupportChat from '@/components/SupportChat';
import TransactionHistoryButton from '@/components/WidgetMenu/TransactionHistoryButton';
import WidgetMenu from '@/components/WidgetMenu';
import FeesDetails from '@/components/FeesDetails';
import WidgetContentToast from '@/components/WidgetContentToast';

import { isErrorRoute as hideTransactionDetails } from '@/composables/errorScreenAppearance';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

import ZhFeeApproximation from '../ZhDisclosure/ZhFeeApproximation';

export default defineComponent({
  name: 'WidgetContentArea',

  components: {
    ZhFeeApproximation,
    ExchangeRateInfo,
    SupportChat,
    TransactionHistoryButton,
    WidgetMenu,
    FeesDetails,
    WidgetContentToast,
    PreloadRouterView,
  },

  setup() {
    const store = useStore();
    // TODO: should be moved to `WindgetMenuLayout` component, since this logic belongs to it.
    const isMenuOpened = computed(() => store.getters.isMenuOpened);
    const onToggleMenu = () => store.dispatch('toggleMenu');
    const currentMenuItem = computed(() => store.getters.currentMenuItem);
    const isQuoteReady = computed(() => store.getters['widgetQuote/isQuoteReady']);
    const isQuoteHasError = computed(() => store.getters['v2/widgetQuote/hasError']);
    const liveChatKey = computed(() => store.getters.liveChatKey);
    const currentLang = computed(() => store.getters.locale);
    const isTransactionHistoryAvailable = computed(() => {
      return configShowTransactionHistory.value && ((isNewExchangeFormFlowEnabled() && !hideTransactionDetails.value) || store.getters['transactionHistory/isAvailable']);
    });
    const themeConfig = computed(() => store.getters.themeConfig);
    const configShowTransactionHistory = computed(() => themeConfig.value?.showTransactionHistory ?? true);

    const quoteFees = computed(() => {
      if (!store.getters['widgetQuote/fees']) {
        return {
          network_fee: '0.00',
          service_fee: '0.00',
          total_fee: '0.00',
        };
      }

      return store.getters['widgetQuote/fees'];
    });

    const flowDetails = computed(() => store.getters['transaction/flowDetails']);

    return {
      quoteFees,
      isMenuOpened,
      onToggleMenu,
      currentMenuItem,
      isQuoteReady,
      isQuoteHasError,
      isTransactionHistoryAvailable,

      liveChatKey,
      currentLang,

      hideTransactionDetails,

      flowDetails,
    };
  },
});
</script>

<style lang="scss">
.view-transition {
  display: block;
  animation: view-transition .3s ease-out;
}
</style>

<style lang="scss" scoped>

.widget-content-area {
  position: relative;
  background: #fff;
  border-radius: 6px;
  max-width: 672px; // 42rem;
  overflow: hidden;

  @media (max-width: $tablet-max) {
    max-width: 608px;
  }

  @media (min-width: 1900px) {
    max-width: 756px;
  }

  @media (min-width: $desktop-xxlg-min) {
    max-width: 900px;
  }

  &__wrapper {
    position: relative;

    @media (min-width: $laptop-min) {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
    }
  }

  .burger-button {
    position: absolute;
    z-index: 100;
    width: rem(24);
    height: rem(24);
    right: rem(20);
    top: rem(22);
    cursor: pointer;
    transition: transform 0.15s linear;

    @media (max-width: $tablet-max) {
      right: rem(24);
      top: rem(33);
    }

    &:hover {
      opacity: 0.6;
    }

    &--opened {
      transform: rotate(90deg);
    }
  }

  &__inner {
    position: relative;
    padding: rem(40) rem(56) rem(24);
    overflow: hidden;

    @media (max-width: $tablet-max) {
      padding: 20px 24px 56px;
      min-height: 420px;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      padding-top: 5.2vh;
      padding-bottom: 3.125vh;
    }

    @media (min-width: $laptop-min) {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
    }

    @media (min-width: $desktop-min) {
      padding-left: rem(80);
      padding-right: rem(80);
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      padding-top: 5.22vh;
      padding-bottom: 3.13vh;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      padding-top: 5.3vh;
      padding-bottom: 3.18vh;
    }
  }

  &__fees-details {
    background: $grey-04;
    padding: 6px 20px 0;

    @media (min-width: $tablet-max) {
      display: none;
    }
  }

  &__chat-button {
    width: 100%;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: $screen-sm-min) {
      padding: 10px 0;
      background: $grey-04;
    }

    &-item {
      flex-shrink: 0;

      &:first-child {
        width: 60%;
        border-radius: 0 0 0 6px;
        justify-content: flex-start;
        padding-left: 60px;

        @media (max-width: $laptop-middle) {
          padding-left: 24px;
        }
      }

      &:last-child {
        width: 40%;
        border-radius: 0 0 6px 0;
        justify-content: flex-end;
        padding-right: 60px;

        @media (max-width: $laptop-middle) {
          padding-right: 24px;
        }
      }

      &:first-child, &:last-child {
        @media (max-width: $screen-sm-min) {
          width: 100%;
          height: 40px;
          justify-content: center;
          padding: 0 24px;
          border-radius: 0;
        }
      }
    }
  }
}

.widget-content-area__fees-details.hidden {
  display: none;
};
</style>
