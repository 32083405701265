import { executeEverySecond, removeExecutionById } from '@paybis/frontend-common-lib/src/utils/secondsTicker';
// eslint-disable-next-line import/no-cycle
import WidgetService from '@/services/widget/widgetService.v1';
import { isV2 } from '@/services/apiVersion';

let quoteAutorefreshSubscribeId = null;
let quoteUnwatch = null;
const ONE_MINUTE_MS = 60 * 1000;

export const widgetQuote = {
  namespaced: true,

  state: {
    data: {},
    secondsToExpire: null,
    isQuoteLoading: false,
    originalExpirationTimeDiff: ONE_MINUTE_MS,
    reactivityBooster: 1, // We need it to make `refreshTime` getter reactive on v2
  },

  getters: {
    secondsToExpire: ({ secondsToExpire }) => {
      if (secondsToExpire && secondsToExpire >= 0) {
        return secondsToExpire;
      }

      return 0;
    },
    quote: state => state.data,
    quoteId: state => state.data.quoteId,
    commonQuotePayment: (state, getters, globalState, globalGetters) => globalGetters['external/funnelQuotePayment'] || {},
    commonQuotePayout: (state, getters, globalState, globalGetters) => globalGetters['external/funnelQuotePayout'] || {},
    commonQuoteFees: (state, getters, globalState, globalGetters) => {
      const { networkFee, totalFee, serviceFee, currency } = globalGetters['external/funnelQuoteFees'];
      if (!totalFee || !currency) return null;

      return {
        network_fee: networkFee,
        service_fee: serviceFee,
        total_fee: totalFee,
        currency,
      };
    },
    commonHasQuote: (state, getters, globalState, globalGetters) => globalGetters['external/funnelHasQuote'] || false,
    fees: (state, getters) => getters.commonQuoteFees || state.data.fees,
    amountToCropped: ({ data }, { commonQuotePayout }) => {
      const amountTo = commonQuotePayout.amount || data.amountTo || '0';
      return amountTo.substring(0, 10);
    },
    amountFrom: ({ data }, { commonQuotePayment }) => commonQuotePayment.amount || data?.amountFrom,
    currencyCodeFrom: ({ data }, { commonQuotePayment }) => (
      commonQuotePayment.currency || data?.currencyCodeFrom
    ),
    currencyCodeTo: ({ data }, { commonQuotePayout }) => (
      commonQuotePayout.currency || data?.currencyCodeTo
    ),
    currencyTo: ({ data }, { commonQuotePayout }) => (
      commonQuotePayout.currency || data?.currencyTo
    ),

    isQuoteReady: (state, getters) => (
      state.data?.quoteId || getters.commonHasQuote
    ),
    isQuoteLocked: () => false,

    isQuoteLoading: state => state.isQuoteLoading,

    refreshTime: (state, getters, rootState, rootGetters) => {
      const time = Date.now() + ONE_MINUTE_MS;
      const { reactivityBooster } = state;

      if (isV2() && reactivityBooster) {
        // XXX: PD-12078 set quote expiration time to default
        // while tx is not created yet
        return time;
      }

      if (!rootGetters['request/quoteId']) {
        return time;
      }

      const { quote: { expiresAt } } = getters;

      return (new Date(expiresAt)).getTime();
    },
    refreshTimeV2: () => () => Date.now() + ONE_MINUTE_MS,

    originalExpirationTimeDiff: (state, getters, rootState, rootGetters) => {
      const requestQuoteId = rootGetters['request/quoteId'];
      const invoiceId = rootGetters['request/invoiceId'];
      if (requestQuoteId === null || (isV2() && !invoiceId)) {
        return ONE_MINUTE_MS;
      }

      return state.originalExpirationTimeDiff;
    },
  },

  mutations: {
    setQuote(state, quote) {
      if (Object.keys(quote).length > 0) {
        const { amountFrom, amountTo } = quote;
        quote.amountFrom = amountFrom.amount;
        quote.currencyFrom = amountFrom.currency;
        quote.amountTo = amountTo.amount;
        quote.currencyTo = amountTo.currency;
      }
      state.data = quote;
      state.originalExpirationTimeDiff = new Date(quote.expiresAt).getTime() - Date.now();
    },
    setLoading(state, loadingStatus) {
      state.isQuoteLoading = loadingStatus;
    },
    boostReactivity(state) {
      state.reactivityBooster += state.reactivityBooster;
    },
  },

  actions: {
    async initQuoteById({ commit, dispatch }, quoteId) {
      const quote = await WidgetService.getQuoteById(quoteId);

      commit('setQuote', quote);
      await dispatch('exchangeForm/updateQuote', quote, {
        root: true,
      });
    },

    subscribeToQuoteAutorefresh({ dispatch, commit, getters }, { refreshTime, isWithPaymentMethod }) {
      if (quoteAutorefreshSubscribeId) {
        removeExecutionById(quoteAutorefreshSubscribeId);
      }

      quoteAutorefreshSubscribeId = executeEverySecond(async currentTime => {
        if (currentTime < refreshTime || getters.isQuoteLoading) return;

        if (isV2()) {
          const refreshQuoteMethod = isWithPaymentMethod ? 'refreshQuoteWithPaymentMethod' : 'refreshQuote';
          try {
            commit('setLoading', true);
            // XXX: maybe refactor?
            await dispatch(`v2/widgetQuote/${refreshQuoteMethod}`, {}, { root: true });

            commit('setLoading', false);
          } catch (e) {
            removeExecutionById(quoteAutorefreshSubscribeId);
          } finally {
            commit('setLoading', false);
          }

          return;
        }

        try {
          commit('setLoading', true);

          const refreshedQuote = await dispatch('refreshQuote');

          await dispatch('exchangeForm/updateQuote', refreshedQuote, {
            root: true,
          });
        } catch (e) {
          removeExecutionById(quoteAutorefreshSubscribeId);
        } finally {
          commit('setLoading', false);
        }
      });
    },

    async refreshQuote({ commit, rootGetters }) {
      const requestId = rootGetters['request/requestId'];
      const refreshedQuote = await WidgetService.refreshQuote(requestId);

      commit('setQuote', refreshedQuote);

      return refreshedQuote;
    },

    async generateQuote({ commit, rootGetters }, quoteData) {
      const requestId = rootGetters['request/requestId'];
      const { locale } = rootGetters;
      const quote = await WidgetService.generateQuote(requestId, { locale, quote: quoteData });

      commit('setQuote', quote);

      return quote;
    },

    setErrorQuote({ commit }, quote) {
      if (quoteAutorefreshSubscribeId !== null) {
        removeExecutionById(quoteAutorefreshSubscribeId);
      }

      commit('setQuote', quote);
    },

    deleteQuote({ commit }) {
      commit('setQuote', {});
    },
  },
};

export const stopQuoteAutorefresh = () => {
  removeExecutionById(quoteAutorefreshSubscribeId);
};

export const refreshQuoteSubscriber = (store, isWithPaymentMethod = false) => {
  if (typeof quoteUnwatch === 'function') {
    quoteUnwatch();
  }

  quoteUnwatch = store.watch(
    (state, getters) => ([
      getters['widgetQuote/isQuoteReady'],
      getters['widgetQuote/refreshTime'],
      getters['external/funnelInvoiceId'],
    ]),
    ([isQuoteReady, refreshTime, funnelInvoiceId]) => {
      if (!isQuoteReady || funnelInvoiceId) {
        stopQuoteAutorefresh();
        return;
      }

      const dispatchOpts = { root: true };
      store.dispatch('external/initCryptoTimer', {
        expiresAt: refreshTime,
      }, dispatchOpts);
      store.dispatch('widgetQuote/subscribeToQuoteAutorefresh', { refreshTime, dispatchOpts, isWithPaymentMethod });
    },
    { immediate: true },
  );
};

export const refreshQuoteWithPaymentMethodSubscriber = store => {
  refreshQuoteSubscriber(store, true);
};
