import { version } from '../../../package.json';

import themes from './constants';

export const getThemeName = themeId => {
  const themeName = themes[themeId] || null;

  if (themeName === null) {
    throw new Error(`Theme with id ${themeId} does not exist. Please contact your provider`);
  }

  return themeName;
};

export const loadPartnersCss = async (themeName) => new Promise((resolve, reject) => {
  if (!themeName) {
    reject();

    return;
  }

  const link = document.createElement('link');
  const partnerFileName = themeName.toLowerCase();
  // eslint-disable-next-line no-undef
  const filename = `/partners/${partnerFileName}/styles.css?${version}`;
  link.rel = 'stylesheet';
  link.href = filename;
  link.onload = resolve;

  const { head } = document;
  head.appendChild(link);
});

// eslint-disable-next-line global-require, import/no-dynamic-require
export const loadPartnerJSON = themeName => require(`/public/partners/${themeName}/config`);

export const isCoinmamaTheme = (themeName = '') => themeName.toLowerCase() === themes.coinmama;
