<template>
  <div
    ref="wrapper"
    :class="{
      'is-open': isOpen,
      'is-bottom': bottom,
      'is-left': left,
      'is-fixed': fixed
    }"
    class="pb-tooltip"
    @mouseenter="handleHover"
    @mouseleave="handleHoverOut"
    @touchstart="handleTouch"
  >
    <span class="pb-tooltip__icon">
      <Icon icon="question-circle" />
    </span>

    <div
      ref="tooltip"
      class="pb-tooltip__content-wrapper"
    >
      <div class="pb-tooltip__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './icon';

export default {
  name: 'Tooltip',

  components: {
    Icon,
  },

  props: {
    bottom: { type: Boolean, required: false, default: false },
    left: { type: Boolean, required: false, default: false },
    fixed: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      isOpen: false,
      scrollableParent: null,
    };
  },

  mounted() {
    if (this.left && document.dir === 'rtl') {
      // eslint-disable-next-line
      return (this.left = false);
    }
  },

  destroyed() {
    document.removeEventListener('touchstart', this.handleCloseAll);

    window.removeEventListener('scroll', this.handleParentScroll);

    if (this.scrollableParent) {
      this.scrollableParent.removeEventListener(
        'scroll',
        this.handleParentScroll,
      );
    }
  },

  methods: {
    handleHover(e) {
      this.isOpen = true;

      const leftSpace = this.$refs.wrapper.getBoundingClientRect().x;
      const rightSpace = window.innerWidth
        - this.$refs.wrapper.getBoundingClientRect().x
        - this.$refs.wrapper.getBoundingClientRect().width;

      if (this.fixed) {
        this.positionFixed();
      } else {
        this.positionStatic(leftSpace, rightSpace);
      }
    },

    positionStatic(leftSpace, rightSpace) {
      if (leftSpace < 260) {
        if (leftSpace > rightSpace) {
          this.$refs.tooltip.style.right = `-${rightSpace - 24}px`;
          this.$refs.tooltip.style.left = 'auto';
        } else {
          this.$refs.tooltip.style.left = `-${leftSpace - 24}px`;
          this.$refs.tooltip.style.right = 'auto';
        }
      } else {
        this.$refs.tooltip.style = [];
      }
    },

    positionFixed() {
      const { wrapper } = this.$refs;
      const toggleRect = wrapper.getBoundingClientRect();
      const { tooltip } = this.$refs;
      const tooltipRect = tooltip.getBoundingClientRect();

      const xShift = this.left ? toggleRect.width - tooltipRect.width : '';
      const yShift = this.bottom ? toggleRect.height : tooltipRect.height * -1;

      tooltip.style.top = `${toggleRect.y + yShift}px`;
      tooltip.style.left = `${toggleRect.x + xShift}px`;

      this.scrollableParent = wrapper.closest('.table-scroll-wrapper');

      this.scrollableParent.addEventListener('scroll', this.handleParentScroll);

      window.addEventListener('scroll', this.handleParentScroll);
    },

    handleHoverOut() {
      this.isOpen = false;

      if (this.fixed) {
        window.removeEventListener('scroll', this.handleParentScroll);
      }

      if (this.scrollableParent) {
        this.scrollableParent.removeEventListener(
          'scroll',
          this.handleParentScroll,
        );
      }
    },

    handleTouch() {
      if (!this.isOpen) {
        this.handleHover();

        document.addEventListener('touchstart', this.handleCloseAll);
      } else {
        this.handleHoverOut();
      }
    },

    handleParentScroll() {
      this.handleHoverOut();
    },

    handleCloseAll(e) {
      const el = this.$refs.wrapper;

      const { target } = e;

      if (el !== target && !el.contains(target)) {
        this.isOpen = false;

        document.removeEventListener('click', this.handleCloseAll);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pb-tooltip {
  position: relative;
  display: inline-block;
  line-height: inherit;
  cursor: help;

  &__content-wrapper {
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 0;
    right: auto;
    z-index: 990;
    padding: rem(6) 0;
    opacity: 0;
    transform: translate3d(0, #{rem(4)}, 0);
    visibility: hidden;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0.2s;
  }

  [dir="rtl"] &__content-wrapper {
    right: 0;
    left: unset;
  }

  &.is-bottom &__content-wrapper {
    top: 100%;
    bottom: auto;
    transform: translate3d(0, #{rem(-4)}, 0);
  }

  &.is-left &__content-wrapper {
    right: 0;
    left: auto;
  }

  [dir="rtl"] &.is-left &__content-wrapper {
    left: 0;
    right: auto;
  }

  &.is-fixed &__content-wrapper {
    position: fixed;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  &.is-open &__content-wrapper {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0s;
  }

  &__content {
    position: relative;
    background: $black;
    opacity: 0.8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: rem(6);
    padding: rem(12);
    color: $white;
    width: rem(224);
    max-width: 80vw;
    font-size: rem(12);
    line-height: 1.33;
    text-align: left;
  }

  &__content a {
    border-bottom: 1px solid rgba($white, 0.3);
    transition: border-color 0.2s linear;
  }

  &__content a:hover {
    border-color: $black-06;
  }
}
</style>
