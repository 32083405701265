<template>
  <Transition name="toast">
    <div
      v-if="isPaymentFailed"
      class="widget-content-toast widget-content-toast--error"
      @click="onClose"
    >
      <template v-if="isPaymentProcessorNotFound">
        {{ $t('widget.errors.try-another-card') }}
      </template>
      <template v-else-if="payoutErrorMessage">
        {{ payoutErrorMessage }}
      </template>
      <template v-else>
        {{ $t('widget.errors.card-charging-fail') }}
      </template>

      <div class="widget-content-toast__close btn-close">
        <span class="svg-icon icon-close-red widget-content-toast__close-icon" />
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WidgetContentToast',

  computed: {
    ...mapGetters('external', [
      'isPaymentFailed',
    ]),
    ...mapGetters(['isPaymentProcessorNotFound']),
    ...mapGetters('transaction', ['payoutErrorMessage']),
  },

  methods: {
    ...mapActions('external', ['resetFunnelCardErrorState']),

    onClose() {
      this.resetFunnelCardErrorState();
    },
  },
};
</script>

<style lang="scss" scoped>
$toast-height: 58px;
$toast-y-padding: 10px;

@keyframes toast-slide-down {
  0% {
    height: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    height: $toast-height;
    padding-top: $toast-y-padding;
    padding-bottom: $toast-y-padding;
  }
}

.toast-enter-active {
  animation: toast-slide-down .15s ease-out;
}
.toast-leave-active {
  animation: toast-slide-down .15s ease-out reverse;
}

.widget-content-toast {
  display: flex;
  padding: 10px 36px;
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.29;
  text-align: center;
  position: relative;
  height: $toast-height;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &--success {
    color: $green;
    background-color: rgba($green, .1);
  }

  &--error {
    color: $red;
    background-color: rgba($red, .1);
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  &__close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
}
</style>
