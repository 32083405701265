<template>
  <div
    class="widget-wrapper"
    :class="{
      'widget-hidden': !isAnyRouteLoaded,
      'widget-wrapper--animated': isAnyRouteLoaded,
    }"
  >
    <!-- The second element needs to make right distance of element to not owerlaping content that we have, -->
    <!-- because initial element is fixed -->
    <template v-if="isUKUser">
      <FCANotificationBanner
        fixed
        @show-modal="showFCANotificationPopupModal"
      />
      <FCANotificationBanner />
    </template>

    <WidgetHeader @close-widget="toggleCloseConfirmModal" />

    <div class="widget-wrapper__base">
      <div
        class="widget-wrapper__body"
        :style="widgetWrapperAlignment"
      >
        <div
          v-if="!showLeftSidebar"
          class="widget-wrapper__partner-info fade-out-animated"
        >
          <WidgetPartnerInfo v-if="!hideTransactionDetails" />
        </div>

        <div
          class="widget-wrapper__screen fade-out-animated"
          :class="{ 'widget-wrapper__screen--without-sidebar': showLeftSidebar }"
        >
          <WidgetContentArea
            class="widget-wrapper__content-area"
            @is-widget-loaded="onWidgetLoaded"
          />

          <ExchangeRateExpiration
            v-if="!isTransactionInFinalStatus && !hideTransactionDetails"
            class="widget-wrapper__exchange-rate"
          />

          <ZhDisclaimer
            v-if="flowDetails.isZerohashPayoutStrategy()"
            skin="mobile"
          />
        </div>
      </div>
    </div>

    <WidgetFooter class="widget-wrapper__footer" />
  </div>

  <Modal
    :show="isCloseConfirmVisible"
    :component="$options.modals.CloseModal"
    @confirm="closeWidget"
    @reject="toggleCloseConfirmModal"
  />

  <Modal
    :show="isVerificationCancelModalVisible"
    :component="$options.modals.VerificationCancelModal"
    @confirm="cancelTransaction"
    @reject="toggleCancelTransactionModal"
  />

  <Modal
    :show="isApplePayNotificationVisible"
    :component="$options.modals.ApplePayNotificationModal"
    @confirm="closeWidget"
    @reject="toggleApplePayNotificationModal"
  />

  <Modal
    :show="isFCANotificationPopup"
    :component="$options.modals.FCANotificationPopup"
    :isWithoutBlur="true"
    @reject="hideFCANotificationPopupModal"
  />

  <Modal
    :show="isTransactionCryptocurrencyIsNotAvailableModalVisible"
    :component="$options.modals.TransactionCryptocurrencyIsNotAvailableModal"
    @confirm="closeWidget"
  />

  <Modal
    :show="isTransactionValidationErrorModalVisible"
    :component="$options.modals.TransactionValidationErrorModal"
    @confirm="closeWidget"
  />

  <Modal
    :show="isBannedUserModalVisible"
    :component="$options.modals.BannedUserModal"
    @confirm="closeWidget"
  />
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import WidgetFooter from '@/components/WidgetFooter';
import WidgetContentArea from '@/components/WidgetContentArea';
import WidgetPartnerInfo from '@/components/WidgetPartnerInfo';
import WidgetHeader from '@/components/WidgetHeader';
import FCANotificationBanner from '@/components/WidgetFCANotificationBanner';
import ExchangeRateExpiration from '@/components/ExchangeRateExpiration';
import { useIsTransactionInFinalStatus } from '@/composables/transactionStatus';
import {
  Modal,
  CloseModal,
  VerificationCancelModal,
  FCANotificationPopup,
  TransactionCryptocurrencyIsNotAvailableModal,
  TransactionValidationErrorModal,
  BannedUserModal,
  ApplePayNotificationModal,
} from '@/components/Modal';

import { emitToParent, STATES } from '@/services/widgetApiEvents';
import { onBackButtonEvent } from '@/composables/backButtonEvents';
import {
  shouldChangeAppearanceForError as showLeftSidebar,
  isErrorRoute as hideTransactionDetails,
  widgetWrapperAlignment,
} from '@/composables/errorScreenAppearance';

import { useRoutePreloader } from '@/router/PreloadRouterView';
import { canClose } from '@/services/widgetSettings';
import ZhDisclaimer from '../ZhDisclosure/ZhDisclaimer';

export default {
  name: 'WidgetWrapper',

  modals: {
    CloseModal,
    BannedUserModal,
    VerificationCancelModal,
    TransactionCryptocurrencyIsNotAvailableModal,
    TransactionValidationErrorModal,
    ApplePayNotificationModal,
    FCANotificationPopup,
  },

  components: {
    ZhDisclaimer,
    WidgetFooter,
    WidgetHeader,
    FCANotificationBanner,
    WidgetContentArea,
    WidgetPartnerInfo,

    Modal,

    ExchangeRateExpiration,
  },
  setup() {
    const store = useStore();
    const { loadedRoutes } = useRoutePreloader();

    const isCloseConfirmVisible = ref(false);

    const isVerificationCancelModalVisible = computed(
      () => store.getters['transaction/showTransactionCancellationPopup'],
    );
    const isTransactionCryptocurrencyIsNotAvailableModalVisible = computed(
      () => store.getters.showTransactionCryptocurrencyIsNotAvailablePopup,
    );
    const isTransactionValidationErrorModalVisible = computed(
      () => store.getters.showTransactionValidationErrorPopup,
    );
    const isBannedUserModalVisible = computed(
      () => store.getters.showBannedUserPopup,
    );
    const isFCANotificationPopup = computed(
      () => store.getters.showFCANotificationPopup,
    );
    const isApplePayNotificationVisible = computed(
      () => store.getters.showApplePayNotificationPopup,
    );

    const isCoinmamaTheme = computed(() => store.getters.themeConfig?.serviceName === 'Coinmama');

    const toggleCloseConfirmModal = () => {
      isCloseConfirmVisible.value = !isCloseConfirmVisible.value;
    };
    const toggleApplePayNotificationModal = () => {
      store.dispatch('hideApplePayNotificationPopup');
    };
    const hideFCANotificationPopupModal = () => {
      store.dispatch('hideFCANotificationPopup');
      document.body.style.overflow = '';
    };
    const showFCANotificationPopupModal = () => {
      store.dispatch('showFCANotificationPopup');
      document.body.style.overflow = 'hidden';
    };
    const toggleCancelTransactionModal = () => {
      store.commit('transaction/toggleTransactionCancellationPopup');
    };

    const closeWidget = () => {
      toggleCloseConfirmModal();
      emitToParent('state', {
        state: STATES.STATE_CLOSED,
      });
    };

    const cancelTransaction = async () => {
      store.commit('transaction/toggleTransactionCancellationPopup');
      store.commit('lock');

      await store.dispatch('transaction/cancelTransaction');

      store.commit('unlock');
    };

    const isTransactionInFinalStatus = useIsTransactionInFinalStatus();

    onBackButtonEvent(() => {
      if (!canClose()) {
        return;
      }
      if (!isCloseConfirmVisible.value) {
        toggleCloseConfirmModal();
      }
    });

    const isAnyRouteLoaded = computed(
      () => Object.entries(loadedRoutes).filter(
        ([, inLoadedState]) => inLoadedState,
      ).length > 0,
    );

    watch(
      isAnyRouteLoaded,
      newValue => {
        if (newValue) {
          window.dispatchEvent(new Event('pb-widget-loaded'));

          emitToParent('state', {
            state: 'loaded',
          });

          if (isCoinmamaTheme.value) {
            emitToParent('loader-styles-change', {
              backgroundColor: '#eeeeee',
            });
          }
        }
      },
      { immediate: true },
    );

    const flowDetails = computed(() => store.getters['transaction/flowDetails']);

    const FCA_COUNTRY_CODE_LIST = ['UK', 'GB'];
    const isUKUser = computed(() => {
      const verifiedCountryCode = store.getters.client.country;
      if (verifiedCountryCode) {
        return FCA_COUNTRY_CODE_LIST.includes(verifiedCountryCode);
      }

      return FCA_COUNTRY_CODE_LIST.includes(store.getters.customerCountryCode);
    });

    return {
      isCloseConfirmVisible,
      isApplePayNotificationVisible,
      isFCANotificationPopup,
      toggleCloseConfirmModal,
      closeWidget,

      isAnyRouteLoaded,

      isTransactionInFinalStatus,
      isVerificationCancelModalVisible,
      isTransactionCryptocurrencyIsNotAvailableModalVisible,
      isTransactionValidationErrorModalVisible,
      isBannedUserModalVisible,
      isUKUser,
      toggleCancelTransactionModal,
      toggleApplePayNotificationModal,
      hideFCANotificationPopupModal,
      showFCANotificationPopupModal,
      cancelTransaction,

      hideTransactionDetails,
      showLeftSidebar,
      widgetWrapperAlignment,
      flowDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
$simpleFadeBg: simpleFadeBg 1s both ease-in-out;

// Animate blocks only when wrapper was animated
.fade-out-animated {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 800ms ease, opacity 800ms ease;
}

.widget-wrapper--animated .fade-out-animated {
  opacity: 1;
  transform: translateY(0);
}

.widget-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 320px;
  min-height: 100vh;
  position: relative;
  background: #000;
  z-index: 1;
  transition: opacity 0.1s linear;
  overflow: hidden;

  &__base {
    flex-grow: 1;
  }

  @media (max-width: 450px) {
    min-height: -webkit-fill-available;
  }

  &.widget-hidden {
    opacity: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: all .3s ease-in-out;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.001);
    z-index: -2;
  }

  // bg animation
  &--animated {
    &::before, &::after {
      animation: $simpleFadeBg;
    }
  }
}

.widget-wrapper__body {
  display: flex;
  align-items: stretch;

  @media (max-width: $tablet-max) {
    display: block;
  }
}

.widget-wrapper__screen {
  flex: 1;

  &--without-sidebar {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @media (max-width: $tablet-max) {
    padding: 0 12px;
  }
}

.widget-wrapper__content-area {
  margin: 0 50px;

  @media (min-width: $laptop-min) {
    display: flex;
    flex-direction: column;
    min-height: 538px;
  }

  @media (min-width: $desktop-min) {
    margin-right: 80px;
  }

  @media (max-width: $tablet-max) {
    max-width: 608px;
    margin: 28px auto 0;
  }
}

.widget-wrapper__partner-info {
  width: 50%;

  @media (max-width: $laptop-max) {
    width: 45%;
  }

  @media (max-width: $tablet-max) {
    display: block;
    width: auto;
  }
}

.widget-wrapper__exchange-rate {
  margin: 24px 0;

  display: block;
  text-align: center;

  @media (min-width: $tablet-max) {
    display: none;
  }
}

.full-screen-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);

  .loading-icon {
    top: 30%;

    @media (max-width: $tablet-max) {
      &::after {
        background-color: #fff;
      }
    }
  }
}

// animations
@keyframes fadeOutBlocks {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes simpleFadeBg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
