import getParams from '@/utils/getParams';
import { computed } from 'vue';
// eslint-disable-next-line import/no-cycle
import { EXCHANGE_FORM_V2, PAYMENT_METHODS_V2 } from '@/router/routes';

const { indexPage: queryParamIndexPage } = getParams();

const PAYMENT_METHOD_LIST = 'paymentMethodList';

export const useIndexPage = () => {
  const indexPage = computed(() => {
    const isPaymentMethodList = queryParamIndexPage === PAYMENT_METHOD_LIST;
    if (isPaymentMethodList) {
      return PAYMENT_METHODS_V2;
    }

    return EXCHANGE_FORM_V2;
  });

  return {
    indexPage,
  };
};
