import cookieStore from './cookie';

const STORAGE_KEY = 'x-user-locale';

function isSessionStorageAvailable() {
  let storage;
  try {
    storage = window.sessionStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    if (cookieStore === null) {
      initCookieStorage();
    }

    return e instanceof DOMException && (
    // everything except Firefox
      e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      && (storage && storage.length !== 0);
  }
}

export default {
  storeLocale(locale) {
    if (!isSessionStorageAvailable()) {
      return cookieStore.set(STORAGE_KEY, locale);
    }

    sessionStorage.setItem(STORAGE_KEY, locale);
  },

  getLocale() {
    if (!isSessionStorageAvailable()) {
      return cookieStore.get(STORAGE_KEY);
    }

    return sessionStorage.getItem(STORAGE_KEY);
  },
};
