import client from '../network/clients/authentication';
import { AuthError, TooEarlyError } from '../models/auth-error';

const post = async (path, postBody) => {
  try {
    const { data } = await client.post(path, postBody);

    return data;
  } catch ({ response }) {
    const { status } = response;
    if (status === 425) {
      throw new TooEarlyError(response);
    }
    throw new AuthError(response);
  }
};

export default {
  async login(login, password, reCaptcha) {
    return await post('/authentication/legacy-login/', {
      login,
      password,
      reCaptcha,
    });
  },

  async requestPasswordRecovery(email, locale) {
    return await post('/authentication/legacy-forgot/', {
      email,
      locale,
    });
  },
};
