<template>
  <button
    class="google-pay-button-with-text btn btn-primary btn-lg"
    :lang="lang"
    :disabled="disabled"
    @click.prevent="payWithGooglePay"
  >
    <span class="google-pay-button-with-text__image">
      <img
        alt="Google pay"
        :src="icon"
      >
    </span>
  </button>
</template>

<script>
import { isWebView, isWebViewUA } from '@paybis/frontend-common-lib/src/services/web-view-service';
import { UAParser } from 'ua-parser-js';

import googlePayIcon from '@paybis/frontend-common-lib/src/assets/images/google-pay.svg';
import payWithGooglePayIcon from '@paybis/frontend-common-lib/src/assets/images/pay-with-google-pay.svg';
import { isElectron } from '../../../../src/utils/detect';

export default {
  name: 'GooglePayButton',
  props: {
    lang: { type: String, required: true },
    paymentDataRequest: { type: Object, required: true, default: () => ({}) },
    disabled: { type: Boolean, required: false, default: false },
  },
  computed: {
    icon() {
      return googlePayIcon;
    },
  },
  methods: {
    payWithGooglePay() {
      if (isElectron() || isWebView() || isWebViewUA(window.navigator.userAgent)) {
        this.$emit('pay', { showPopupPaymentNotPossible: true });
        return;
      }

      const dataRequest = this.paymentDataRequest;

      const PaymentDataRequest = JSON.parse(JSON.stringify(dataRequest));
      const PaymentsClient = new google.payments.api.PaymentsClient({
        environment: process.env.VUE_APP_GOOGLE_PAY_ENV,
      });

      PaymentsClient.loadPaymentData(PaymentDataRequest)
        .then(paymentData => {
          const {
            paymentMethodData: { info, tokenizationData },
          } = paymentData;
          const { token: paymentToken } = tokenizationData;
          const { billingAddress = {} } = info;
          const { name = '' } = billingAddress;

          this.emitPayEvent(paymentToken, name);
        })
        .catch(error => {
          this.handleGooglePayError(error);
        });
    },
    emitPayEvent(paymentToken, cardholderName) {
      this.$emit('pay', {
        paymentToken: JSON.parse(paymentToken),
        cardholderName,
      });
    },
    handleGooglePayError(error) {
      this.$emit('error', { error });
    },
  },
};
</script>
<style scoped lang="scss">
.google-pay-button-with-text {
  cursor: pointer;
  --google-pay-scale: 1;
  display: flex;
  align-items: center;

  &__image {
    display: flex;
    align-items: center;

    & > img {
      display: flex;
      height: rem(20);
    }
  }

  & > .text {
    margin-right: calc(2px * var(--google-pay-scale));
  }

  &--android {
    border-radius: 2rem;
  }
}
</style>
