import { CURRENCIES } from './mutations';
// eslint-disable-next-line import/no-cycle
import WidgetService from '@/services/widget/widgetService.v2';
import { AppCriticalError } from '@/exceptions/AppCriticalError';
import { getStandaloneParams, hasParams } from '@/services/widgetSettings';
import { isV2 } from '@/services/apiVersion';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

const popularCurrenciesDefault = {
  from: ['USD', 'EUR', 'GBP'],
  to: [
    'BTC',
    'BTC-TESTNET',
    'ETH',
    'ETH-ROPSTEN',
    'USDT',
    'USDT-TRC20',
    'USDC',
    'BNBSC',
    'BNBSC-TESTNET',
    'EUSD',
    'EUSD-TESTNET',
  ],
};

const getCurrentDirectionByFlow = isSellCryptoFlow => {
  if (isSellCryptoFlow) {
    return 'to';
  }

  return 'from';
};

const getExchangeCurrencies = (requestId, isSellCryptoFlow) => {
  if (isSellCryptoFlow) {
    return WidgetService.getExchangeCurrenciesForSell(requestId);
  }

  return WidgetService.getExchangeCurrenciesForBuy(requestId);
};

export default {
  namespaced: false,

  state: {
    raw: {
      data: [],
      meta: {},
    },
    currencyPairs: {},
    isLoading: false,
    isLoaded: false,
    fromDisplayName: null,
  },

  getters: {
    // eslint-disable-next-line max-len
    currencyPairsByPaymentMethod: ({ raw: { data } }) => paymentMethod => data.find(item => item.name === paymentMethod),
    allFiatCurrencies: ({ raw: { data } }, getters, rootState, rootGetters) => {
      const result = [];
      const usedCurrencies = new Set();

      const addToResult = fiatAssetId => {
        if (usedCurrencies.has(fiatAssetId)) {
          return;
        }

        usedCurrencies.add(fiatAssetId);
        result.push({
          from: fiatAssetId,
          // XXX: we need keep both directions for simplicity
          // @see PaymentMethodsScreen.vue:121
          to: fiatAssetId,
        });
      };

      data.forEach(item => {
        item.pairs.forEach(pair => {
          if (rootGetters.isSellCryptoFlow) {
            pair.to.forEach(fiatAssetId => addToResult(fiatAssetId));
          } else {
            addToResult(pair.from || pair.fromAssetId);
          }
        });
      });

      return result;
    },
    allCurrencies: (state, getters, rootState, rootGetters) => {
      const pairHashes = Object.keys(state.currencyPairs);

      let mappedPairs = pairHashes
        .map(pairHash => pairHash.split(':'))
        .map(([from, to]) => ({
          from,
          to,
          details: state.currencyPairs[`${from}:${to}`],
        }));

      // Needed to sort values after Object.keys: fixes the PD-16576 issue
      if (rootGetters.isSellCryptoFlow) {
        const popularCryptoCurrencies = mappedPairs.filter(pair => popularCurrenciesDefault.to.includes(pair.from));
        const othersCryptoCurrencies = mappedPairs.filter(pair => !popularCurrenciesDefault.to.includes(pair.from));

        mappedPairs = [...popularCryptoCurrencies, ...othersCryptoCurrencies];
      }

      return mappedPairs;
    },
    allCurrenciesWithPair: (state, getters) => pairCurrency => {
      const { allCurrencies } = getters;
      // TODO: for simplisity assume that we can transfer only FIAT to CRYPTO. No currencies swap!
      return allCurrencies
        .filter(currency => currency.from === pairCurrency || currency.to === pairCurrency);
    },
    popularCurrenciesTo: (state, getters) => pairCurrency => getters.popularCurrencies(pairCurrency, 'to'),
    popularCurrenciesFrom: (state, getters) => pairCurrency => getters.popularCurrencies(pairCurrency, 'from'),
    popularCurrencies: (state, getters, globalState, globalGetters) => (pairCurrency, direction) => {
      const oppositeDirection = direction === 'from' ? 'to' : 'from';
      const popularCurrenciesDirection = globalGetters.isSellCryptoFlow ? oppositeDirection : direction;
      const popularCurrencies = popularCurrenciesDefault[popularCurrenciesDirection];
      const isFiat = globalGetters.isSellCryptoFlow ? direction === 'to' : direction === 'from';

      const allCurrenciesWithPair = isFiat ? getters.allFiatCurrencies : getters.allCurrenciesWithPair(pairCurrency);
      const allCurrenciesMap = new Map(
        allCurrenciesWithPair.map(currency => [currency[direction], currency]),
      );

      const sortedPopularCurrencies = [];
      popularCurrencies.forEach(popularCurrency => {
        if (allCurrenciesMap.has(popularCurrency)) {
          sortedPopularCurrencies.push(allCurrenciesMap.get(popularCurrency));
        }
      });

      return sortedPopularCurrencies;
    },
    popularCurrenciesWithPair: (state, getters, globalState, globalGetters) => pairCurrency => {
      const defaultCurrencyDirection = getCurrentDirectionByFlow(globalGetters.isSellCryptoFlow);
      const oppositeDirection = defaultCurrencyDirection === 'from' ? 'to' : 'from';

      const fiatPopular = Object.entries(state.currencyPairs)
        .filter(([hash]) => hash.endsWith(`:${pairCurrency}`))
        .map(entry => entry[1])
        .filter(value => popularCurrenciesDefault[defaultCurrencyDirection].includes(value.from))
        .map(({ from, to, ...details }) => ({ from, to, details }));

      if (fiatPopular.length > 0) {
        return fiatPopular;
      }

      return Object.entries(state.currencyPairs)
        .filter(([hash]) => hash.startsWith(`${pairCurrency}:`))
        .map(entry => entry[1])
        .filter(value => popularCurrenciesDefault[oppositeDirection].includes(value.to))
        .map(({ from, to, ...details }) => ({ from, to, details }))
        .sort((a, b) => {
          const indexOf = str => popularCurrenciesDefault[oppositeDirection].indexOf(str);
          return indexOf(a.to) - indexOf(b.to);
        });
    },
    getCurrencyBy: state => currencyCode => {
      const [, pair] = Object.entries(state.currencyPairs)
        .find(([key]) => key.endsWith(`:${currencyCode}`)) || [];
      if (!pair) {
        return currencyCode;
      }

      return pair.toCurrency;
    },
    fromDisplayName: state => state.fromDisplayName,
    getBlockchainNameByCurrencyCode: state => currencyCode => {
      const currencies = state.raw.meta.currencies || [];
      if (currencies.length === 0) {
        return null;
      }

      const { blockchainName = null } = currencies.find(({ code }) => code === currencyCode)
      || currencies.find(({ code }) => code.startsWith(currencyCode))
      || {};

      return blockchainName;
    },
    isLoaded: state => state.isLoaded,
  },

  mutations: {
    [CURRENCIES.SET_CURRENCY_PAIRS]: (state, currencies) => {
      state.currencyPairs = currencies;
    },
    [CURRENCIES.SET_IS_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    [CURRENCIES.SET_FROM_DISPLAY_NAME]: (state, fromDisplayName) => {
      state.fromDisplayName = fromDisplayName;
    },
    setRaw: (state, payload) => {
      state.raw = payload;
    },
    setIsLoaded: state => {
      state.isLoaded = true;
    },
  },

  actions: {
    async init({ commit, dispatch, rootGetters, getters }, requestId) {
      const { isSellCryptoFlow, isRequestWithQuote } = rootGetters;

      commit(CURRENCIES.SET_IS_LOADING, true);
      try {
        const { data, meta } = await getExchangeCurrencies(requestId, isSellCryptoFlow);

        commit('setRaw', { data, meta });

        // XXX: try to use default by displayName: Credit/Debit Card
        const paymentMethod = data.find(item => item.displayName === 'Credit/Debit Card')?.name || data[0].name;
        dispatch('setCurrencyPairsByPaymentMethod', { paymentMethod }); // set default on init
        if (!isRequestWithQuote) {
          if (isSellCryptoFlow) {
            const currencyFrom = popularCurrenciesDefault.to.find(currency => meta.currencies.find(({ code }) => code === currency));
            dispatch('setCurrencyCodeFrom', currencyFrom);

            const supportedCurrencyPairs = getters.popularCurrenciesWithPair(currencyFrom);
            const currencyTo = supportedCurrencyPairs.find(({ to }) => meta.defaultCurrency === to)?.to || supportedCurrencyPairs?.[0].to;
            dispatch('setCurrencyCodeTo', currencyTo);
          } else {
            const popularCurrenciesFrom = popularCurrenciesDefault.from.find(currency => meta.currencies.find(({ code }) => code === currency));
            const currencyFrom = rootGetters['exchangeForm/allCurrencies']
              .find(currency => currency.from === meta.defaultCurrency)?.from ?? popularCurrenciesFrom;
            dispatch('setCurrencyCodeFrom', currencyFrom || rootGetters['exchangeForm/allCurrencies'][0]?.from);
            const currencies = rootGetters['exchangeForm/allCurrenciesWithPair'](currencyFrom);
            const [
              firstPopularCurrencyPair = {},
            ] = rootGetters['exchangeForm/popularCurrenciesWithPair'](currencyFrom);
            const predefinedCurrency = currencies.find(({ to }) => to === firstPopularCurrencyPair.to);
            dispatch('setCurrencyCodeTo', predefinedCurrency?.to || currencies[0]?.to);
          }
        }

        if (isV2() && !isNewExchangeFormFlowEnabled()) {
          dispatch('v2/paymentMethods/getMethods', null, {
            root: true,
          });
        }

        if (hasParams()) {
          dispatch('setStandaloneParams', getStandaloneParams());
        }
      } catch (error) {
        throw new AppCriticalError(error.message);
      } finally {
        commit(CURRENCIES.SET_IS_LOADING, false);
        commit('setIsLoaded');
      }
    },
    setCurrencyPairsByPaymentMethod({ commit, getters, dispatch }, { paymentMethod }) {
      const currencyPairs = getters.currencyPairsByPaymentMethod(paymentMethod);
      if (!currencyPairs) {
        return;
      }

      const { displayName, pairs } = currencyPairs;

      dispatch('setFromDisplayName', displayName);
      commit(CURRENCIES.SET_CURRENCY_PAIRS, groupCurrencyPairs(pairs));
    },
    setFromDisplayName({ commit }, payload) {
      commit(CURRENCIES.SET_FROM_DISPLAY_NAME, payload);
    },
  },
};

function groupCurrencyPairs(currencies) {
  const pairs = {};
  currencies.forEach(currency => {
    const from = currency.from || currency.fromAssetId;
    const toCurrencies = currency.to;

    toCurrencies.forEach(toCurrency => {
      const isScalarCurrency = typeof toCurrency === 'string';
      const to = isScalarCurrency ? toCurrency : toCurrency.currencyCode;
      pairs[`${from}:${to}`] = {
        from,
        to,
        toCurrency: isScalarCurrency ? toCurrency : toCurrency.currency,
      };
    });
  });

  return pairs;
}
