import { ref } from 'vue';

let isLoaded = false;
export const nSureDeviceId = ref(null);

export function loadNSureScript({ apiKey, appId, nSurePartnerId: partnerId }) {
  if (isLoaded) {
    return;
  }

  // BC: remove in next releases. leave only appId parameter
  const nSureAppId = appId || apiKey;

  window.nSureAsyncInit = deviceId => {
    nSureDeviceId.value = deviceId;

    if (partnerId !== '') {
      window.nSureSDK.init(nSureAppId, partnerId);

      return;
    }

    window.nSureSDK.init(nSureAppId);
  };

  const script = document.createElement('script');
  script.src = process.env.VUE_APP_NSURE_SCRIPT_URL;
  script.async = true;
  document.body.appendChild(script);
  isLoaded = true;
}
