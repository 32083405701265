import engine from 'store'
import storages from 'store/storages/all';
import expirePlugin from 'store/plugins/expire';

const storage = engine.createStore(storages, [expirePlugin]);

export const StorageService = {
  get(key) {
    return storage.get(key);
  },
  set(key, data, options) {
    return storage.set(key, data, options);
  },
  remove(key, options) {
    return storage.remove(key, options);
  },
};
