<template>
  <span>
    <template v-if="isSumsubVerificationPending">
      <SumsubWidget
        :card="selectedCard"
        :invoice="invoice"
        @verificationCompleted="verificationCompleted"
        @verificationError="verificationError"
        @verificationCancelled="verificationCancelled"
      />
    </template>
    <template v-else-if="isChallenge">
      <PaymentStatusWaiting v-if="showPaymentStatusWaiting" />
    </template>
    <template v-else-if="showPaymentStatusWaiting">
      <PaymentStatusWaiting />
    </template>
    <template v-else-if="isPaymentInProgress">
      <ChallengeWaiting :counter="counter" />
    </template>
    <template v-else>
      <Title
        v-if="!canShowAddCardForm"
        data-testid="payment-title"
      >
        {{ $t('transaction-flow.steps.payment-details.title') }}
      </Title>
      <Content>
        <FormRow v-if="!canShowAddCardForm">
          <Loader
            v-if="isLoading"
            class="mt-32 mb-32 ml-auto mr-auto "
          />
          <CardSelect
            v-else
            :cards="cards"
            :selected="selectedCardId"
            :has-apple-pay="hasApplePay"
            :has-google-pay="hasGooglePay"
            :user-country="userCountry"
            :google-payment-data-request="googlePaymentDataRequest"
            @selectCard="handleSelectCard"
            @applePay="payWithApplePay"
            @googlePay="payWithGooglePay"
            @applePayError="handleApplePayError"
            @googlePayError="handleGooglePayError"
            @pay="handlePayWithCard"
            @deleteCard="handleDeleteCard"
          />
        </FormRow>

        <div
          v-if="canShowAddCardForm"
          class="card-payment-drawer"
        >
          <Icon
            v-if="isAvailablePayment"
            class="card-payment-drawer__icon"
            icon="arrow-left"
            @click.native="setSelectedCard(null)"
          />
          <Title
            data-testid="payment-title"
            class="card-payment-drawer__title"
          >
            {{ $t('transaction-flow.steps.payment-details.new_card_title') }}
          </Title>
          <FormRow double-margin>
            <CardForm
              ref="cardForm"
              :fields="addCard.getFormFields()"
              :billing-address-fields="addCard.billingAddress.getFormFields()"
              :invoice="invoice"
              @created="createCardHandler"
              @error="createCardErrorHandler"
            />
          </FormRow>
          <Actions :key="canShowAddCardForm">
            <template
              v-if="canShowAddCardForm"
              #submit
            >
              <button
                class="btn btn-lg btn-primary"
                :class="{
                  'is-loading': isLocked,
                }"
                :disabled="isLocked"
                data-testid="pay-button"
                @click="createCardAndPay"
              >
                {{ $t('shared.navigation.button.pay') }}
              </button>
            </template>
            <template
              v-if="canShowAddCardForm"
              #accessory
            >
              <SecureStamp />
            </template>
          </Actions>
        </div>

        <Actions
          v-if="canShowLowerCancelButton"
          :key="canShowAddCardForm"
          :cancel-button-default-class="true"
        />
        <RefundDisclaimer v-if="!isLoading" />
      </Content>
    </template>
    <PopupPaymentNotPossible
      v-if="hasPopUpNotPossible"
      :show="showPopupPaymentNotPossible"
      :payment-name="notPossiblePaymentName"
      @close="showPopupPaymentNotPossible = false"
    />
  </span>
</template>

<script>
import Loader from '@paybis/frontend-common-lib/src/components/loader';
import Content from '@paybis/frontend-common-lib/src/components/content';
import FormRow from '@paybis/frontend-common-lib/src/components/form/form-row';
import Title from '@paybis/frontend-common-lib/src/components/title';
import { PaymentProcessorFactory } from '@paybis/frontend-common-lib/src/payment-processor/PaymentProcessorFactory';
import ChallengeFormFactory from '@paybis/frontend-common-lib/src/payment-processor/worldpay/ChallengeFormFactory';
import DDCFormFactory from '@paybis/frontend-common-lib/src/payment-processor/worldpay/DDCFormFactory';
import DDCBridgerPayIFrameFactory from '@paybis/frontend-common-lib/src/payment-processor/bridgerpay/DDCBridgerPayIFrameFactory';
import { captureException, captureMessage } from '@paybis/frontend-common-lib/src/plugins/sentry';
import CardClient from '@paybis/frontend-common-lib/src/services/clients/card-client';
import CardPaymentClient from '@paybis/frontend-common-lib/src/services/clients/card-payment-client';
import { getLocaleByLanguage } from '@paybis/frontend-common-lib/src/services/util';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Storage from '@paybis/frontend-common-lib/src/services/storage';
import { UAParser } from 'ua-parser-js';
import { isWebView } from '@paybis/frontend-common-lib/src/services/web-view-service';

import FormFactory from '@paybis/frontend-common-lib/src/factories/FormFactory';
import Icon from '@paybis/frontend-common-lib/src/components/icon';
import Actions from './actions';
import CardForm from './card-form';
import CardSelect from './card-select';
import ChallengeWaiting from './challenge-waiting';
import PaymentStatusWaiting from './payment-status-waiting';
import SecureStamp from './secure-stamp';
import SumsubWidget from './sumsub-widget';
import RefundDisclaimer from './refund-disclaimer';

import eventBus from '../helpers/eventBus';
import inIframe from '../utils/iframe';
import { isElectron } from '../../../../src/utils/detect';
import PopupPaymentNotPossible from './popup-payment-not-possible';
import { PAYMENT_METHODS } from '../../../../src/constants/paymentMethods';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    PopupPaymentNotPossible,
    RefundDisclaimer,
    PaymentStatusWaiting,
    SumsubWidget,
    Title,
    Content,
    FormRow,
    CardSelect,
    CardForm,
    Actions,
    SecureStamp,
    Loader,
    ChallengeWaiting,
    Icon,
  },
  props: {
    invoice: { type: String, required: true },
    transaction: { type: Object, required: true },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
      loadingCards: false,
      isPaymentInProgress: false,
      counter: 0,
      getCardStatusTimeout: null,
      getPaymentStatusTimeout: null,
      showPaymentStatusWaiting: false,
      googlePaymentToken: null,
      bridgerPayDdcTimeoutId: null,
      showPopupPaymentNotPossible: false,
      notPossiblePaymentName: null,
    };
  },
  computed: {
    ...mapGetters('card', [
      'cards',
      'selectedCardId',
      'addCard',
      'selectedCard',
      'isChallenge',
      'isActivePayment',
      'isApplePay',
      'isGooglePay',
      'applePaySettings',
      'googlePaySettings',
      'isCardsLoaded',
    ]),
    ...mapGetters('cardSumsub', [
      'sumsubApplicant',
      'isSumsubVerificationPending',
    ]),
    ...mapGetters('quote', ['paymentAmount']),
    ...mapGetters('applePay', [
      'initiatingApplePay',
      'canUseApplePay',
      'userCountry',
    ]),
    ...mapGetters('googlePay', [
      'initiatingGooglePay',
      'canUseGooglePay',
      'googlePaymentDataRequest',
    ]),
    ...mapGetters('feature', [
      'cardEntryIFrameAppFeature',
      'cardEntryIFrameAppFeature',
    ]),
    ...mapGetters(['isLocked', 'language', 'requestId']),
    ...mapGetters('settings', ['isWidget']),
    isAvailablePayment() {
      return (
        !this.isLoading
        && (this.cards.length > 0 || this.canUseApplePay || this.canUseGooglePay)
      );
    },
    isApplePayAvailable() {
      return (
        !inIframe()
        && !isElectron()
        && !isWebView()
        && this.isApplePaySession
        && ApplePaySession.supportsVersion(this.applePaySettings.version)
        && ApplePaySession.canMakePayments()
      );
    },
    isSafari() {
      return ['Mobile Safari', 'Safari'].includes(UAParser().browser.name);
    },
    isGooglePayAvailable() {
      return (
        !isElectron()
        && !isWebView()
        && !this.isSafari
      );
    },
    isApplePaySession() {
      return (window && window.ApplePaySession) || false;
    },
    isPaymentMethodSelect() {
      return this.isApplePay || this.isGooglePay;
    },
    canShowAddCardForm() {
      // XXX: cant use selectedCard.isNew() method
      // @see card store selectedCard getter.
      return (
        !this.isPaymentMethodSelect
        && !this.isLoading
        && this.selectedCardId === 'new'
      );
    },
    canShowLowerCancelButton() {
      return this.isPaymentMethodSelect || this.selectedCardId !== 'new';
    },
    browserLanguage() {
      return getLocaleByLanguage(this.language);
    },
    isLoading() {
      return (
        this.loadingCards || this.initiatingApplePay || this.initiatingGooglePay
      );
    },
    hasApplePay() {
      return isElectron() || isWebView() || this.canUseApplePay;
    },
    hasGooglePay() {
      return isElectron() || isWebView() || this.canUseGooglePay;
    },
    hasPopUpNotPossible(){
      return isElectron() || isWebView();
    },
  },
  watch: {
    invoice: {
      handler(newValue) {
        if (inIframe() && this.isSafari && this.transaction.isPaymentDetails()) {
          eventBus.emit('transaction-flow.payment-details.can-use-apple-pay');
        }
      },
      immediate: true,
    },
    isLoading: {
      handler(newValue, prevValue) {
        // Need to check explicitly, because sometimes we haven't loading
        // and `prevValue` is `undefined`
        if (!newValue && prevValue !== true) {
          eventBus.emit('transaction-flow.payment-details.loaded');
        }
      },
      immediate: true,
    },
    isActivePayment: {
      handler(newValue, oldValue) {
        if (!oldValue && newValue) {
          this.showPaymentStatusWaiting = true;
        }
      },
      immediate: true,
    },
    canShowAddCardForm(val) {
      if (val) {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
  beforeDestroy() {
    if (this.getPaymentStatusTimeout) clearTimeout(this.getPaymentStatusTimeout);
  },
  methods: {
    ...mapActions('transaction', {
      requestTransactionStatus: 'init',
    }),
    ...mapActions('card', [
      'getCards',
      'setFormFieldErrors',
      'createCard',
      'getPreferrablePaymentProcessor',
      'deleteCard',
      'chooseFirstAvailableCard',
      'initialPayment',
    ]),
    ...mapActions('cardSumsub', ['getSumsubApplicant']),
    ...mapActions('applePay', ['initApplePay']),
    ...mapActions('googlePay', ['initGooglePay']),
    ...mapMutations('card', [
      'setSelectedCardId',
      'startChallenge',
      'completeChallenge',
      'resetAddCard',
      'setActivePayment',
      'setApplePay',
      'clearApplePay',
      'setGooglePay',
      'clearGooglePay',
    ]),
    ...mapMutations(['lock', 'unlock', 'clearLocks', 'initRedirect']),
    ...mapMutations('transaction', ['setIsPaymentFailed']),
    ...mapMutations('cardSumsub', [
      'sumsubVerificationPending',
      'sumsubVerificationCompleted',
    ]),
    setSelectedCard(cardId) {
      this.clearApplePay();
      this.clearGooglePay();
      this.setSelectedCardId(cardId);

      // Remove selected card id
      setTimeout(() => {
        Storage.remove(`selectedCardId:${this.invoice}`);
      }, 1000);
    },
    selectDefaultPayment() {
      // Remove error for old card
      if (this.selectedCard && this.selectedCard.hasError()) this.selectedCard.removeErrors();

      const selectedCardId = Storage.get(`selectedCardId:${this.invoice}`);
      // Selects the card if it has been selected before
      if (selectedCardId && selectedCardId !== 'new') {
        this.setSelectedCard(selectedCardId);
      } else if (this.hasApplePay) {
        this.setApplePay();
      } else if (this.hasGooglePay) {
        this.setGooglePay();
      } else {
        this.chooseFirstAvailableCard();
      }
    },
    verificationCompleted() {
      return new Promise((resolve, reject) => {
        this.requestTransactionStatus()
          .then(() => {
            this.showPaymentStatusWaiting = true;
            this.sumsubVerificationCompleted();
            this.clearLocks();
          })
          .catch(reject);
      });
    },
    verificationCancelled() {
      this.requestCards().then(() => {
        this.sumsubVerificationCompleted();
        this.isPaymentInProgress = false;

        this.showPaymentStatusWaiting = false;
        this.clearLocks();
      });
    },
    verificationError() {
      this.requestCards().then(() => {
        this.sumsubVerificationCompleted();
        this.isPaymentInProgress = false;

        this.selectedCard.setError();
        this.clearLocks();
      });
    },
    getCardStatus({ cardId }) {
      return new Promise((resolve, reject) => CardClient.getCardStatus({ cardId })
        .then(({ data }) => {
          this.selectedCard.getStatus().setStatus(data.status);
          const cardStatus = this.selectedCard.getStatus();

          if (cardStatus.isAuthSuccess()) {
            clearTimeout(this.getCardStatusTimeout);

            return resolve(false);
          }

          if (cardStatus.isAuthFailed()) {
            clearTimeout(this.getCardStatusTimeout);
            this.isPaymentInProgress = false;
            this.selectedCard.setError();
            this.clearLocks();

            return resolve(false);
          }

          this.getCardStatusTimeout = setTimeout(
            () => this.getCardStatus({ cardId }).then(resolve),
            5000,
          );
        })
        .catch(reject));
    },
    getPaymentStatus({ paymentId, cardId }) {
      return new Promise((resolve, reject) => {
        const handlePaymentStatusWithDelay = () => {
            this.getPaymentStatusTimeout = setTimeout(
                () => this.getPaymentStatus({ paymentId, cardId }).then(resolve),
                5000,
            );
        };
        if (!paymentId) {
            handlePaymentStatusWithDelay();
            return;
        }
        CardPaymentClient.getPaymentStatus({ paymentId })
            .then(({ data }) => {
              if (data.status === 'created') {
                this.showPaymentStatusWaiting = true;
                this.loading = false;
              }

              if (data.status === 'approved') {
                clearTimeout(this.getPaymentStatusTimeout);
                return this.getCardStatus({ cardId }).then(result => {
                  if (result) {
                    // HACK
                    this.showPaymentStatusWaiting = false;
                    this.completeChallenge();
                    this.sumsubVerificationPending();
                  }
                  return resolve();
                });
              }

              if (data.status === 'approve_failed') {
                clearTimeout(this.getPaymentStatusTimeout);
                this.showPaymentStatusWaiting = false;
                this.completeChallenge();
                this.selectedCard.setError();
                this.clearLocks();
                this.isPaymentInProgress = false;

                return resolve();
              }

            handlePaymentStatusWithDelay();
            })
            .catch(reject);
      });
    },
    handleTransactionStatus() {
      return new Promise(resolve => {
        if (!this.transaction.isPaymentDetails()) {
          return resolve();
        }

        setTimeout(() => this.handleTransactionStatus().then(resolve), 3000);
      });
    },
    handlePayWithCard() {
      eventBus.emit('transaction-flow.payment.starts');

      this.payWithCard();
    },
    createAndSubmitDdcForm({ bin, jwt }, cardId, cvv) {
      const { iframe, form } = new DDCFormFactory({ bin, jwt }).build();
      iframe.appendChild(form);
      document.body.appendChild(iframe);

      const sumsubMessageHandler = event => {
        const { origin: eOrigin, data: eData } = event;
        // eslint-disable-next-line no-undef
        const { origin } = new URL(process.env.VUE_APP_WORLDPAY_DDC_URL);
        if (eOrigin !== origin) {
          return;
        }

        // Remove event listener
        window.removeEventListener('message', sumsubMessageHandler, false);

        this.ddcMessageHandler(eData, cardId, cvv);
      };
      window.addEventListener('message', sumsubMessageHandler, false);

      form.submit();
    },
    createDdcBridgerPayIframe({ iframe_code }, cardId, cvv) {
      const bridgerpayMessageHandler = event => {
        const { data: eData } = event;

        if (eData && eData.fingerprint && Object.keys(eData.fingerprint).length > 0) {
          this.bridgerpayPaymentRequest(eData.fingerprint, cardId, cvv);

          // Remove event listener
          window.removeEventListener('message', bridgerpayMessageHandler, false);
          this.bridgerPayDdcTimeoutId && clearTimeout(this.bridgerPayDdcTimeoutId);
        }
      };

      try {
        const { iframe } = new DDCBridgerPayIFrameFactory().build();
        document.body.appendChild(iframe);

        iframe.onload = () => {
          this.bridgerPayDdcTimeoutId = setTimeout(() => {
            this.bridgerpayPaymentRequest(null, cardId, cvv);
          }, 16000);
        };

        const docIframe = iframe.contentWindow.document;
        docIframe.open();
        docIframe.write(iframe_code);
        docIframe.close();

        window.addEventListener('message', bridgerpayMessageHandler, false);
      } catch (error) {
        this.bridgerpayPaymentRequest(null, cardId, cvv);
        window.removeEventListener('message', bridgerpayMessageHandler, false);
      }
    },
    createAndSubmitChallengeForm({ jwt, md }) {
      const form = new ChallengeFormFactory({ jwt, md }).buildForm();
      document.body.appendChild(form);

      form.submit();
    },
    createCardHandler(payload) {
      eventBus.emit('transaction-flow.payment.starts');

      this.payWithCard(payload);
    },
    createCardErrorHandler(status) {
      this.unlock();
      if (status === 400) {
        this.selectedCard.setError();
      }
    },
    createCardAndPay() {
      this.lock();

      if (this.cardEntryIFrameAppFeature.isEnabled()) {
        this.$refs.cardForm.submit();
        return;
      }

      const { invoice } = this;

      eventBus.emit('transaction-flow.payment.starts');

      return this.createCard({
        currency: this.paymentAmount.getCurrency(),
        invoice,
        browserLanguage: this.browserLanguage,
      })
        .then(({ data }) => {
          const { id } = data;
          this.addCard.setId(id);

          this.payWithCard();
        })
        .catch(({ data, status }) => {
          this.unlock();

          eventBus.emit('transaction-flow.payment.fails');

          if (status === 400 && data.code === 'CARD_IS_VERIFYING') {
            // Show error popup
            this.selectedCard.setAlreadyVerifying();
            return Promise.resolve();
          }

          if (data && data.errors) {
            this.setFormFieldErrors(data.errors);

            return Promise.resolve();
          }

          this.addCard.setError();
        });
    },
    onCatchError() {
      eventBus.emit('transaction-flow.payment.fails');

      this.isPaymentInProgress = false;

      this.selectedCard.setError();
      this.clearLocks();
    },
    payWithCard(payload = null) {
      this.lock();

      if (!payload) {
        // cvv validation
        if (
          !this.selectedCard.hasCvv()
          || (this.selectedCard.hasCvv() && this.selectedCard.getCvv().length !== 3)
        ) {
          this.selectedCard.setCvvError();
          this.selectedCard.setCvvErrorMessage(
            this.t('validators.3 digits required'),
          );

          this.clearLocks();

          return Promise.resolve();
        }
      }

      this.isPaymentInProgress = true;

      if (!payload) {
        // Clear cvv error (if actually was)
        this.selectedCard.clearCvvError();

        // UI: Reset add card form fields
        if (this.selectedCard.fields) {
          this.selectedCard.resetFormFields();
        }
      }

      // Check transaction is in appropriate status to perform actions
      if (!this.transaction.isPaymentDetails()) {
        return new Promise(() => {
          setTimeout(() => {
            this.counter++;
            this.payWithCard(payload);
          }, 15000);
        });
      }

      // Remember selected card id
      Storage.put(`selectedCardId:${this.invoice}`, this.selectedCardId);

      window.scrollTo(0, 0);

      const cardId = payload ? payload.id : this.selectedCard.getId();
      const cvv = payload ? payload.cvv : this.selectedCard.getCvv();

      return this.getPreferrablePaymentProcessor({
        cardId,
        currency: this.paymentAmount.getCurrency(),
        invoice: this.invoice,
      })
        .then(({ paymentProcessorId }) => {
          try {
            const paymentProcessor = new PaymentProcessorFactory(
              paymentProcessorId,
              cardId,
              this.invoice,
              cvv,
            ).build();

            return paymentProcessor
              .pay()
              .then(
                ({
                  isRedirect,
                  challengeLink,
                  paymentId,
                  isForm,
                  data,
                  isBridgerPayIframe,
                }) => {
                  if (isRedirect) {
                    this.initRedirect();

                    setTimeout(() => {
                      window.location.assign(challengeLink);
                    }, 1000);

                    return;
                  }

                  if (paymentId) {
                    return this.getPaymentStatus({ cardId, paymentId });
                  }

                  if (isForm) {
                    this.clearLocks();
                    // TODO: refactor this
                    if (paymentProcessor.getName() === 'worldpay') {
                      this.createAndSubmitDdcForm(data, cardId, cvv);

                      return Promise.resolve();
                    }

                    const formElement = new FormFactory(
                      data.form,
                    ).createFormElement();
                    document.body.appendChild(formElement);
                    formElement.submit();

                    return Promise.resolve();
                  }

                  if (isBridgerPayIframe) {
                    this.createDdcBridgerPayIframe(data, cardId, cvv);
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Something went wrong'));
                },
              ).catch(error => {
                this.onCatchError();

                return Promise.reject(error);
              });
          } catch (error) {
            eventBus.emit('transaction-flow.payment.payment-processor-not-found');

            this.onCatchError();

            return Promise.reject(error);
          }
        })
        .catch(e => {
          eventBus.emit('transaction-flow.payment.payment-processor-not-found');

          this.handleException(e);
        });
    },
    ddcMessageHandler(data, cardId, cvv) {
      this.lock();

      let parsedEventData = {};
      try {
        parsedEventData = JSON.parse(data);
      } catch (e) {
        parsedEventData = {
          SessionId: null,
        };
      }

      const { SessionId, Payload = {} } = parsedEventData;
      this.makePayment(Payload.SessionId || SessionId, cardId, cvv);
    },
    async makePayment(dfReferenceId, cardId, cvv) {
      const paymentRequest = {
        cardId,
        invoice: this.invoice,
        dfReferenceId,
        cvv,
        browserLanguage: this.browserLanguage,
        returnUrl: this.getReturnUrl(),
      };

      if (this.googlePaymentToken !== null) {
        paymentRequest.paymentToken = this.googlePaymentToken;

        try {
          const { data } = await CardPaymentClient.initialWorldpayGooglePayPayment(paymentRequest)
          this.handleCardChallenge({
            cardId,
            isChallengeRequired: data.is_challenge_required,
            challengeInfo: data.challenge_info,
            paymentId: data.payment_id,
          });
        } catch {
          this.onCatchError();
        } finally {
          this.googlePaymentToken = null;
          this.unlock();
        }

        return;
      }

      try {
        const data = await this.initialPayment(paymentRequest);
        this.handleCardChallenge({
          ...data,
          cardId,
        });
      } catch {
        this.onCatchError();
      } finally {
        this.unlock();
      }
    },
    async bridgerpayPaymentRequest(ddc, cardId, cvv) {
      if (this.bridgerPayDdcTimeoutId) clearTimeout(this.bridgerPayDdcTimeoutId);

      const paymentRequest = {
        cardId,
        invoice: this.invoice,
        returnUrl: this.getReturnUrl(),
        cvv,
        ddc,
      };

      try {
        const { data } = await CardPaymentClient.bridgerpayPaymentCreate(paymentRequest);
        this.selectedCard.setPaymentId(data.payment_id);
        this.handleCardChallenge({
          cardId,
          isChallengeRequired: data.is_challenge_required,
          challengeInfo: data.challenge_info,
          paymentId: data.payment_id,
          isBridgerPay: true,
        });
      } catch {
        this.onCatchError();
      } finally {
        this.unlock();
      }
    },
    handleDeleteCard(card) {
      this.deleteCard(card).then(result => {
        if (result) {
          this.chooseFirstAvailableCard();
        }
      });
    },
    handleSelectCard(cardId) {
      eventBus.emit('transaction-flow.payment.select-card');

      this.setIsPaymentFailed(false);
      this.setSelectedCard(cardId);
    },
    reset() {
      this.sumsubVerificationCompleted();
    },
    requestCards() {
      // TODO this method not called here anymore, because it was
      // moved into common lib store. Need to get rid of it or refactor
      // @see frontend-common-lib/store/modules/transaction.js:132
      return new Promise((resolve, reject) => {
        this.loadingCards = true;

        this.getCards()
          .then(() => {
            if (!this.selectedCard.isNew() || this.selectedCard.hasError()) {
              return Promise.resolve();
            }
            this.chooseFirstAvailableCard();
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            this.loadingCards = false;
            this.clearLocks();

            resolve();
          });
      });
    },
    getReturnUrl() {
      const parsedUrl = new URL(window.location.href);
      if (this.isWidget) {
        const searchParams = new URLSearchParams();
        searchParams.set('requestId', this.requestId);

        return `${parsedUrl.origin}/?${searchParams.toString()}`;
      }

      return parsedUrl.origin + parsedUrl.pathname;
    },
    handleCardChallenge({
      isChallengeRequired = false,
      cardId,
      paymentId,
      challengeInfo = null,
      isBridgerPay = false,
    }) {
      if (!isChallengeRequired) {
        return this.getPaymentStatus({
          paymentId,
          cardId,
        });
      }

      this.startChallenge();
      this.isPaymentInProgress = false;

      if (isBridgerPay) {
        this.initRedirect();

        setTimeout(() => {
          window.location.assign(challengeInfo.url);
        }, 1000);

        return;
      }

      setTimeout(() => {
        this.createAndSubmitChallengeForm({
          jwt: challengeInfo.jwt,
          md: {
            payment_id: paymentId,
            invoice: this.invoice,
            challenge_return_url: this.getReturnUrl(),
          },
        });
      });
    },
    payWithGooglePay({ paymentToken, cardholderName, showPopupPaymentNotPossible = false }) {
      if (showPopupPaymentNotPossible) {
        this.openPopupPaymentNotPossible(PAYMENT_METHODS.GOOGLE_PAY);
        return;
      }
      this.lock();
      this.googlePaymentToken = paymentToken;

      eventBus.emit('transaction-flow.payment.starts');

      const { invoice } = this;
      CardClient.googlePay({
        paymentToken,
        cardholderName,
        invoice,
      })
        .then(({ data }) => {
          const { id: cardId } = data;
          this.selectedCard.setId(cardId);
          this.setSelectedCardId(cardId);
          return this.getPreferrablePaymentProcessor({
            cardId,
            invoice,
            currency: this.paymentAmount.getCurrency(),
          }).then(({ paymentProcessorId }) => {
            try {
              const paymentProcessor = new PaymentProcessorFactory(
                paymentProcessorId,
                cardId,
                invoice,
                null,
              ).build();

              return paymentProcessor
                .initGooglePayPayment({
                  paymentToken,
                  browserLanguage: getLocaleByLanguage(this.language),
                  returnUrl: this.getReturnUrl(),
                })
                .then(
                  ({ isRedirect, challengeLink, paymentId, isForm, data }) => {
                    this.showPaymentStatusWaiting = true;

                    if (isForm) {
                      // XXX: this case is only triggerred for worldpay
                      this.clearLocks();
                      this.createAndSubmitDdcForm(data, cardId, this.selectedCard.getCvv());

                      return Promise.resolve();
                    }

                    if (isRedirect) {
                      this.initRedirect();

                      setTimeout(() => {
                        window.location.assign(challengeLink);
                      }, 1000);

                      return;
                    }

                    if (paymentId) {
                      this.clearLocks();

                      return this.getPaymentStatus({ cardId, paymentId });
                    }

                    this.clearLocks();
                  },
                );
            } catch (error) {
              eventBus.emit('transaction-flow.payment.fails');
              return Promise.reject(error);
            }
          });
        })
        .catch(error => {
          this.handleGooglePayError(error);
        });
    },
    payWithApplePay({ paymentToken, cardholderName, applePaySession, showPopupPaymentNotPossible = false }) {
      if (showPopupPaymentNotPossible) {
        this.openPopupPaymentNotPossible(PAYMENT_METHODS.APPLE_PAY);
        return;
      }
      eventBus.emit('transaction-flow.payment.starts');

      const { invoice } = this;
      CardClient.applePay({
        paymentToken,
        cardholderName,
        invoice,
      })
        .then(({ data }) => {
          const { id: cardId } = data;
          this.selectedCard.setId(cardId);
          this.setSelectedCardId(cardId);
          return this.getPreferrablePaymentProcessor({
            cardId,
            invoice,
            currency: this.paymentAmount.getCurrency(),
          }).then(({ paymentProcessorId }) => {
            try {
              const paymentProcessor = new PaymentProcessorFactory(
                paymentProcessorId,
                cardId,
                invoice,
                null,
              ).build();

              return paymentProcessor
                .initApplePayPayment({
                  paymentToken,
                  browserLanguage: getLocaleByLanguage(this.language),
                  returnUrl: this.getReturnUrl(),
                })
                .then(challengeData => {
                  this.showPaymentStatusWaiting = true;
                  // Close applePay popup with payment success
                  applePaySession.completePayment({ status: 0 });
                  challengeData.cardId = cardId;
                  this.handleCardChallenge(challengeData);
                  this.clearLocks();
                });
            } catch (error) {
              return Promise.reject(error);
            }
          });
        })
        .catch(error => {
          this.handleApplePayError({ error, session: applePaySession });
        });
    },
    handleGooglePayError(error) {
      this.selectedCard.setGooglePayError();
      this.showPaymentStatusWaiting = false;
      this.handleException(error);
    },
    handleApplePayError({ error, session }) {
      this.selectedCard.setApplePayError();
      this.showPaymentStatusWaiting = false;
      this.handleException(error);
      // Dismiss applePay popup with payment failure
      try {
        session.completePayment({ status: 1 });
      } catch { /* empty */ } // Do nothing. We already catch this error and it is duplicate error
    },
    handleException(error) {
      const errorInst = error instanceof Error ? error : new Error(error.data.message);
      this.onCatchError();

      const { status } = error;
      if (status && status === 400) {
        return;
      }

      captureException({
        error: errorInst,
        extra: {
          invoice: this.invoice,
        },
      });
    },
    openPopupPaymentNotPossible(paymentName) {
      this.showPopupPaymentNotPossible = true;
      this.notPossiblePaymentName = paymentName;
    },
  },
  async mounted() {
    if (this.isApplePayAvailable) {
      await this.initApplePay();
    }

    if (!this.isApplePayAvailable && this.isGooglePayAvailable) {
      await this.initGooglePay();
    }

    this.reset();

    const sumsubApplicantPromise = this.getSumsubApplicant();

    if (this.cards.length === 0 && !this.isCardsLoaded) {
      await this.requestCards();
    }

    if (this.isActivePayment) {
      this.showPaymentStatusWaiting = true;
      sumsubApplicantPromise.finally(() => {
        this.getPaymentStatus({
          paymentId: this.selectedCard.getPaymentId(),
          cardId: this.selectedCard.getId(),
        }).finally(() => {
          this.setActivePayment(false);
          this.clearLocks();

          eventBus.emit('transaction-flow.card-payment-details.loaded');
        });
      });
    } else if (this.selectedCard.hasPaymentId()) {
      this.showPaymentStatusWaiting = true;
      this.getCardStatus({ cardId: this.selectedCard.getId() })
        .then(result => {
          if (result) {
            this.showPaymentStatusWaiting = false;
            this.completeChallenge();
            this.sumsubVerificationPending();
          }
        })
        .finally(() => {
          this.showPaymentStatusWaiting = false;
          this.clearLocks();

          eventBus.emit('transaction-flow.card-payment-details.loaded');
        });
    } else {
      eventBus.emit('transaction-flow.card-payment-details.loaded');
    }

    this.selectDefaultPayment();
  },
};
</script>

<style lang="scss">
@keyframes open {
  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.card-payment-drawer {
  width: 100%;
  background: $white;
  opacity: 0;
  visibility: hidden;
  transform: translate(100px, 0);
  animation: open 0.4s forwards cubic-bezier(0.4, 0, 0.2, 1) 0.2s;

  &__icon {
    cursor: pointer;
    font-size: rem(28);
    line-height: 1.14;
    position: absolute;
    top: rem(2);
    left: 0;
    z-index: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  &__title {
    margin: 16px 36px 24px !important;

    @media screen and (max-width: $screen-md-max) {
      margin-top: 12px !important;
    }
  }
}
</style>
