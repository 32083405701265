export const COOKIE_DISABLED = 'cookieDisabled';

const defaultContent = {
  title: 'widget.error-page.title',
  description: 'widget.error',
  correlationMessage: 'widget.error-page.correlation-message',
};

const contentMapper = {
  [COOKIE_DISABLED]: {
    ...defaultContent,
    title: 'widget.errors.cookie-access.title',
    description: 'widget.errors.cookie-access.description',
  },
};

export default errorType => contentMapper[errorType] || defaultContent;
