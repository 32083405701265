<template>
  <div
    class="widget-header"
    :style="headerBottomSpace"
  >
    <span
      v-if="showLogo"
      class="logo"
    />
    <div
      v-show="showCloseButton && isCloseButtonVisible"
      class="btn-close"
      @click="toggleCloseConfirm"
    >
      <span
        class="svg-icon icon-close-mask"
        :style="headerCloseIconStyle"
      />
      <span :style="headerCloseTextStyle">
        {{ $t('widget.close') }}
      </span>
    </div>
  </div>
</template>

<script>
import { headerCloseTextStyle, headerCloseIconStyle, headerBottomSpace } from '@/composables/errorScreenAppearance';
import { canClose } from '@/services/widgetSettings';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'WidgetHeader',

  emits: ['close-widget'],

  setup(props, { emit }) {
    const store = useStore();
    const themeConfig = computed(() => store.getters.themeConfig);
    const showLogo = computed(() => themeConfig.value?.showLogo ?? true);
    const showCloseButton = computed(() => themeConfig.value?.showCloseButton ?? true);

    return {
      isCloseButtonVisible: canClose(),
      toggleCloseConfirm: () => emit('close-widget'),
      headerCloseTextStyle,
      headerCloseIconStyle,
      headerBottomSpace,
      showLogo,
      showCloseButton,
    };
  },

};
</script>

<style lang="scss" scoped>
.widget-header {
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @media (min-width: $laptop-min) {
    padding: 40px 48px;
    min-height: 5vh;
  }

  @media (min-width: $desktop-lg-min) {
    min-height: 14vh;
  }

  @media (min-width: $laptop-min) and (min-height: $widget-height-high) {
    min-height: 17vh;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    padding-top: calcVh(16, $widget-h-sm);
    padding-bottom: calcVh(16, $widget-h-sm);
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    padding-top: calcVh(16, $widget-h-xlg);
    padding-bottom: calcVh(16, $widget-h-xlg);
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    padding-top: calcVh(16, $widget-h-xxlg);
    padding-bottom: calcVh(16, $widget-h-xxlg);
  }

  @media (min-width: $desktop-xlg-min) {
    padding-top: calcVh(48, $widget-h-xxlg);
    padding-bottom: calcVh(48, $widget-h-xxlg);
  }

  .btn-close {
    z-index: 5;
  }

  .icon-close-mask {
    background-color: $black;
    mask-image: url('~@/assets/images/widget/close.svg');
    height: 16px;
    width: 16px;
    margin-right: 5px;
    mask-repeat: no-repeat;
    mask-size: contain;
  }
}
</style>
