import AuthClient from './auth-client';
import { Session } from '../models/session';
import { Tokens } from '../models/tokens';
import Storage from './auth-storage';
import TokenService from './token';

export default {
  async startSession(email, locale, flowName = '', theme = null, isEmailProvided = false, sourceInfo = null) {
    const postBody = {
      email,
      locale,
      flow: flowName === 'widget' ? 'email-only' : 'email-phone',
    };

    if (theme !== null) {
      postBody.theme = theme;
    }
    if (sourceInfo !== null) {
      postBody.sourceInfo = sourceInfo;
    }

    const response = await AuthClient.startSession(postBody);
    const session = new Session(response, email, locale, flowName, theme, sourceInfo);

    if (isEmailProvided) {
      session.setEmailProvided();
    }

    Storage.saveSession(session);

    return session;
  },

  async startRegistration(reCaptcha, session, phone, locale) {
    const sanitizedPhone = phone.replace(/\s/g, '');

    const { expirationTime, canBeResentAfter, attempts } = await AuthClient.startRegistration(session.sessionId, {
      phone: sanitizedPhone,
      locale,
      reCaptcha,
    });

    session.setPhoneOtpSession(expirationTime, canBeResentAfter, attempts, phone);

    Storage.saveSession(session);

    return session;
  },

  getAffiliateInfo() {
    const urlParams = new URLSearchParams(window.location.search);

    const affiliateRefId = urlParams.get('refId');

    if (!affiliateRefId) return {};

    return {
      affiliateInfo:
        {
          affiseAffiliateId: Number(affiliateRefId),
          affiseClickId: null,
          iDevAffiliateId: null,
        },
    };
  },

  async confirmEmailOtp(session, otp) {
    const response = await AuthClient.confirmEmailOtp(session.sessionId, {
      otp,
      isTosAccepted: true,
      channel: 'email',
      ...this.getAffiliateInfo(),
    });

    session.confirmEmailOtpSession();
    if (response.code === 'registration-required') {
      Storage.saveSession(session);
      return null;
    }

    Storage.clearStoredSession();

    return new Tokens(response);
  },

  async confirmPhoneOtp(session, otp, isTosAccepted) {
    const response = await AuthClient.confirmPhoneOtp(session.sessionId, {
      otp,
      isTosAccepted,
      channel: 'phone',
      ...this.getAffiliateInfo(),
    });

    Storage.clearStoredSession();

    return new Tokens(response);
  },

  async resendEmailOtp(session) {
    const userEmail = session.getEmailOtpSession().getContext();
    try {
      const { expirationTime, canBeResentAfter, attempts } = await AuthClient.resendOtp(session.sessionId);
      session.setEmailOtpSession(expirationTime, canBeResentAfter, attempts, userEmail);
    } catch (error) {
      if (error.code !== 404) {
        throw error;
      }
      session = await this.startSession(
        userEmail,
        session.getLocale(),
        session.getFlow(),
        session.getTheme(),
        session.isEmailProvided(),
        session.getSourceInfo(),
      );
    }

    Storage.saveSession(session);
  },

  async resendPhoneOtp(session) {
    const { expirationTime, canBeResentAfter, attempts } = await AuthClient.resendOtp(session.sessionId);
    const phoneNumber = session.getPhoneOtpSession().getContext();
    session.setPhoneOtpSession(expirationTime, canBeResentAfter, attempts, phoneNumber);

    Storage.saveSession(session);
  },

  async logout() {
    try {
      await AuthClient.logout();
    } catch {
      // XXX: suppress error
    }

    TokenService.removeTokens();
  },
};
