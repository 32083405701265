import { Feature } from '../models/feature';

const CARD_ENTRY_CARDHOLDER_NAME_FEATURE_NAME = 'cardentry-cardholdername';
const CARD_ENTRY_IFRAME_APP = 'cardentry-iframe-app';
const WEB_WALLETS = 'web-wallets-page';
const WEB_WALLETS_SUSPENDED_BLOCKCHAIN_ETHEREUM = 'web-wallets-suspended-blockchain-ethereum';
const WIDGET_PENDING_TRANSACTION_NOTIFICATION = 'widget-pending-transaction-notification';
const PAYOUT_DETAILS_WITH_QR_SCANNER = 'widget-payout-details-with-qr-scanner';

export default {
  namespaced: true,
  state: {
    features: [],
  },
  getters: {
    features: state => state.features,
    getFeatureByName: state => name => {
      const feature = state.features.find(feature => feature.name === name);
      if (!feature) {
        return new Feature(false);
      }

      return new Feature(feature.enabled);
    },
    cardholderNameFeature: (state, getters) => getters.getFeatureByName(CARD_ENTRY_CARDHOLDER_NAME_FEATURE_NAME),
    cardEntryIFrameAppFeature: (state, getters) => getters.getFeatureByName(CARD_ENTRY_IFRAME_APP),
    webWalletsFeature: (state, getters) => getters.getFeatureByName(WEB_WALLETS),
    webWalletsSuspendedBlockchainEthereumFeature: (state, getters) => getters.getFeatureByName(
      WEB_WALLETS_SUSPENDED_BLOCKCHAIN_ETHEREUM,
    ),
    widgetPendingTransactionNotificationFeature: (state, getters) => getters.getFeatureByName(WIDGET_PENDING_TRANSACTION_NOTIFICATION),
    payoutDetailsWithQRScannerFeature: (state, getters) => getters.getFeatureByName(PAYOUT_DETAILS_WITH_QR_SCANNER),
  },
  mutations: {
    setFeatures(state, payload) {
      state.features = payload;
    },
  },
};
