<template>
  <div class="partner-info">
    <div class="partner-info-wrapper">
      <div class="partner-info__name">
        {{ themeConfig.serviceName }}
      </div>
      <div class="partner-info__deposit-name">
        <template v-if="isSellCryptoFlow">
          {{ $t('transactions-list.title.transaction-details') }}
        </template>
        <template v-else>
          {{ $t('widget.deposit') }}
        </template>
      </div>

      <ExchangeRateInfo />

      <FeesDetails
        :fees="quoteFees"
        class="partner-info__fees-details desktop"
      />

      <ZhFeeApproximation v-if="flowDetails.isZerohashPayoutStrategy()" />

      <ExchangeRateExpiration
        v-if="!isTransactionInFinalStatus"
        class="partner-info__exchange-rate"
      />

      <ZhDisclaimer v-if="flowDetails.isZerohashPayoutStrategy()" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import FeesDetails from '@/components/FeesDetails';
import ExchangeRateInfo from '@/components/ExchangeRateInfo';
import ExchangeRateExpiration from '@/components/ExchangeRateExpiration';
import { useIsTransactionInFinalStatus } from '@/composables/transactionStatus';
import ZhFeeApproximation from '../ZhDisclosure/ZhFeeApproximation';
import ZhDisclaimer from '../ZhDisclosure/ZhDisclaimer';

export default {
  name: 'WidgetPartnerInfo',

  components: {
    ZhFeeApproximation,
    ZhDisclaimer,
    FeesDetails,
    ExchangeRateInfo,
    ExchangeRateExpiration,
  },

  setup() {
    const store = useStore();
    const themeConfig = computed(() => store.getters.themeConfig);

    const quoteFees = computed(() => {
      if (!store.getters['widgetQuote/fees']) {
        return {
          network_fee: '0.00',
          service_fee: '0.00',
          total_fee: '0.00',
        };
      }

      return store.getters['widgetQuote/fees'];
    });
    const isTransactionInFinalStatus = useIsTransactionInFinalStatus();

    const flowDetails = computed(() => store.getters['transaction/flowDetails']);
    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);

    return {
      themeConfig,
      quoteFees,
      isTransactionInFinalStatus,
      flowDetails,
      isSellCryptoFlow,
    };
  },
};
</script>

<style lang="scss" scoped>

.partner-info {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;

  @media (max-width: $tablet-max) {
    display: flex;
  }

  &-wrapper {
    max-width: 43.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    @media screen and (max-width: $tablet-max) {
      max-width: none;
    }

    @media screen and (min-width: $laptop-min) {
      padding-top: 90px;
    }
  }

  &__name {
    font-weight: 700;
    font-size: rem(44);
    line-height: rem(58);
    letter-spacing: -0.01em;

    @media (min-width: $laptop-min) {
      font-size: rem(80);
      line-height: rem(92);
      margin-bottom: rem(32);
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: 10.42vh;
      line-height: 1.2;
      margin-bottom: calcVh(32, $widget-h-sm);
    }

    @media (min-width: $desktop-min) {
      font-size: rem(112);
      line-height: 1;
      max-width: rem(700);
      flex-grow: 1;
      text-align: center;
    }

    @media (min-width: $desktop-min) and (max-height: $widget-height-sm) {
      font-size: 14.58vh;
      line-height: 1;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: 14.62vh;
      line-height: 1;
      margin-bottom: calcVh(32, $widget-h-xlg);
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: 14.84vh;
      line-height: 1;
      margin-bottom: calcVh(32, $widget-h-xxlg);
    }
  }

  &__deposit-name {
    display: none;
    color: #fff;
    font-size: rem(18);
    line-height: 1.5rem;
    font-weight: 700;
    margin-bottom: 8px;

    @media (min-width: $tablet-max) {
      display: block;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: 2.34vh;
      line-height: 3.12vh;
    }

    @media (min-width: $desktop-min) and (max-height: $widget-height-sm) {
      font-size: 2.6vh;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: 2.61vh;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: 2.65vh;
    }
  }

  &__fees-details {
    display: none;

    @media screen and (min-width: $tablet-max) {
      display: flex;
    }
  }

  &__exchange-rate {
    display: none;

    @media (min-width: $tablet-max) {
      display: block;
    }
  }
}
</style>
