import { h, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { isAnyTranslationLoaded } from '@/i18n';

import WidgetWrapper from '@/components/WidgetWrapper';
import { ModalHolder } from '@/components/Modal';

import { useTransactionEventsRegistrator } from '@/composables/useTransactionEventsRegistrator';

import { loadNSureScript } from '@/utils/loadNSure';

import { emitToParent } from '@/services/widgetApiEvents';

export default {
  name: 'App',

  setup() {
    const store = useStore();
    const transactionEventsRegistrator = useTransactionEventsRegistrator();

    // TODO: this is temporary solution. We need to display some error modal here.
    watch(
      () => store.getters.hasTranslationsLoadingError,
      hasError => {
        if (hasError) {
          // eslint-disable-next-line no-alert
          // alert('translations not loaded. Please contact your service provider');
          if (isAnyTranslationLoaded.value) {
            store.dispatch('setError', null);
          } else {
            emitToParent('state', {
              state: 'closed',
            });
          }
        }
      },
    );

    watch(
      () => store.getters.nSure,
      loadNSureScript,
    );

    onMounted(() => {
      transactionEventsRegistrator.register();
    });

    onUnmounted(() => {
      transactionEventsRegistrator.unregister();
    });

    return () => [
      h(WidgetWrapper),
      h(ModalHolder),
    ];
  },
};
