import { h } from 'vue';

import AuthenticationFlow, { CONSTANTS } from './exports';
import eventBus from '@/helpers/eventBus';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';
import { ERROR_TYPES } from './errorTypes';

import AuthLoading from './AuthLoading';

import './AuthFlow.scss';

const {
  AUTHENTICATED_EVENT,
  LOADED_EVENT,
  ERROR_EVENT,
  USER_BANNED_EVENT,
  MISSING_REFRESH_TOKEN_EVENT,
  SESSION_TIMEOUT_EVENT,
} = CONSTANTS;

function getCountryCode() {
  let locale;
  if (navigator.languages && navigator.languages.length) {
    [locale] = navigator.languages;
  } else {
    locale = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }

  return locale.split('-').reverse()[0];
}

export default {
  name: 'AuthFlow',

  props: {
    email: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
      required: true,
    },
    sourceInfo: {
      type: Object,
      required: false,
      default: null,
    },
    locale: {
      type: String,
      default: 'en',
    },
    partnerId: {
      type: String,
      required: false,
      default: null,
    },
    requestId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    authLoaded: false,
  }),

  // TODO: with new exchange form flow this bing not working, because Auth is not mounted
  // Should be moved into separate layer
  beforeCreate() {
    eventBus.on(AUTHENTICATED_EVENT, ({ tokens }) => {
      this.$emit('authenticated', tokens);
    });
    eventBus.on(LOADED_EVENT, () => {
      this.authLoaded = true;
      this.$emit('loaded');
    });
    eventBus.on(ERROR_EVENT, ({ error }) => {
      if ([425, 429].includes(error.status)) {
        this.$emit('error', { type: ERROR_TYPES.ERROR_TOO_EARLY, error });
        return;
      }

      this.$emit('error', { type: ERROR_TYPES.ERROR_CRITICAL, error });
    });
    eventBus.on(USER_BANNED_EVENT, () => {
      this.$emit('error', { type: ERROR_TYPES.ERROR_BANNED, error: null });
    });

    eventBus.on(MISSING_REFRESH_TOKEN_EVENT, () => {
      this.$emit('error', { type: ERROR_TYPES.ERROR_MISSING_TOKEN, error: null });
    });

    eventBus.on(SESSION_TIMEOUT_EVENT, () => {
      this.$emit('error', { type: ERROR_TYPES.ERROR_SESSION_TIMEOUT, error: null });
    });

    eventBus.on('app.session_timeout', () => {
      this.$emit('error', { type: ERROR_TYPES.ERROR_SESSION_TIMEOUT, error: null });
    });
  },

  render() {
    const countryCode = getCountryCode();

    const config = {
      class: 'auth-flow',
      view: 'widget',
      countryCode,
      theme: this.theme,
      locale: this.locale,
      sourceInfo: this.sourceInfo,
      partnerId: this.partnerId,
      requestId: this.requestId,
      isNewExchangeFormFlow: isNewExchangeFormFlowEnabled(),

      i18nOverrides: {
        emailHint: 'widget.auth.email-hint',
      },
    };

    if (this.email) {
      config.email = this.email;
    }

    const Components = [
      h(AuthenticationFlow, config),
    ];

    if (!this.authLoaded) {
      Components.push(
        h(AuthLoading),
      );
    }

    return Components;
  },
};
