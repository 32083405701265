import cookieStore from './provider/js-cookie';
import inMemory from './provider/in-memory';
import { isSafari } from '../safari';

export default {
  get(key) {
    if (isSafari()) {
      const value = localStorage.getItem(key) || undefined;
      if (value) {
        return value;
      }
    }

    return cookieStore.get(key) || inMemory.get(key);
  },
  set(key, value, options = {}) {
    if (isSafari()) {
      localStorage.setItem(key, value);
    }

    cookieStore.set(key, value, options);
    inMemory.set(key, value);
  },
  remove(key, options = {}) {
    if (isSafari()) {
      localStorage.removeItem(key);
    }

    cookieStore.remove(key, options);
    inMemory.remove(key);
  },
};
