import { bankDetailsList } from '../models/bank-details-list';

const bankDetailsForCurrencies = bankDetails => ({
  eur: [bankDetails.iban],
  gbp: [
    {
      subtitle: 'transaction-flow.bank-details.subtitle.international',
      row: [bankDetails.iban],
    },
    {
      subtitle: 'transaction-flow.bank-details.subtitle.domestic',
      row: [bankDetails.accountNr, bankDetails.localCode],
    },
  ],
  usd: [
    {
      subtitle: 'transaction-flow.bank-details.subtitle.international',
      row: [bankDetails.accountNr],
    },
    {
      subtitle: 'transaction-flow.bank-details.subtitle.domestic',
      row: [bankDetails.accountNr, bankDetails.localCode],
    },
  ],
});

const commonBankDetails = bankDetails => [
  bankDetails.receiver,
  bankDetails.beneficiaryAddress,
  bankDetails.depositAmount,
  bankDetails.paymentReference,
];

export const createBankDetailsList = (currency, details) => {
  const fullDetailsList = bankDetailsList(currency, details);
  const commonBankDetailsList = commonBankDetails(fullDetailsList);
  const currencyBankDetailsList = bankDetailsForCurrencies(fullDetailsList)[currency.toLowerCase()];

  return currencyBankDetailsList.concat(commonBankDetailsList);
};

export const createBankSwiftDetailsList = (currency, details) => {
  const fullDetailsList = bankDetailsList(currency, details);
  return [
    fullDetailsList.beneficiaryAccountNumber,
    fullDetailsList.beneficiaryName,
    fullDetailsList.beneficiaryAddress,
    fullDetailsList.beneficiaryBankAddress,
    fullDetailsList.beneficiaryBankName,
    fullDetailsList.beneficiaryBankBic,
  ];
};
