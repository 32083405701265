import Api from '../api';

import { verificationNormalizer } from '../../normalizers';

const apiUrl = process.env.VUE_APP_PROCESSING_API_URL;
const DEFAULT_LANGUAGE = 'en';

export default {
  // Verification
  async getVerification() {
    const verificationResponse = await this.getBasicVerification();
    const livenessResponse = await this.getLiveness();

    return verificationNormalizer({
      verificationResponse,
      livenessResponse,
    });
  },

  getBasicVerification() {
    return Api().get(`${apiUrl}/public/verification/v1/basic/normalized`);
  },

  getLiveness() {
    return Api().get(`${apiUrl}/public/processing/v2/sumsub-widget/liveness`);
  },

  // Sumsub
  getApplicantData() {
    return Api().get(`${apiUrl}/public/sumsub-applicant/me`);
  },
  requestLiveness() {
    return Api().post(`${apiUrl}/public/sumsub-applicant/me/request-liveness`);
  },
  getExtraVerificationConfig(verificationFieldTypeSlug) {
    return Api().get(
      `${apiUrl}/public/processing/v2/sumsub-applicant/me/action-config/${verificationFieldTypeSlug}`,
    );
  },
  saveReviewAnswer(reviewAnswer) {
    return Api().post(
      `${apiUrl}/public/processing/v1/sumsub-widget/last-review-answer`,
      { reviewAnswer },
    );
  },

  // Transaction Flow
  getTransactionByInvoice(invoice, language = DEFAULT_LANGUAGE) {
    return Api().get(`${apiUrl}/public/transaction/v2/${invoice}`, {
      headers: {
        'X-User-Locale': language,
      },
    });
  },
  cancelTransactionByInvoice(invoice) {
    return Api().put(`${apiUrl}/public/transaction/v2/${invoice}/cancel`);
  },
  submitTransactionPayoutDetailsByInvoice(
    invoice,
    payload,
    language = DEFAULT_LANGUAGE,
  ) {
    return Api().put(
      `${apiUrl}/public/transaction/v2/${invoice}/payout-details`,
      payload,
      {
        headers: {
          'X-User-Locale': language,
        },
      },
    );
  },
  markTransactionAsPaidByInvoice(invoice) {
    return Api().put(`${apiUrl}/public/transaction/v2/${invoice}/mark-as-paid`);
  },
  checkoutTransactionByInvoice(invoice, language = DEFAULT_LANGUAGE) {
    return Api().put(
      `${apiUrl}/public/transaction/v2/${invoice}/checkout?language=${language}`,
    );
  },
  authorizeTransactionByInvoice(invoice) {
    return Api().put(`${apiUrl}/public/transaction/v2/${invoice}/authorize`);
  },
  submitGiftCard(invoice, giftCardNumber) {
    const payload = {
      giftCardNumber,
    };

    return Api().put(
      `${apiUrl}/public/transaction/v2/${invoice}/gift-card`,
      payload,
    );
  },
  getUser() {
    return Api().get(`${apiUrl}/public/processing/v1/users/me`);
  },
};
