/**
  Duplicate constants. This is needed for less coupling between libraries.
  @see paybis/frontend-common-lib/src/models/transaction/status.js
*/
export const TRANSACTION_STATUSES = {
  STATUS_PAYOUT_AUTHORIZATION_PENDING: 'auth-pending',
  STATUS_PAYOUT_AUTHORIZATION_PROCESSING: 'auth-processing',
  STATUS_VERIFICATION: 'verification',
  STATUS_PAYOUT_DETAILS: 'payout-details',
  STATUS_PAYMENT_DETAILS: 'payment-details',
  STATUS_PAYMENT_WAITING: 'payment-waiting',
  STATUS_PAYMENT_REVIEW: 'payment-review',
  STATUS_PAYOUT_WAITING: 'payout-waiting',
  STATUS_COMPLETED: 'completed',
  STATUS_CANCELLED: 'cancelled',
  STATUS_REJECTED: 'rejected',
  STATUS_PAYMENT_ERROR: 'payment-error',
};
