<template>
  <div class="card-select">
    <div
      v-if="hasApplePay"
      class="card-select__item"
    >
      <div
        class="card-select__item-header"
        @click="selectApplePay()"
      >
        <button
          class="card-select__toggle"
          :class="{ 'is-selected': isApplePay }"
          @click="selectApplePay()"
        >
          {{ $t('shared.select.select') }}
        </button>
        <div class="card-select__item-icon">
          <img
            :src="
              require(`@paybis/frontend-common-lib/src/assets/images/info-apple-pay.svg`)
            "
            alt="logo"
          >
        </div>
        <div class="card-select__item-main">
          <div class="card-select__item-placeholder">
            {{ $t('transaction-flow.apple-pay.select.label') }}
          </div>
        </div>
      </div>
      <div
        v-if="isApplePay"
        class="card-select__item-footer"
      >
        <SecureStamp class="card-select__item-secure" />

        <ApplePayButton
          class="card-select__item-pay card-select__item-pay--apple"
          :lang="language"
          :payment="paymentAmount"
          :country-code="userCountry"
          :disabled="isLocked"
          :settings="applePaySettings"
          @pay="$emit('applePay', $event)"
          @error="$emit('applePayError', $event)"
        />
      </div>
    </div>

    <div
      v-if="hasGooglePay"
      class="card-select__item"
    >
      <div
        class="card-select__item-header"
        @click="selectGooglePay()"
      >
        <button
          class="card-select__toggle"
          :class="{ 'is-selected': isGooglePay }"
          @click="selectGooglePay()"
        >
          {{ $t('shared.select.select') }}
        </button>
        <div class="card-select__item-icon">
          <img
            class="google-pay-mark"
            :src="
              require(`@paybis/frontend-common-lib/src/assets/images/info-google-pay.svg`)
            "
            alt="Google Pay logo"
          >
        </div>
        <div class="card-select__item-main">
          <div class="card-select__item-placeholder">
            {{ $t('transaction-flow.google-pay.select.label') }}
          </div>
        </div>
      </div>
      <div
        v-if="isGooglePay"
        class="card-select__item-footer"
      >
        <SecureStamp class="card-select__item-secure" />

        <GooglePayButton
          class="card-select__item-pay card-select__item-pay--google"
          :lang="language"
          :disabled="isLocked"
          :payment-data-request="googlePaymentDataRequest"
          @pay="$emit('googlePay', $event)"
          @error="$emit('googlePayError', $event)"
        />
      </div>
    </div>

    <div
      v-for="(card, i) in cards"
      :key="i"
      class="card-select__item"
      :class="{ 'has-cvv': true }"
    >
      <div
        class="card-select__item-header"
        @click="handleSelectCard(card)"
      >
        <button
          class="card-select__toggle"
          :class="{
            'is-selected': !isPaymentMethodSelect && card.getId() === selected,
          }"
          :disabled="card.isExpired()"
        >
          {{ $t('shared.select.select') }}
        </button>
        <div class="card-select__item-icon">
          <img
            :src="
              require(`@paybis/frontend-common-lib/src/assets/images/info-${card.getType()}.svg`)
            "
            alt="logo"
          >
        </div>
        <div class="card-select__item-main">
          <div class="card-select__item-primary">
            <div class="card-select__item-number">
              {{ card.getMaskedCardNumber() }}
              <p class="card-select__item-holder">
                {{ card.getCardholderName() }}
              </p>
            </div>
          </div>
          <div
            class="card-select__item-expires"
            :class="{ 'is-expired': card.isExpired() }"
          >
            {{ card.isExpired() ? $t('shared.expired') : '' }}
            {{ card.getExpirationDate() }}
          </div>
        </div>
        <div
          class="card-select__item-action"
          @click.stop
        >
          <CardSelectOptions @deleteCard="$emit('deleteCard', card)" />
        </div>
      </div>
      <div
        v-if="!isPaymentMethodSelect && card.getId() === selected"
        class="card-select__item-content"
      >
        <FormRow
          v-if="!isCardPayout"
          media="xs"
        >
          <FormRowSlot
            media="xs"
            width="50%"
            gap="wide"
          >
            <FormInput
              :id="`expiration-date-${card.getId()}`"
              v-maska="'##/##'"
              inputmode="numeric"
              class="card-select__expiration-date"
              :is-disabled="true"
              :value="card.getExpirationDate()"
              :name="`expiration_date_${card.getId()}`"
              :label="
                $t(
                  'transaction-flow.payment-details.card-details.expiration-date',
                )
              "
              error=""
            />
          </FormRowSlot>
          <FormRowSlot
            class="card-select__cvv-container"
            media="xs"
            width="50%"
            gap="wide"
          >
            <FormInput
              :id="`cvv-${card.getId()}`"
              v-maska="'###'"
              :value="card.getCvv()"
              :has-error="card.hasCvvError()"
              :error="$t(`validators.${card.getCvvErrorMessage()}`)"
              :name="`cvv-${card.getId()}`"
              type="text"
              inputmode="numeric"
              :label="$t('transaction-flow.payment-details.card-details.cvv')"
              class="card-select__cvv-input"
              @input="card.setCvv($event)"
            />
          </FormRowSlot>
        </FormRow>
        <div class="card-select__item-footer">
          <SecureStamp class="card-select__item-secure" />

          <button
            class="btn btn-lg btn-primary card-select__item-pay"
            :class="{
              'is-loading': isPayButtonDisabled,
            }"
            :disabled="isPayButtonDisabled || (card.isCvvRequired() && card.hasCvvError())"
            @click="$emit('pay', card)"
          >
            <template v-if="isCardPayout">
              {{ $t('shared.navigation.button.continue') }}
            </template>
            <template v-else>
              {{ $t('shared.navigation.button.pay') }}
            </template>
          </button>
        </div>
      </div>
    </div>
    <div class="card-select__item">
      <div
        class="card-select__item-header"
        @click="handleSelect('new')"
      >
        <button
          class="card-select__toggle"
          :class="{
            'is-selected': !isPaymentMethodSelect && selected === 'new',
          }"
        >
          {{ $t('shared.select.select') }}
        </button>
        <div class="card-select__item-icon">
          <img
            class="card-select__item-icon-svg"
            :src="
              require(`@paybis/frontend-common-lib/src/assets/images/icon-plus.svg`)
            "
            alt="Plus icon"
          >
        </div>
        <div class="card-select__item-main">
          <div class="card-select__item-placeholder">
            {{ $t('transaction-flow.payment-details.card-details.add-card') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@paybis/frontend-common-lib/src/components/form/form-input';
import FormRow from '@paybis/frontend-common-lib/src/components/form/form-row';
import FormRowSlot from '@paybis/frontend-common-lib/src/components/form/form-row-slot';
import { maska } from 'maska';

import { mapGetters, mapMutations } from 'vuex';
import CardPamentClient from '@paybis/frontend-common-lib/src/services/clients/card-payment-client';
import CardSelectOptions from './card-select-options';
import SecureStamp from './secure-stamp';
import ApplePayButton from './apple-pay-button';
import GooglePayButton from './google-pay-button';

export default {
  name: 'CardSelect',

  directives: { maska },

  components: {
    FormInput,
    FormRow,
    FormRowSlot,
    CardSelectOptions,
    SecureStamp,
    ApplePayButton,
    GooglePayButton,
  },

  props: {
    cards: { type: Array, required: true },
    selected: { type: String, required: false, default: '' },
    hasApplePay: { type: Boolean, default: false },
    hasGooglePay: { type: Boolean, default: false },
    userCountry: { type: String, default: null },
    googlePaymentDataRequest: { type: Object, default: null },
    isCardPayout: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('quote', ['paymentAmount']),
    ...mapGetters('card', [
      'selectedCard',
      'isApplePay',
      'isGooglePay',
      'applePaySettings',
      'googlePaySettings',
    ]),
    ...mapGetters(['isLocked', 'language']),

    isPaymentMethodSelect() {
      return this.isApplePay || this.isGooglePay;
    },

    isPayButtonDisabled() {
      return this.isLocked || this.isLoading;
    },
  },

  methods: {
    ...mapMutations('card', [
      'setApplePay',
      'clearApplePay',
      'setGooglePay',
      'clearGooglePay',
    ]),
    clearAllPayments() {
      this.clearApplePay();
      this.clearGooglePay();
    },
    selectApplePay() {
      this.clearAllPayments();
      this.setApplePay();
    },
    selectGooglePay() {
      this.clearAllPayments();
      this.setGooglePay();
    },
    handleSelect(id) {
      this.clearAllPayments();
      this.$emit('selectCard', id);
    },
    handleSelectCard(card) {
      if (card.isExpired()) return;
      this.handleSelect(card.getId());
    },
  },
};
</script>

<style lang="scss">
.card-select {
  width: 100%;

  &__cvv-container {
    display: flex;
  }

  &__cvv-input {
    margin-top: auto;
  }

  &__item {
    margin-left: rem(32);
    padding: rem(10) 0;
  }

  &__item-header {
    position: relative;
    display: flex;
    cursor: pointer;
  }

  &__item-content {
    padding-top: rem(20);
    padding-bottom: rem(14);
  }

  &__item-footer {
    padding-top: rem(24);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-header + &__item-footer {
    padding-top: rem(16);
    padding-bottom: rem(14);
  }

  [dir='rtl'] &__item {
    margin-right: rem(32);
    margin-left: unset;
  }

  &__item {
    border-bottom: 1px solid $black-10;
  }

  &__item-icon {
    display: flex;
    background-color: $grey-04;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);

    &-svg {
      width: rem(16);
      height: rem(16);
      margin: auto;
    }
  }

  &__item-primary {
    display: flex;
    align-items: center;
  }

  &__item-icon img {
    display: block;
    max-width: rem(30);
    max-height: rem(30);
    margin: auto;
  }

  &__item-icon .google-pay-mark {
    max-width: rem(48);
    max-height: rem(48);
    margin: rem(-4);
    flex-basis: max-content;
  }

  &__toggle {
    position: absolute;
    left: rem(-32);
    top: 50%;
    bottom: 50%;
    margin-top: rem(-8);
    font-size: 0;
    width: rem(16);
    height: rem(16);
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    background-color: #f5f5f5;
  }

  [dir='rtl'] &__toggle {
    right: rem(-32);
    left: unset;
  }

  &__toggle.is-selected:after {
    content: '';
    display: block;
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background-color: $black;
    margin: auto;
  }

  &__item-main {
    flex: 1;
    margin: 0 0 0 rem(16);
  }

  &__item-main-solo {
    display: flex;
    align-items: center;
  }

  [dir='rtl'] &__item-main {
    margin: 0 rem(16) 0 0;
  }

  &__item-number {
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
  }

  &__item-holder {
    font-size: rem(12);
    line-height: 1.17;
    color: $grey-30;
  }

  &__item-expires{
    align-self: center;
    font-size: rem(12);
    margin-top: rem(4);
  }

  &__item-expires.is-expired{
    color: $red;
    text-align: right;
  }

  &__item-error{
    color: $red;
    margin-top: rem(4);
    font-size: rem(12);
  }

  &__item-action {
    align-self: center;
    margin-left: rem(8);
    margin-right: rem(-10);
  }

  [dir='rtl'] &__item-action {
    margin-left: rem(-10);
    margin-right: rem(8);
  }

  &__item-placeholder {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(40);
  }

  &__expiration-date {
    ::v-deep .form-input__input {
      -moz-appearance: textfield;
    }
  }

  @media (max-width: 1000px) {
    &__item-expires.is-expired {
      text-align: left;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &__item-cvv {
      margin-top: rem(10);
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    border: 1px solid $black-10;
    border-radius: rem(6);
    padding: rem(8) rem(24) rem(8) rem(16);

    [dir='rtl'] & {
      padding: rem(8) rem(16) rem(8) rem(24);
    }

    &__item-main {
      display: flex;
      justify-content: space-between;
    }

    &__item-expires {
      font-size: rem(14);
      margin-top: 0;
    }

    &__item {
      border-bottom: none;
    }

    &__item:not(:last-child) {
      border-bottom: 1px solid $black-10;
    }
  }
}
</style>

<style lang="scss" scoped>
.card-select {
  &__item-pay {
    width: 100%;

    &--apple,
    &--google {
      justify-content: center;
      padding: rem(12) rem(28);
      display: flex;
      align-items: center;
    }

    &.is-loading::after {
      margin-left: -1rem;
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
    }
  }

  &__item-secure {
    display: none;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__item-pay {
      width: auto;
    }

    &__item-secure {
      display: flex;
    }
  }
}
</style>
